import React from "react";
import { Link } from "react-router-dom";

import { useAuth } from "../../hooks/useAuth";
import AuthLayout from "../../layouts/AuthLayout";

const AuthenicatePage = () => {
  const { verified, handleCheckSession } = useAuth();

  return (
    <AuthLayout isAuth={true} title="Authorization..." backgroundVideo="/videos/LineVideo.mp4" />
    // <div style={{ display: "flex", flexDirection: "column", rowGap: "20px" }}>
    //   <button>click</button>
    //   <h1>
    //     {verified
    //       ? "You are currently being authorized, please wait"
    //       : "Please check your email and verify the address"}
    //   </h1>
    //   {!verified && (
    //     <>
    //       <div>
    //         <Link to="/login">go to login page</Link>
    //       </div>
    //       <button onClick={handleCheckSession}>already verified</button>
    //     </>
    //   )}
    // </div>
  );
};

export default AuthenicatePage;
