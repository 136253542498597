export const lightPalette = {
  social: {
    main: "#7C7C7D",
    dark: "#0F0311",
    light: "#FFFFFF",
    gray: "#F5F5F5",
  },
  textfield: {
    background: "#FFFFFF",
    errorText: "#DC6675",
    textColor: "#0F0311",
    border: {
      main: "#CCCCCC",
      hover: "#999999",
      focus: "#C53DD8",
      filled: "#B3B3B3",
      error: "#DC6675",
      disable: "#CCCCCC",
    },
    placeholder: {
      main: "#999999",
      hover: "#808080",
      focus: "#808080",
      filled: "#808080",
      error: "#DC6675",
      disable: "#B3B3B3",
    },
  },
  contrastText: {
    main: "#C53DD8",
  },
  textColor: {
    dark: "#0F0311",
    main: "#0F0311",
    light: "#F3F2F3",
    contrastText: "#C53DD8",
    darkContrast: "#666666",
    tooltipText: "#333333",
    marketplaceNavigation: "#F5F5F5",
    lightGray: "#808080",
  },
  checkbox: {
    colored: { main: "#C53DD8", outlined: "#CCCCCC" },
    default: { main: "#A0A0A0", outlined: "#F5F5F5" },
  },
  chip: {
    primary: {
      backgroundColor: "#0F0311",
      color: "#F5F5F5",
    },
    default: {
      backgroundColor: "rgba(0, 0, 0, 0.12)",
      color: "#0F0311",
    },
    success: {
      backgroundColor: "#E9F0E1",
      color: "#4CAF50",
    },
    error: {
      backgroundColor: "rgba(255, 36, 66, 0.06)",
      color: "#DD5363",
    },
    colored: {
      color: "#ffffff",
    },
  },
  table: {
    headerText: "#808080",
    activeRowBg: "#FFFFFF",
    rowBg: "#f5f5f5",
  },
  tooltip: {
    background: "#FFFFFF",
  },
  totalRequestVolume: {
    dateSwitcher: {
      background: "#F5F5F5",
    },
  },
  menu: {
    border: "#CCCCCC",
    grayText: "#B3B3B3",
    refColor: "#A926BB",
  },
  navbar: {
    planText: "#FF9F38",
  },
  chart: {
    barFirst: "#5C1466",
    barFirstDisabled: "#B3B3B3",
    barSecond: "#A926BB",
    barSecondDisabled: "#CCCCCC",
    barThird: "#D166E0",
    barThirdDisabled: "#d6d5d6",
    barFourth: "#EBBBF1",
    barFourthDisabled: "#F5F5F5",
    tooltipCursor: "rgba(247, 230, 250, 0.498)",
  },
  requestMethod: {
    POST: {
      primary: "#C53DD8",
      secondary: "#DE91E9",
    },
    PUT: {
      primary: "#6A546D",
      secondary: "#9E86A2",
    },
    GET: {
      primary: "#CAA811",
      secondary: "#ECC722",
    },
    DELETE: {
      primary: "#808080",
      secondary: "#999999",
    },
  },
};
