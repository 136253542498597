import { Transaction } from "../../types/transaction";

export const GET_TRANSACTIONS_SERVER = "GET_TRANSACTIONS_SERVER";
export const UPDATE_LOADING_TRANSACTIONS = "UPDATE_LOADING_TRANSACTIONS";
export const UPDATE_TRANSACTIONS = "UPDATE_TRANSACTIONS";

interface GetTransactionsProps {
  project_ids?: string[];
  filter_range_type?: string;
  turbo_tx?: boolean;
  event_type?: string;
  page: number;
}

interface UpdateStatusProps {
  status: boolean;
}

export const getTransactionsServer = ({
  project_ids,
  event_type,
  filter_range_type,
  turbo_tx,
  page,
}: GetTransactionsProps) => ({
  type: GET_TRANSACTIONS_SERVER,
  payload: {
    request: {
      method: "POST",
      url: `/transaction?page=${page}&count=5`,
      data: { project_ids, event_type, filter_range_type, turbo_tx },
    },
  },
});

export const updateTransactionsLoading = ({ status }: UpdateStatusProps) => ({
  type: UPDATE_LOADING_TRANSACTIONS,
  payload: { status },
});

export const updateTransactions = (transactions: Transaction[]) => ({
  type: UPDATE_TRANSACTIONS,
  payload: { transactions },
});
