import { Project, GetProjectsBlockchain } from "../../types/project";
import {
  ADD_TO_LOADING_PROJECTS,
  ADD_TO_RESET_KEYS,
  REMOVE_FROM_LOADING_PROJECTS,
  REMOVE_FROM_RESET_KEYS,
  UPDATE_IS_PROJECT_CREATING,
  UPDATE_LOADING_PROJECTS,
  UPDATE_PROJECTS,
  UPDATE_PROJECTS_NETWORKS,
  UPDATE_PROJECTS_NETWORKS_LOADING,
} from "../actions/projectsActions";
import { StoreType } from "../types/store.types";

export interface projectsStateType {
  allProjectsLoading: boolean;
  projects: Project[];
  isProjectCreating: boolean;
  loadingProjectsIds: string[];
  resetKeysIds: string[];
  chainsNetworks: GetProjectsBlockchain[];
  networksLoading: boolean;
}

export const projectsInitialState: projectsStateType = {
  allProjectsLoading: false,
  projects: [],
  isProjectCreating: false,
  loadingProjectsIds: [],
  resetKeysIds: [],
  chainsNetworks: [],
  networksLoading: false,
};

const projectsReducer = (state = projectsInitialState, action: any) => {
  switch (action.type) {
    case UPDATE_LOADING_PROJECTS: {
      return {
        ...state,
        allProjectsLoading: action.payload.status,
      };
    }
    case UPDATE_PROJECTS: {
      return {
        ...state,
        projects: action.payload.projects,
      };
    }
    case UPDATE_IS_PROJECT_CREATING: {
      return {
        ...state,
        isProjectCreating: action.payload.status,
      };
    }
    case ADD_TO_LOADING_PROJECTS: {
      return {
        ...state,
        loadingProjectsIds: [...state.loadingProjectsIds, action.payload.id],
      };
    }
    case REMOVE_FROM_LOADING_PROJECTS: {
      return {
        ...state,
        loadingProjectsIds: state.loadingProjectsIds.filter((item) => item !== action.payload.id),
      };
    }
    case ADD_TO_RESET_KEYS: {
      return {
        ...state,
        resetKeysIds: [...state.resetKeysIds, action.payload.id],
      };
    }
    case REMOVE_FROM_RESET_KEYS: {
      return {
        ...state,
        resetKeysIds: state.resetKeysIds.filter((item) => item !== action.payload.id),
      };
    }
    case UPDATE_PROJECTS_NETWORKS: {
      return {
        ...state,
        chainsNetworks: action.payload.networks,
      };
    }
    case UPDATE_PROJECTS_NETWORKS_LOADING: {
      return {
        ...state,
        networksLoading: action.payload.status,
      };
    }
    default:
      return { ...state };
  }
};

export const getAllprojects = (state: StoreType) => state.projects.projects;
export const getProjectsLoading = (state: StoreType) => state.projects.allProjectsLoading;
export const getIsProjectCreating = (state: StoreType) => state.projects.isProjectCreating;
export const getLoadingProjectsIds = (state: StoreType) => state.projects.loadingProjectsIds;
export const getResetKeysIds = (state: StoreType) => state.projects.resetKeysIds;
export const getChainsNetworks = (state: StoreType) => state.projects.chainsNetworks;
export const getNetworksLoading = (state: StoreType) => state.projects.networksLoading;

export default projectsReducer;
