export const TeamIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.39019 9.1671C7.61749 9.1671 8.61241 8.17218 8.61241 6.94488C8.61241 5.71758 7.61749 4.72266 6.39019 4.72266C5.16289 4.72266 4.16797 5.71758 4.16797 6.94488C4.16797 8.17218 5.16289 9.1671 6.39019 9.1671Z"
      stroke="#212121"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.6771 16.802C11.2583 16.6075 11.5994 15.9753 11.3838 15.4031C10.6227 13.3831 8.67714 11.9453 6.39048 11.9453C4.10381 11.9453 2.15826 13.3831 1.39714 15.4031C1.18159 15.9764 1.5227 16.6086 2.10381 16.802C3.1727 17.1586 4.63826 17.4998 6.39159 17.4998C8.14492 17.4998 9.60937 17.1586 10.6771 16.802Z"
      stroke="#212121"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.3355 6.38976C14.5628 6.38976 15.5577 5.39483 15.5577 4.16753C15.5577 2.94024 14.5628 1.94531 13.3355 1.94531C12.1082 1.94531 11.1133 2.94024 11.1133 4.16753C11.1133 5.39483 12.1082 6.38976 13.3355 6.38976Z"
      stroke="#212121"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.1673 14.6969C15.554 14.6113 16.7273 14.3235 17.6218 14.0246C18.2029 13.8302 18.544 13.198 18.3284 12.6257C17.5673 10.6057 15.6218 9.16797 13.3351 9.16797C12.2495 9.16797 11.2407 9.49241 10.3984 10.0491"
      stroke="#212121"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
