import { CreateProjectFields } from "../forms/createProject/types";
import { Blockchain, Network } from "../types/project";
import { Option } from "../types/newEntry";

export const getBlockchainIcon = (blockchain: Blockchain) => {
  switch (blockchain) {
    case Blockchain.Bitcoin:
      return <img src={"/images/blockchains/Bitcoin.png"} alt="Bitcoin" style={{ width: "18px", height: "18px" }} />;
    case Blockchain.Cardano:
      return <img src={"/images/blockchains/Cardano.png"} alt="Cardano" style={{ width: "18px", height: "18px" }} />;
    case Blockchain.Dogecoin:
      return <img src={"/images/blockchains/Dogecoin.png"} alt="Dogecoin" style={{ width: "18px", height: "18px" }} />;
    case Blockchain.Arch:
      return <img src={"/images/blockchains/Arch.png"} alt="Arch" style={{ width: "18px", height: "18px" }} />;
    default:
      return null;
  }
};

export const getBlockchainLabel = (blockchain: Blockchain) =>
  ({
    [Blockchain.Bitcoin]: "Bitcoin",
    [Blockchain.Cardano]: "Cardano",
    [Blockchain.Dogecoin]: "Dogecoin",
    [Blockchain.Arch]: "Arch Network",
  }[blockchain]);

export const getNetworkLabel = (blockchain: Blockchain, network: Network) => {
  if (blockchain === Blockchain.Bitcoin)
    return {
      [Network.Mainnet]: "Mainnet",
      [Network.Preprod]: "Preprod",
      [Network.Preview]: "Preview",
      [Network.Testnet]: "Testnet4",
    }[network];

  return {
    [Network.Mainnet]: "Mainnet",
    [Network.Preprod]: "Preprod",
    [Network.Preview]: "Preview",
    [Network.Testnet]: "Testnet",
  }[network];
};

export const getFieldRenderValue = (field: CreateProjectFields, value: string, blockchain: Blockchain) => {
  if (field === CreateProjectFields.blockchain) return <span>{getBlockchainLabel(value as Blockchain)}</span>;
  if (field === CreateProjectFields.network) return getNetworkLabel(blockchain, value as Network);
  return value;
};

export const blockhainsSortHelper = (a: Option, b: Option) => {
  const order = [Blockchain.Bitcoin, Blockchain.Cardano, Blockchain.Dogecoin, Blockchain.Arch];
  return order.indexOf(a.value as Blockchain) - order.indexOf(b.value as Blockchain);
};

export const networksSortHelper = (a: Option, b: Option) => {
  const order = [Network.Mainnet, Network.Preview, Network.Preprod, Network.Testnet];
  return order.indexOf(a.value as Network) - order.indexOf(b.value as Network);
};
