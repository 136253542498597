import { CSSProperties } from "react";
import { getTheme as getUiKitTheme, mutateFontSizeResponsiveness } from "@maestro-org/ui-kit";
import { createTheme, PaletteColor, PaletteMode } from "@mui/material";
import { lightPalette } from "./lightPalette";
import { darkPalette } from "./darkPalette";
import { typography } from "./typography";

let uiKitTheme = createTheme(getUiKitTheme("light") as any);

uiKitTheme = mutateFontSizeResponsiveness(uiKitTheme);

const getPalette = (mode: PaletteMode) => {
  const uiKitPalette = getUiKitTheme(mode).palette;
  return {
    ...uiKitPalette,
    ...(mode === "light" ? lightPalette : darkPalette),
    mode,
  };
};

const getTypography = () => {
  const uiKitTypography = uiKitTheme.typography;

  return Object.assign({}, uiKitTypography, typography);
};

export const getTheme = (mode: PaletteMode) => ({
  breakpoints: uiKitTheme.breakpoints,
  borderRadius: uiKitTheme.borderRadius,
  typography: getTypography(),
  palette: getPalette(mode),
});

declare module "@mui/material/Typography" {
  export interface TypographyPropsVariantOverrides {
    secondaryFont: true;
    caption2: true;
    subtitle3: true;
    subtitle4: true;
    textfieldLabel: true;
    authTitle: true;
    dividerText: true;
    authLowerText: true;
    footer1: true;
    footer2: true;
    footer3: true;
    footer4: true;
    footer5: true;
    tableText: true;
    chartTitle: true;
    dateSwitch: true;
    tooltipText: true;
  }
}

declare module "@mui/material/styles" {
  export interface ThemeOptions {
    borderRadius: {
      xxs: string;
      xs: string;
      sm: string;
      ms: string;
      md: string;
      lg: string;
    };
  }
  export interface Theme {
    borderRadius: {
      xxs: string;
      xs: string;
      sm: string;
      ms: string;
      md: string;
      lg: string;
    };
  }
  export interface TypographyVariants {
    secondaryFont: string;
    caption2: CSSProperties;
    subtitle3: CSSProperties;
    subtitle4: CSSProperties;
    textfieldLabel: CSSProperties;
    authTitle: CSSProperties;
    dividerText: CSSProperties;
    authLowerText: CSSProperties;
    footer1: CSSProperties;
    footer2: CSSProperties;
    footer3: CSSProperties;
    footer4: CSSProperties;
    footer5: CSSProperties;
    tableText: CSSProperties;
    chartTitle: CSSProperties;
    dateSwitch: CSSProperties;
    tooltipText: CSSProperties;
  }

  export interface TypographyVariantsOptions {
    secondaryFont?: string;
    caption2: CSSProperties;
    subtitle3: CSSProperties;
    subtitle4: CSSProperties;
    textfieldLabel: CSSProperties;
    authTitle: CSSProperties;
    dividerText: CSSProperties;
    authLowerText: CSSProperties;
    footer1: CSSProperties;
    footer2: CSSProperties;
    footer3: CSSProperties;
    footer4: CSSProperties;
    footer5: CSSProperties;
    tableText: CSSProperties;
    chartTitle: CSSProperties;
    dateSwitch: CSSProperties;
    tooltipText: CSSProperties;
  }

  interface AdditionalTextColorProps {
    darkContrast?: string;
    marketplaceNavigation?: string;
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface CustomPalette {
    textColor: PaletteColor | AdditionalTextColorProps;
    social: any;
    textfield: any;
    button: any;
    contrastText: any;
    checkbox: any;
    chip: any;
    table: any;
    tooltip: any;
    totalRequestVolume: any;
    menu: any;
    navbar: any;
    chart: any;
    requestMethod: any;
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  export interface Palette extends CustomPalette {}
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  export interface PaletteOptions extends CustomPalette {}
}
