import React, { useEffect, useState } from "react";
import { Button } from "@maestro-org/ui-kit";
import { styled, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import { updateTeamMemberRoleServer } from "../../redux/actions/teamsActions";
import { updatePopup } from "../../redux/actions/popupsActions";
import { getUpdateTeamMemberRoleDialog, getPrefilled } from "../../redux/reducers/popupsReducer";
import { toast } from "react-toastify";

import Dialog from "./Dialog";

import { Popups } from "../../types/popups";

const UpdateTeamMemberRoleDialog = () => {
  const isOpen = useSelector(getUpdateTeamMemberRoleDialog);
  const { email, role } = useSelector(getPrefilled) || {};

  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(updatePopup({ popup: Popups.updateTeamMemberRole, status: false }));
  };

  const handleUpdate = () => {
    if (!email || !role) {
      toast.error("Error occurred while updating team user role");
      return;
    }
    dispatch(updateTeamMemberRoleServer(email, role));
    handleClose();
  };

  return (
    <Dialog open={isOpen}>
      <Wrapper>
        <Typography color="grey.A200" variant="h5">
          Update {email} role
        </Typography>
        <Typography color="grey.A200" variant="paragraphMedium">
          Are you sure you want to update this user's role?
        </Typography>
        <Actions>
          <CancelButton onClick={handleClose}>Cancel</CancelButton>
          <Button onClick={handleUpdate}>Update</Button>
        </Actions>
      </Wrapper>
    </Dialog>
  );
};

const Wrapper = styled("div")({
  display: "flex",
  flexDirection: "column",
  rowGap: "40px",
});

const Actions = styled("div")({
  display: "flex",
  alignItems: "center",
  columnGap: "12px",
});

const CancelButton = styled(Button)(({ theme }) => ({
  background: theme.palette.grey["50"],
  color: theme.palette.grey.A200,

  "&:hover": {
    background: theme.palette.grey["50"],
    color: theme.palette.grey.A200,
  },
}));

export default UpdateTeamMemberRoleDialog;
