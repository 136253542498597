import React, { useEffect } from "react";
import { styled } from "@mui/material";

import SettingsLayout from "../../layouts/SettingsLayout";

import { SettingsPages } from "../../types/settings";
import withPrivateRoute from "../../hooks/withPrivateRoute";
import Teams from "./components/Teams";

const TeamPage = () => {
  return (
    <SettingsLayout
      path={[
        { title: "Settings", href: "/" },
        { title: "Subscription", href: "/" },
        { title: "Team", href: "/" },
      ]}
      active={SettingsPages.team}
    >
      <Wrapper>
        <Teams />
      </Wrapper>
    </SettingsLayout>
  );
};

const Wrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  rowGap: "32px",

  "@media (max-height: 1000px)": {
    rowGap: "28px",
  },

  [theme.breakpoints.down("xl")]: {
    rowGap: "25px",
  },
}));

export default withPrivateRoute(TeamPage);
