export const GoogleIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_5102_28371)">
      <path
        d="M19.8042 10.2312C19.8042 9.55141 19.7491 8.86797 19.6315 8.19922H10.1992V12.05H15.6007C15.3765 13.292 14.6563 14.3907 13.6018 15.0888V17.5874H16.8243C18.7166 15.8457 19.8042 13.2736 19.8042 10.2312Z"
        fill="#4285F4"
      />
      <path
        d="M10.1999 20.0017C12.897 20.0017 15.1714 19.1162 16.8286 17.5876L13.6061 15.089C12.7096 15.699 11.5521 16.0444 10.2036 16.0444C7.59474 16.0444 5.38272 14.2843 4.58904 11.918H1.26367V14.4938C2.96127 17.8706 6.41892 20.0017 10.1999 20.0017Z"
        fill="#34A853"
      />
      <path
        d="M4.58467 11.9163C4.16578 10.6743 4.16578 9.32947 4.58467 8.0875V5.51172H1.26297C-0.155365 8.33737 -0.155365 11.6664 1.26297 14.4921L4.58467 11.9163Z"
        fill="#FBBC04"
      />
      <path
        d="M10.1999 3.95805C11.6256 3.936 13.0035 4.47247 14.036 5.45722L16.8911 2.60218C15.0833 0.904587 12.6838 -0.0287217 10.1999 0.000673888C6.41892 0.000673888 2.96126 2.13185 1.26367 5.51234L4.58537 8.08813C5.37537 5.71811 7.59106 3.95805 10.1999 3.95805Z"
        fill="#EA4335"
      />
    </g>
    <defs>
      <clipPath id="clip0_5102_28371">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
