import AddNewProjectDialog from "./AddNewProjectDialog";
import APIKeyCreatedDialog from "./APIKeyCreatedDialog";
import CreateTeamDialog from "./CreateTeamDialog";
import CreateWebhookDialog from "./CreateWebhookDialog";
import DeleteAccountDialog from "./DeleteAccountDialog";
import DeleteProjectDialog from "./DeleteProjectDialog";
import DeleteWebhookDialog from "./DeleteWebhookDialog";
import RenameTeamDialog from "./RenameTeamDialog";
import ResetKeyDialog from "./ResetKeyDialog";
import AddTeamMemberDialog from "./AddTeamMemberDialog";
import UpdateTeamMemberRoleDialog from "./UpdateTeamMemberRoleDialog";
import DeleteTeamMemberDialog from "./DeleteTeamMemberDialog";

const AllDialogs = () => (
  <>
    <APIKeyCreatedDialog />
    <ResetKeyDialog />
    <DeleteProjectDialog />
    <DeleteWebhookDialog />
    <AddNewProjectDialog />
    <CreateWebhookDialog />
    <DeleteAccountDialog />
    <RenameTeamDialog />
    <CreateTeamDialog />
    <AddTeamMemberDialog />
    <UpdateTeamMemberRoleDialog />
    <DeleteTeamMemberDialog />
  </>
);

export default AllDialogs;
