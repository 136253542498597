import * as Yup from "yup";

import { AccountDetailsFields } from "./types";

export const accountDetailsSchema = Yup.object().shape({
  [AccountDetailsFields.firstName]: Yup.string()
    .label("First name")
    .required(({ label }) => `${label} is required`),
  [AccountDetailsFields.lastName]: Yup.string()
    .label("Last name")
    .required(({ label }) => `${label} is required`),
});
