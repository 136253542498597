import { toast } from "react-toastify";
import { takeEvery, put } from "redux-saga/effects";
import { Webhook } from "../../types/webhooks";
import {
  addToLoadingWebhooks,
  CREATE_WEBHOOKS_SERVER,
  DELETE_WEBHOOK_SERVER,
  getWebhooksServer,
  GET_WEBHOOKS_SERVER,
  removeFromLoadingWebhooks,
  updateIsWebhookCreating,
  updateWebhooks,
  updateWebhooksLoading,
  UPDATE_SINGLE_WEBHOOK_SERVER,
} from "../actions/webhooksActions";

const webhooksSagas = [
  takeEvery(GET_WEBHOOKS_SERVER, handleGetWebhooks),
  takeEvery(`${GET_WEBHOOKS_SERVER}_SUCCESS`, handleGetWebhooksSuccess),
  takeEvery(`${GET_WEBHOOKS_SERVER}_FAIL`, handleGetWebhooksFail),

  takeEvery(CREATE_WEBHOOKS_SERVER, handleCreateWebhook),
  takeEvery(`${CREATE_WEBHOOKS_SERVER}_SUCCESS`, handleCreateWebhookSuccess),
  takeEvery(`${CREATE_WEBHOOKS_SERVER}_FAIL`, handleCreateWebhookFail),

  takeEvery(DELETE_WEBHOOK_SERVER, handleDeleteWebhook),
  takeEvery(`${DELETE_WEBHOOK_SERVER}_SUCCESS`, handleDeleteWebhookSuccess),
  takeEvery(`${DELETE_WEBHOOK_SERVER}_FAIL`, handleDeleteWebhookFail),

  takeEvery(UPDATE_SINGLE_WEBHOOK_SERVER, handleUpdateWebhook),
  takeEvery(`${UPDATE_SINGLE_WEBHOOK_SERVER}_SUCCESS`, handleUpdateWebhookSuccess),
  takeEvery(`${UPDATE_SINGLE_WEBHOOK_SERVER}_FAIL`, handleUpdateWebhookFail),
];

function* handleGetWebhooks() {
  yield put(updateWebhooksLoading({ status: true }));
}

function* handleGetWebhooksFail() {
  yield put(updateWebhooksLoading({ status: false }));
  toast.error("Could not get webhooks. Try again later!");
}

function* handleGetWebhooksSuccess(action: any) {
  yield put(updateWebhooksLoading({ status: false }));
  yield put(updateWebhooks({ webhooks: action.payload.data }));
}

function* handleCreateWebhook() {
  yield put(updateIsWebhookCreating({ status: true }));
}

function* handleCreateWebhookFail(action: any) {
  const error = action.error.response.data.error;
  if (
    error === "Webhook limit reached. To create more webhooks, upgrade to a higher subscription tier." ||
    error === "Webhook with the same name already exists."
  )
    toast.error(error);
  else toast.error("Could not create a new webhook. Try again later!");
  yield put(updateIsWebhookCreating({ status: false }));
}

function* handleCreateWebhookSuccess() {
  yield put(updateIsWebhookCreating({ status: false }));
  toast.success("New webhook was successfully created");
  yield put(getWebhooksServer({}));
}

function* handleDeleteWebhook(action: any) {
  yield put(addToLoadingWebhooks({ id: action.payload.id }));
}

function* handleDeleteWebhookFail(action: any) {
  yield put(removeFromLoadingWebhooks({ id: action.meta.previousAction.payload.id }));
  toast.error("Could not delete the webhook. Try again later!");
}

function* handleDeleteWebhookSuccess(action: any) {
  const { id, prevWebhooks } = action.meta.previousAction.payload;
  yield put(removeFromLoadingWebhooks({ id }));
  yield put(updateWebhooks({ webhooks: prevWebhooks.filter((webhook: Webhook) => webhook.webhook_id !== id) }));
}

function* handleUpdateWebhook(action: any) {
  yield put(addToLoadingWebhooks({ id: action.payload.id }));
}

function* handleUpdateWebhookFail(action: any) {
  yield put(removeFromLoadingWebhooks({ id: action.meta.previousAction.payload.id }));
  toast.error("Could not update the webhook. Try again later!");
}

function* handleUpdateWebhookSuccess(action: any) {
  yield put(removeFromLoadingWebhooks({ id: action.meta.previousAction.payload.id }));
  yield put(getWebhooksServer({}));
}

export default webhooksSagas;
