import React from "react";
import { styled, Typography } from "@mui/material";
import SettingsCard from "./components/SettingsCard";
import ToggleMfaButton from "../../components/Button/ToggleMfaButton";

const MfaSettings = () => {
  return (
    <SettingsCard>
      <Typography color="grey.A200" variant="h6">
        2-Factor Authentication
      </Typography>

      <InnerWrapper>
        <ToggleMfaButton />

        <NoteText color="grey.A200">
          Note: you will be automatically logged out of your account to complete 2FA setup
        </NoteText>
      </InnerWrapper>
    </SettingsCard>
  );
};

const NoteText = styled(Typography)({
  fontSize: "12px",
  fontWeight: "400",
});

const InnerWrapper = styled("div")({
  display: "flex",
  flexDirection: "column",
  gap: "8px",
});

export default MfaSettings;
