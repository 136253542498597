import { DateTabs } from "../modules/Project/components/TotalRequestVolume";
import { ChartData } from "../types/chart";
import { handleSingleDigit } from "./handleSingleDigit";

const parseTime = (time: string, type: DateTabs): string => {
  const date = new Date(time);

  const year = date.getFullYear();
  const month = handleSingleDigit(date.getMonth() + 1);
  const day = handleSingleDigit(date.getDate());
  const hours = handleSingleDigit(date.getHours());
  const minutes = handleSingleDigit(date.getMinutes());
  const weekDay = date.toLocaleDateString("en-US", { weekday: "short" });
  const monthName = date.toLocaleString("en-US", { month: "long" });

  let result = "";
  switch (type) {
    case DateTabs.day: {
      result = `${hours}`;
      break;
    }
    case DateTabs.week: {
      result = `${weekDay} ${day}.${month}`;
      break;
    }
    case DateTabs.month: {
      result = `${day}`;
      break;
    }
    case DateTabs.year: {
      result = `${monthName}${new Date().getFullYear() !== year ? ` ${year}` : ""}`;
      break;
    }
  }

  return result;
};

export const handleTRVtime = (data: ChartData[], type: DateTabs): ChartData[] => {
  const newData = data.map((item) => ({ ...item, time: parseTime(item.time, type) }));

  return newData;
};
