export const darkPalette = {
  social: {
    main: "#7C7C7D",
    dark: "#0F0311",
    light: "#FFFFFF",
    gray: "#F5F5F5",
  },
  textfield: {
    background: "#161217",
    errorText: "#DC6675",
    textColor: "#F5F5F5",
    border: {
      main: "#4E3E51",
      hover: "#866A8A",
      focus: "#C53DD8",
      filled: "#6A546D",
      error: "#DC6675",
      disable: "#322834",
    },
    placeholder: {
      main: "#6A546D",
      hover: "#866A8A",
      focus: "#866A8A",
      filled: "#866A8A",
      error: "#DC6675",
      disable: "#4E3E51",
    },
  },
  contrastText: {
    main: "#C53DD8",
  },
  textColor: {
    dark: "#0F0311",
    main: "#0F0311",
    light: "#F3F2F3",
    contrastText: "#C53DD8",
    darkContrast: "#666666",
    marketplaceNavigation: "#F5F5F5",
    lightGray: "#808080",
  },
  checkbox: {
    colored: { main: "#C53DD8", outlined: "#4E3E51" },
    default: { main: "#A0A0A0", outlined: "#161217" },
  },
  chip: {
    primary: {
      backgroundColor: "#FFFFFF",
      color: "#000000",
    },
    default: {
      backgroundColor: "rgba(255, 255, 255, 0.24)",
      color: "#6A546D",
    },
    success: {
      backgroundColor: "#E9F0E1",
      color: "#4CAF50",
    },
    error: {
      backgroundColor: "rgba(255, 36, 66, 0.06)",
      color: "#DD5363",
    },
    colored: {
      color: "#ffffff",
    },
  },
  table: {
    headerText: "#808080",
    activeRowBg: "#FFFFFF",
    rowBg: "#f5f5f5",
  },
  tooltip: {
    background: "#FFFFFF",
  },
  totalRequestVolume: {
    dateSwitcher: {
      background: "#F5F5F5",
    },
  },
  menu: {
    border: "#CCCCCC",
    grayText: "#B3B3B3",
    refColor: "#A926BB",
  },
  navbar: {
    planText: "#FF9F38",
  },
  chart: {
    barFirst: "#5C1466",
    barFirstDisabled: "#6A546D",
    barSecond: "#A926BB",
    barSecondDisabled: "#9E86A2",
    barThird: "#D166E0",
    barThirdDisabled: "#cca1d3",
    barFourth: "#EBBBF1",
    barFourthDisabled: "#EFEBEF",
    tooltipCursor: "rgba(247, 230, 250, 0.498)",
  },
  requestMethod: {
    POST: {
      primary: "#C53DD8",
      secondary: "#DE91E9",
    },
    PUT: {
      primary: "#6A546D",
      secondary: "#9E86A2",
    },
    GET: {
      primary: "#CAA811",
      secondary: "#ECC722",
    },
    DELETE: {
      primary: "#808080",
      secondary: "#999999",
    },
  },
};
