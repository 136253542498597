import { Button, IconButton } from "@maestro-org/ui-kit";
import { Menu, MenuItem, styled, Typography } from "@mui/material";
import { FC, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ArrowDownSmallIcon } from "../../../../components/Icons/ArrowDownSmallIcon";
import { CopySmallIcon } from "../../../../components/Icons/CopySmallIcon";
import { updatePopup } from "../../../../redux/actions/popupsActions";
import { Popups } from "../../../../types/popups";
import { UserRoles } from "../../../../types/roles";
import { TeamProps } from "../../../../redux/actions/teamsActions";
import { getUser } from "../../../../redux/reducers/usersReducer";

interface Props {
  teamData?: TeamProps;
}

const TeamCardTop: FC<Props> = ({ teamData }) => {
  const dispatch = useDispatch();
  const user = useSelector(getUser);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleRenameTeam = () => {
    dispatch(updatePopup({ popup: Popups.renameTeam, status: true, prefilled: teamData?.team_name }));
    handleClose();
  };

  const findUserByEmail = (teamData?: TeamProps) => {
    const findUser = teamData?.users.find((u) => u.email === user.email);
    return findUser ? [UserRoles.ADMIN, UserRoles.OWNER].includes(findUser.role_name as UserRoles) : false;
  };

  return (
    <Wrapper>
      <Right>
        <TeamTitleWrapper>
          <Typography variant="h6" color="grey.A200">
            {teamData?.team_name}
          </Typography>
          {findUserByEmail(teamData) && (
            <>
              <StyledIconButton variant="light" onClick={handleClick}>
                <ArrowDownSmallIcon />
              </StyledIconButton>

              <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
                <StyledMenuItem onClick={handleRenameTeam}>Rename</StyledMenuItem>
              </Menu>
            </>
          )}
        </TeamTitleWrapper>
        <Typography variant="textfieldLabel" color="#999999">
          {teamData && teamData.users ? `${teamData.users.length} ` : `0 `}
          collaborator{teamData && teamData.users.length > 1 && "s"} in this team
        </Typography>
      </Right>
    </Wrapper>
  );
};

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: "6px",

  color: "#333333",
  fontSize: "14px",
  fontWeight: "400",
  lineHeight: "20px",

  "&:hover": {
    color: theme.palette.primary.main,

    path: {
      fill: theme.palette.primary.main,
    },
  },
}));

const StyledIconButton = styled(IconButton)({
  "&.MuiIconButton-root": {
    padding: "0",
    width: "20px",
    height: "20px",
    backgroundColor: "#F5F5F5",

    svg: {
      fill: "none",
    },
  },
});

const TeamTitleWrapper = styled("div")({
  display: "flex",
  alignItems: "center",
  gap: "8px",
});

const SaveText = styled(Typography)({
  fontSize: "14px",
  fontWeight: "400",
  lineHeight: "16px",
});

const StyledButton = styled(Button)({
  "&.MuiButton-root": {
    borderRadius: "6px",
    padding: "8px 16px",
  },
});

const Right = styled("div")({
  display: "flex",
  flexDirection: "column",
  gap: "8px",
});

const Wrapper = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});

export default TeamCardTop;
