import { Auth0DecodedHash, Auth0Error, Auth0UserProfile } from "auth0-js";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { createUserServer } from "../redux/actions/userActions";
import {
  updateIsAuthorized,
  updateAuth0Data,
  setIsUpdatingUserInfo,
  getUserDataServer,
  updateUserMetadataServer,
} from "../redux/actions/authActions";

import { auth } from "../services/auth0.service";

import { LoginType } from "../types/auth";

import { getLoginType, parseGithubLoginData, parseGoogleLoginData } from "../lib/authUtils";

import { AUTH0_LOGIN_REDIRECT_URI } from "../config";
import { getAuth0FuncData, getAuthData } from "../redux/reducers/authReducer";
import { getUserCreated } from "../redux/reducers/usersReducer";

import { getQueryTeamId } from "../redux/reducers/teamsReducer";
import { getUserOrganization } from "../redux/reducers/usersReducer";

export const useAuth = () => {
  const [verified, setVerified] = useState(true);

  const data = useSelector(getAuthData);
  const auth0Data = useSelector(getAuth0FuncData);
  const userCreated = useSelector(getUserCreated);

  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const queryTeamId = useSelector(getQueryTeamId);
  const organization = useSelector(getUserOrganization);

  const checkEmailVerification = (userData: any) => {
    if (userData?.email_verified || (userData?.sub && userData.sub.includes("github"))) {
      navigate("/?loginSuccess");
    } else {
      setVerified(false);
      toast.info("Please verify your email address", { toastId: "Please verify your email address" });
      navigate("/?loginSuccess");
      // toast.error("You need to verify your email address");
      // navigate("/login");
    }
  };

  const getUserInfo = (accessToken: string, expiresIn: number) => {
    auth.client.userInfo(accessToken, (error: Auth0Error | null, userData: Auth0UserProfile) => {
      if (error) {
        return;
      }

      const loginType = getLoginType(userData.sub);
      let dataOrigin = {
        email: "",
        email_verified: true,
        firstName: "",
        lastName: "",
        picture: "",
        nickname: "",
      };
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (loginType === LoginType.Regular) dataOrigin = userData;
      else {
        if (loginType === LoginType.Github) dataOrigin = parseGithubLoginData(userData);
        else if (loginType === LoginType.Google) dataOrigin = parseGoogleLoginData(userData);
      }

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const { email, email_verified, firstName, lastName, picture, nickname, sub } = dataOrigin;

      if (loginType === LoginType.Regular)
        dispatch(
          createUserServer({ email: email, auth0_user_id: sub, team_id: queryTeamId, organization: organization }),
        );
      else dispatch(createUserServer({ email: email || "", auth0_user_id: sub, team_id: queryTeamId }));

      dispatch(
        updateUserMetadataServer({
          firstName: firstName || nickname || "",
          lastName: lastName || "",
        }),
      );
      dispatch(
        updateAuth0Data({
          accessToken,
          expiresIn,
          email_verified,
          loginType: LoginType[loginType],
        }),
      );
      dispatch(getUserDataServer());
      dispatch(updateIsAuthorized({ status: true }));
      dispatch(setIsUpdatingUserInfo({ status: false }));
    });
  };

  const parseHash = (hash: string) => {
    auth.parseHash(
      {
        hash,
      },
      (error: Auth0Error | null, result: Auth0DecodedHash | null) => {
        if (error) {
          return;
        }
        if (!result) return;

        const { accessToken, expiresIn, idToken } = result;
        if (!accessToken || !expiresIn) return;
        getUserInfo(accessToken, expiresIn);
      },
    );
  };

  const handleCheckSession = () => {
    dispatch(setIsUpdatingUserInfo({ status: true }));
    auth.checkSession(
      {
        responseType: process.env.REACT_APP_AUTH0_LOGIN_RESPONSE_TYPE,
        redirectUri: AUTH0_LOGIN_REDIRECT_URI,
        scope: process.env.REACT_APP_AUTH0_USER_SCOPE,
      },
      (err, authResult) => {
        if (err) {
          dispatch(setIsUpdatingUserInfo({ status: false }));
          return;
        }
        const { accessToken, expiresIn } = authResult;
        if (!accessToken || !expiresIn) return;
        getUserInfo(accessToken, expiresIn);
      },
    );
  };

  useEffect(() => {
    const { hash } = location;
    if (!hash) return;
    parseHash(hash);
  }, [location]);

  // useEffect(() => {
  //   dispatch(getUserData());
  // }, []);

  useEffect(() => {
    if (!userCreated) return;

    checkEmailVerification({ ...data, ...auth0Data });
  }, [userCreated]);

  return { verified, handleCheckSession };
};
