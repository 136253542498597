import React, { useEffect, useMemo } from "react";
import { styled, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import { getAuthData } from "../../redux/reducers/authReducer";
import Switch from "../Switch/Switch";
import { getUserAuthenticatorsServer, updateUserMetadataServer } from "../../redux/actions/authActions";

const ToggleMfaButton = () => {
  const dispatch = useDispatch();
  const authData = useSelector(getAuthData);

  const isUseMfa = useMemo(() => !!authData?.user_metadata?.use_mfa, [authData]);

  const handleToggleMfa = async () => {
    if (isUseMfa) {
      dispatch(getUserAuthenticatorsServer());
    } else {
      dispatch(updateUserMetadataServer({ use_mfa: !isUseMfa }));
    }
  };

  return (
    <SwitchRow>
      <Typography color="grey.A200" variant="paragraphMedium">
        {isUseMfa ? "Enabled" : "Enable 2FA"}
      </Typography>
      <Switch checked={isUseMfa} handleChange={handleToggleMfa} />
    </SwitchRow>
  );
};

const SwitchRow = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
});

export default ToggleMfaButton;
