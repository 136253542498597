import { Webhook } from "../../types/webhooks";
import {
  ADD_TO_LOADING_WEBHOOKS,
  REMOVE_FROM_LOADING_WEBHOOKS,
  UPDATE_ACTIVE_PROJECT_ID,
  UPDATE_WEBHOOKS,
  UPDATE_WEBHOOKS_LOADING,
  UPDATE_WEBHOOK_IS_CREATING,
} from "../actions/webhooksActions";
import { StoreType } from "../types/store.types";

export interface webhooksStateType {
  allWebhooksLoading: boolean;
  webhooks: Webhook[];
  isWebhookCreating: boolean;
  loadingWebhooksIds: string[];
  activeProjectId: string;
}

export const webhooksInitialState: webhooksStateType = {
  allWebhooksLoading: false,
  webhooks: [],
  isWebhookCreating: false,
  loadingWebhooksIds: [],
  activeProjectId: "",
};

const webhooksReducer = (state = webhooksInitialState, action: any) => {
  switch (action.type) {
    case UPDATE_WEBHOOKS: {
      return {
        ...state,
        webhooks: action.payload.webhooks,
      };
    }
    case UPDATE_WEBHOOKS_LOADING: {
      return {
        ...state,
        allWebhooksLoading: action.payload.status,
      };
    }
    case UPDATE_WEBHOOK_IS_CREATING: {
      return {
        ...state,
        isWebhookCreating: action.payload.status,
      };
    }
    case ADD_TO_LOADING_WEBHOOKS: {
      return {
        ...state,
        loadingWebhooksIds: [...state.loadingWebhooksIds, action.payload.id],
      };
    }
    case REMOVE_FROM_LOADING_WEBHOOKS: {
      return {
        ...state,
        loadingWebhooksIds: state.loadingWebhooksIds.filter((webhookId) => webhookId !== action.payload.id),
      };
    }
    case UPDATE_ACTIVE_PROJECT_ID: {
      return {
        ...state,
        activeProjectId: action.payload.id,
      };
    }
    default: {
      return { ...state };
    }
  }
};

export const getAllWebhooks = (state: StoreType) => state.webhooks.webhooks;
export const getAllWebhooksLoading = (state: StoreType) => state.webhooks.allWebhooksLoading;
export const getIsWebhookCreating = (state: StoreType) => state.webhooks.isWebhookCreating;
export const getLoadingWebhooksIds = (state: StoreType) => state.webhooks.loadingWebhooksIds;

export default webhooksReducer;
