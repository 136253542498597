import { FC, useState } from "react";
import { ThreeDotsIcon } from "../../../../components/Icons/ThreeDotsIcon";
import { Menu, styled, Typography } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { Chip } from "@maestro-org/ui-kit";
import IconButton from "../../../../components/Button/IconButton";
import { ITeamUser } from "../../../../redux/actions/teamsActions";
import Tooltip from "../../../../components/Tooltip/Tooltip";

import MemberRoleMenu from "./MemberRoleMenu";
import MemberImage from "./MemberImage";
import { UserRoles, UserStatus } from "../../../../types/roles";

import { useDispatch, useSelector } from "react-redux";

import { Popups } from "../../../../types/popups";
import { updatePopup } from "../../../../redux/actions/popupsActions";

import { getUser } from "../../../../redux/reducers/usersReducer";

const getChipData = (mfa: boolean) => {
  return {
    label: mfa ? "Enabled" : "Disabled",
    hexColor: mfa ? "#E2EDDE" : "#EED7DB",
    textColor: mfa ? "#53954A" : "#DC6675",
  };
};

interface Props {
  elem: ITeamUser;
}

const TeamCardUserItem: FC<Props> = ({ elem }) => {
  const dispatch = useDispatch();

  const user = useSelector(getUser);

  const isUserPending = elem.status === UserStatus.PENDING || elem.status === UserStatus.INACTIVE;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const capitalizeFirstLetter = (role: string) => {
    return role.charAt(0).toUpperCase() + role.slice(1);
  };

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = () => {
    dispatch(updatePopup({ popup: Popups.deleteTeamMember, status: true, prefilled: { email: elem.email } }));
    handleClose();
  };

  return (
    <StyledTooltip
      title={
        <Typography variant="paragraphSmall" color="grey.A200">
          The user has not yet accepted the invite.
        </Typography>
      }
      // followCursor
      disableHoverListener={!isUserPending}
    >
      <Tr isDisabled={isUserPending}>
        <EmailTd>
          <MemberImage user={elem} />

          {elem.email}
          {elem.email === user.email && <OwnerText>(you)</OwnerText>}
        </EmailTd>

        <RoleTd>
          {capitalizeFirstLetter(elem.role_name)}
          {!(
            elem.role_name === UserRoles.OWNER ||
            user.team_role_name === UserRoles.DEVELOPER ||
            user.team_role_name === UserRoles.MEMBER ||
            user.email === elem.email
          ) && <MemberRoleMenu currentRole={elem.role_name} email={elem.email} isUserPending={isUserPending} />}
        </RoleTd>

        {(user.team_role_name === UserRoles.OWNER ||
          user.team_role_name === UserRoles.ADMIN ||
          (user.email === elem.email && elem.role_name === UserRoles.OWNER) ||
          (user.email === elem.email && elem.role_name === UserRoles.ADMIN)) && (
          <>
            <MfaTd>
              {!isUserPending && (
                <>{elem.use_mfa !== null && <StyledChip color={"custom"} {...getChipData(elem.use_mfa)} />}</>
              )}
            </MfaTd>

            <ManageTd>
              {!(elem.role_name === UserRoles.OWNER) && (
                <>
                  <StyledIconButton onClick={handleClick}>
                    <ThreeDotsIcon />
                  </StyledIconButton>

                  <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
                    {elem.status === "inactive" && <StyledMenuItem onClick={handleClose}>Resend</StyledMenuItem>}
                    <StyledMenuItem onClick={handleDelete}>Delete</StyledMenuItem>
                  </Menu>
                </>
              )}
            </ManageTd>
          </>
        )}
      </Tr>
    </StyledTooltip>
  );
};

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  color: "#333333",
  fontSize: "14px",
  fontWeight: "400",
  lineHeight: "20px",

  "&:hover": {
    color: theme.palette.primary.main,
  },
}));

const MfaTd = styled("div")({
  width: "14%",
});

const ManageTd = styled("div")({
  width: "9%",
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
});

const RoleTd = styled("div")({
  width: "27%",
  alignItems: "center",
  display: "flex",
});

const EmailTd = styled("div")({
  display: "flex",
  alignItems: "center",
  width: "50%",
});

const DisabledAvatar = styled("div")({
  borderRadius: "50%",
  width: "28px",
  height: "28px",
  marginRight: "8px",

  border: "1px solid #999999",
  borderStyle: "dashed",
});

const StyledAvatar = styled("img")({
  borderRadius: "50%",
  width: "28px",
  height: "28px",
  marginRight: "8px",
});

const StyledChip = styled<any>(Chip)(({ textColor }) => ({
  color: textColor,
  fontSize: "10px",
  fontWeight: "500",
  lineHeight: "16px",
  textTransform: "uppercase",
  cursor: "unset",
  padding: "2px 12px",
  height: "fit-content",

  "&:hover": {
    color: textColor,
  },
}));

const StyledIconButton = styled(IconButton)({
  padding: "2px 10px",
});

const OwnerText = styled("span")({
  marginLeft: "4px",

  color: "#999999",
});

const Tr = styled<any>("div")(({ isDisabled }) => ({
  display: "flex",

  color: isDisabled ? "#999999" : "#0F0311",

  fontSize: "14px",
  fontWeight: "400",
  lineHeight: "20px",
}));

const StyledTooltip = styled(Tooltip)(({ theme }) => ({
  "& .MuiTooltip-tooltip": {
    maxWidth: "236px",
    padding: "16px",
    boxShadow: "0px 2px 16px -2px rgba(15, 3, 17, 0.16)",
    border: `1px solid ${theme.palette.grey[100]}`,
    borderRadius: "6px",
  },
}));

export default TeamCardUserItem;
