import { IconButton } from "@maestro-org/ui-kit";
import { Dialog as MuiDialog, DialogProps, styled } from "@mui/material";
import React from "react";

import { CloseDialogIcon } from "../Icons";

const Dialog = ({ children, ...props }: DialogProps) => {
  return <StyledDialog {...props}>{children}</StyledDialog>;
};

const StyledDialog = styled(MuiDialog)(({ theme }) => ({
  "& .MuiPaper-root": {
    padding: "56px",
    width: "707px",
    maxWidth: "90%",
    borderRadius: theme.borderRadius.xxs,
    boxShadow: "0px 8px 30px -2px rgba(0, 0, 0, 0.04)",
    border: `1px solid ${theme.palette.grey[200]}`,
    position: "relative",
    display: "flex",
    flexDirection: "column",
    rowGap: "36px",
  },
  backdropFilter: "blur(8px)",
  background: "rgba(179, 179, 179, 0.4)",

  "& .MuiBackdrop-root": {
    background: "transparent",
  },

  [theme.breakpoints.down("md")]: {
    "& .MuiPaper-root": {
      padding: "40px 32px",
    },
  },
}));

const CloseButton = styled(IconButton)(({ theme }) => ({
  background: "transparent",
  width: "35px",
  height: "35px",
  position: "absolute",
  top: "30px",
  right: "30px",

  "&:hover": {
    background: "transparent",
  },

  "& svg": {
    fill: theme.palette.grey.A200,
  },

  "& rect": {
    fill: theme.palette.grey.A200,
    stroke: theme.palette.grey.A200,
  },
}));

export default Dialog;
