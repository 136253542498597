import { Button } from "@maestro-org/ui-kit";
import { styled, Typography } from "@mui/material";
import React, { useEffect } from "react";

import Loader from "./Loader";
import { InfoIcon } from "../../../components/Icons";
import { useDispatch, useSelector } from "react-redux";
import { getUser } from "../../../redux/reducers/usersReducer";
import { setSubscriptionServer } from "../../../redux/actions/stripeActions";
import { Package, PackagePriceData } from "../../../redux/reducers/stripeReducer";

interface Props {
  selectedPlan?: Package;
  selectedTier?: PackagePriceData;
}

const PaymentInProcess = ({ selectedPlan, selectedTier }: Props) => {
  const user = useSelector(getUser);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!user || !selectedPlan) return;

    dispatch(
      setSubscriptionServer({
        payment_method_id: user.default_payment_method_id,
        package_id: selectedPlan.id,
        tier_id: selectedTier?.id || "",
      }),
    );
  }, []);

  return (
    <Wrapper>
      <Typography color="grey.A200" variant="h5">
        Waiting for payment
      </Typography>
      <LoaderWrapper>
        <Loader />
        <Typography color="grey.A200" variant="paragraphSmall">
          Payment is processing...
          <br />
          Please wait, do not close this screen.
        </Typography>
      </LoaderWrapper>
      <Bottom>
        <BottomInfo>
          <InfoIcon />
          <Typography color="grey.300" variant="article">
            Subscription changes take effect immediately and previous plan terms no longer apply.
          </Typography>
        </BottomInfo>
        <Button disabled>
          <Typography variant="paragraphSmall">Payment in process</Typography>
        </Button>
      </Bottom>
    </Wrapper>
  );
};

const Wrapper = styled("div")({
  display: "flex",
  flexDirection: "column",
  rowGap: "40px",
});

const LoaderWrapper = styled("div")({
  display: "flex",
  flexDirection: "column",
  rowGap: "16px",
  alignItems: "center",
  textAlign: "center",
});

const Bottom = styled("div")({
  paddingTop: "16px",
  display: "flex",
  flexDirection: "column",
  rowGap: "24px",
});

const BottomInfo = styled("div")({
  display: "flex",
  alignItems: "flex-start",
  columnGap: "12px",
});

export default PaymentInProcess;
