import { FC } from "react";

import { styled, Typography } from "@mui/material";

import { ProjectsIcon, WebhooksIcon, RequestsIcon } from "../../../components/Icons";

import { getWordPlural } from "../../../lib/subscriptionPlanUtils";

interface Props {
  throughputLimit: string;
  projectLimit: number;
  webhookLimit: number;
}

const PlanPackageData: FC<Props> = ({ throughputLimit, webhookLimit, projectLimit }) => {
  return (
    <Wrapper>
      <Row>
        <RequestsIcon />
        <DataText color="textColor.dark">{throughputLimit} requests/sec</DataText>
      </Row>
      <Row>
        <ProjectsIcon />
        <DataText color="textColor.dark">
          {projectLimit} {getWordPlural(projectLimit, "project")}
        </DataText>
      </Row>
      <Row>
        <WebhooksIcon />
        <DataText color="textColor.dark">
          {webhookLimit} {getWordPlural(webhookLimit, "webhook")}
        </DataText>
      </Row>
    </Wrapper>
  );
};

const Wrapper = styled("div")({
  display: "flex",
  flexDirection: "column",
  gap: "12px",
});

const Row = styled("div")({
  display: "flex",
  alignItems: "center",
  gap: "6px",
});

const DataText = styled(Typography)({
  fontSize: "16px",
  fontWeight: "300",
  lineHeight: "24px",
});

export default PlanPackageData;
