import React from "react";
import { styled } from "@mui/material";
import { Link } from "react-router-dom";

export enum LogoStyles {
  Dark,
  Colored,
  WithText,
  Grey,
}
interface LogoProps {
  style: LogoStyles;
}

const ResponsiveLogo = styled("img")`
  /* width: 200px; */
  cursor: pointer;

  /* @media (max-width: 600px) {
    width: 154px;
  } */
`;

const logoVariants = {
  [LogoStyles.Dark]: <ResponsiveLogo src="/logos/LandingLogos/DarkLogo.svg" alt="logo" />,
  [LogoStyles.Colored]: <img src="/logos/LandingLogos/ColoredLogo.png" width={64} height={61} alt="logo" />,
  [LogoStyles.WithText]: <ResponsiveLogo src="/logos/LandingLogos/WithText.svg" alt="" />,
  [LogoStyles.Grey]: <ResponsiveLogo src="/logos/LandingLogos/GreyLogo.svg" alt="" />,
};
const Logo = ({ style }: LogoProps) => <Link to="/">{logoVariants[style]}</Link>;

export default Logo;
