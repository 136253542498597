import { StripeItem } from "../../types/auth";
import { FeaturesProps } from "../../types/subscription";
import { StripeCustomer } from "../../types/stripeCustomer";
import {
  UPDATE_BILLING_DATA,
  UPDATE_BILLING_DATA_LOADING,
  UPDATE_CLIENT_SECRET,
  UPDATE_CURRENT_PAYMENT_METHOD,
  UPDATE_CUSTOMER,
  UPDATE_CUSTOMER_LOADING,
  UPDATE_LOADING_PAYMENT_METHODS,
  UPDATE_SUBSCRIPTIONS,
  UPDATE_SUBSCRIPTIONS_LOADING,
  UPDATE_SUBSCRIPTION_FEATURES,
  UPDATE_SUBSCRIPTION_FEATURES_LOADING,
  UPDATE_SUBSCRIPTION_TIERS,
  UPDATE_SUBSCRIPTION_TIERS_LOADING,
  UPDATE_USER_CURRENT_SUBSCRIPTION,
  UPDATE_USER_CURRENT_SUBSCRIPTION_LOADING,
  UPDATE_USER_UPDATED_SUBSCRIPTIONS_SERVER,
  UPDATE_USER_UPDATED_SUBSCRIPTIONS_SERVER_LOADING,
  UPDATE_USER_UPDATED_FEATURES_SERVER,
  UPDATE_USER_UPDATED_FEATURES_SERVER_LOADING,
  UPDATE_USER_UPDATED_SUBSCRIPTION_TIERS,
  UPDATE_USER_UPDATED_SUBSCRIPTION_TIERS_LOADING,
  SET_ACTIVE_CHECKOUT_STEP,
} from "../actions/stripeActions";
import { StoreType } from "../types/store.types";
import { SubscriptionPlan } from "../../types/subscription";
import { CheckoutSteps } from "../../types/settings";

export interface Package {
  id: string;
  name: SubscriptionPlan;
  description: string;
  credits_per_day: number;
  credits_per_month: number;
  project_limit: number;
  webhook_limit: number;
  throughput_limit: string;
  features: string[];
}

export interface PackagePriceData {
  id: string;
  package_id: string;
  credits_per_month: number;
  price_per_credit: number;
  price_per_month: number;
  price_per_year?: number;
  value_per_month: number;
}

export interface TiersProps {
  annual_tiers: PackagePriceData[];
  monthly_tiers: PackagePriceData[];
}

export interface stripeStateType {
  isLoading: boolean;
  paymentMethods: StripeItem[];
  activeMethodId: string;
  clientSecret: string;
  loadingMethodsIds: string[];
  customer?: StripeCustomer;
  customerLoading: boolean;
  oldSubscriptionPlans: Package[];
  oldSubscriptionTiers: TiersProps;
  isOldSubscriptionsLoading: boolean;
  oldSubscriptionFeatures: FeaturesProps[];
  isOldFeaturesLoading: boolean;
  isOldTiersLoading: boolean;
  userCurrentSubscription: any | undefined;
  isCurrentSubscriptionLoading: boolean;
  subscriptionPlans: Package[];
  isSubscriptionPlansLoading: boolean;
  subscriptionFeatures: FeaturesProps[];
  isFeaturesLoading: boolean;
  subscriptionTiers: TiersProps;
  isTiersLoading: boolean;
  activeCheckoutStep?: CheckoutSteps;
}

const stripeInitialState: stripeStateType = {
  isLoading: true,
  paymentMethods: [],
  activeMethodId: "",
  clientSecret: "",
  loadingMethodsIds: [],
  customer: undefined,
  customerLoading: false,
  oldSubscriptionPlans: [],
  isOldSubscriptionsLoading: true,
  oldSubscriptionFeatures: [],
  isOldFeaturesLoading: true,
  isOldTiersLoading: true,
  userCurrentSubscription: undefined,
  isCurrentSubscriptionLoading: true,
  subscriptionPlans: [],
  isSubscriptionPlansLoading: false,
  subscriptionFeatures: [],
  isFeaturesLoading: false,
  oldSubscriptionTiers: {
    monthly_tiers: [],
    annual_tiers: [],
  },
  subscriptionTiers: {
    monthly_tiers: [],
    annual_tiers: [],
  },
  isTiersLoading: false,
};

const stripeReducer = (state = stripeInitialState, action: any) => {
  switch (action.type) {
    case UPDATE_BILLING_DATA: {
      return {
        ...state,
        paymentMethods: [...action.payload.data.paymentMethods],
      };
    }
    case UPDATE_BILLING_DATA_LOADING: {
      return {
        ...state,
        isLoading: action.payload.status,
      };
    }
    case UPDATE_CURRENT_PAYMENT_METHOD: {
      return {
        ...state,
        activeMethodId: action.payload.id,
      };
    }
    case UPDATE_CLIENT_SECRET: {
      return {
        ...state,
        clientSecret: action.payload.clientSecret,
      };
    }
    case UPDATE_LOADING_PAYMENT_METHODS: {
      return {
        ...state,
        loadingMethodsIds: action.payload.methodsIds,
      };
    }
    case UPDATE_CUSTOMER: {
      return {
        ...state,
        customer: action.payload.customer,
      };
    }
    case UPDATE_CUSTOMER_LOADING: {
      return {
        ...state,
        customerLoading: action.payload.status,
      };
    }
    case UPDATE_SUBSCRIPTIONS: {
      return {
        ...state,
        oldSubscriptionPlans: action.payload,
      };
    }
    case UPDATE_SUBSCRIPTIONS_LOADING: {
      return {
        ...state,
        isOldSubscriptionsLoading: action.payload.status,
      };
    }
    case UPDATE_USER_UPDATED_SUBSCRIPTIONS_SERVER: {
      return {
        ...state,
        subscriptionPlans: action.payload,
      };
    }
    case UPDATE_USER_UPDATED_SUBSCRIPTIONS_SERVER_LOADING: {
      return {
        ...state,
        isSubscriptionPlansLoading: action.payload.status,
      };
    }
    case UPDATE_USER_UPDATED_FEATURES_SERVER: {
      return {
        ...state,
        subscriptionFeatures: action.payload,
      };
    }
    case UPDATE_USER_UPDATED_FEATURES_SERVER_LOADING: {
      return {
        ...state,
        isFeaturesLoading: action.payload.status,
      };
    }
    case UPDATE_SUBSCRIPTION_FEATURES: {
      return {
        ...state,
        oldSubscriptionFeatures: action.payload,
      };
    }
    case UPDATE_SUBSCRIPTION_FEATURES_LOADING: {
      return {
        ...state,
        isOldFeaturesLoading: action.payload.status,
      };
    }
    case UPDATE_SUBSCRIPTION_TIERS: {
      return {
        ...state,
        oldSubscriptionTiers: action.payload,
      };
    }
    case UPDATE_SUBSCRIPTION_TIERS_LOADING: {
      return {
        ...state,
        isOldTiersLoading: action.payload.status,
      };
    }
    case UPDATE_USER_UPDATED_SUBSCRIPTION_TIERS: {
      return {
        ...state,
        subscriptionTiers: action.payload,
      };
    }
    case UPDATE_USER_UPDATED_SUBSCRIPTION_TIERS_LOADING: {
      return {
        ...state,
        isTiersLoading: action.payload.status,
      };
    }
    case UPDATE_USER_CURRENT_SUBSCRIPTION: {
      return {
        ...state,
        userCurrentSubscription: action.payload,
      };
    }
    case UPDATE_USER_CURRENT_SUBSCRIPTION_LOADING: {
      return {
        ...state,
        isCurrentSubscriptionLoading: action.payload.status,
      };
    }
    case SET_ACTIVE_CHECKOUT_STEP: {
      return {
        ...state,
        activeCheckoutStep: action.payload.step,
      };
    }
    default: {
      return { ...state };
    }
  }
};

export const getAllPaymentMethods = (state: StoreType) => state.stripe.paymentMethods;
export const getBillingDataLoading = (state: StoreType) => state.stripe.isLoading;
export const getActiveMethodId = (state: StoreType) => state.stripe.activeMethodId;
export const getClientSecret = (state: StoreType) => state.stripe.clientSecret;
export const getLoadingPaymentMethods = (state: StoreType) => state.stripe.loadingMethodsIds;
export const getStripeCustomer = (state: StoreType) => state.stripe.customer;
export const getStripeCustomerLoading = (state: StoreType) => state.stripe.customerLoading;
export const getOldSubscriptionPlans = (state: StoreType) => state.stripe.oldSubscriptionPlans;
export const getOldSubscriptionsLoading = (state: StoreType) => state.stripe.isOldSubscriptionsLoading;
export const getOldSubscriptionFeatures = (state: StoreType) => state.stripe.oldSubscriptionFeatures;
export const getOldFeaturesLoading = (state: StoreType) => state.stripe.isOldFeaturesLoading;
export const getOldTiersLoading = (state: StoreType) => state.stripe.isOldTiersLoading;
export const getCurrentSubscription = (state: StoreType) => state.stripe.userCurrentSubscription;
export const getCurrentSubscriptionLoading = (state: StoreType) => state.stripe.isCurrentSubscriptionLoading;
export const getSubscriptionPlans = (state: StoreType) => state.stripe.subscriptionPlans;
export const getSubscriptionPlansLoading = (state: StoreType) => state.stripe.isSubscriptionPlansLoading;
export const getSubscriptionFeatures = (state: StoreType) => state.stripe.subscriptionFeatures;
export const getSubscriptionFeaturesLoading = (state: StoreType) => state.stripe.isFeaturesLoading;
export const getSubscriptionTiers = (state: StoreType) => state.stripe.subscriptionTiers;
export const getOldSubscriptionTiers = (state: StoreType) => state.stripe.oldSubscriptionTiers;
export const getSubscriptionTiersLoading = (state: StoreType) => state.stripe.isTiersLoading;
export const getActiveCheckoutStep = (state: StoreType) => state.stripe.activeCheckoutStep;

export default stripeReducer;
