import { DateTabs } from "../../modules/Home/components/TotalRequestVolume";
import { UserReuestVolume } from "../../types/user";

export const GET_PROJECTS_REQUEST_VOLUME_SERVER = "GET_PROJECTS_REQUEST_VOLUME_SERVER";
export const UPDATE_PROJECTS_REQUEST_VOLUME = "UPDATE_PROJECTS_REQUEST_VOLUME";
export const UPDATE_PROJECTS_REQUEST_VOLUME_LOADING = "UPDATE_PROJECTS_REQUEST_VOLUME_LOADING";

interface UpdateStatusProps {
  status: boolean;
}

interface UpdateRequestVolumeProps {
  data: UserReuestVolume[];
}

export const getProjectsRequestVolumeServer = () => ({
  type: GET_PROJECTS_REQUEST_VOLUME_SERVER,
  payload: {
    request: {
      method: "GET",
      url: `/metric/user/volume`,
    },
  },
});

export const updateProjectsRequestVolume = ({ data }: UpdateRequestVolumeProps) => ({
  type: UPDATE_PROJECTS_REQUEST_VOLUME,
  payload: { data },
});

export const updateRequestVolumeLoading = ({ status }: UpdateStatusProps) => ({
  type: UPDATE_PROJECTS_REQUEST_VOLUME_LOADING,
  payload: { status },
});
