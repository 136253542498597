import { useFormik } from "formik";
import { Dropdown, TextField } from "@maestro-org/ui-kit";
import { styled, Theme, useTheme } from "@mui/material";

import { NewEntryField, NewEntryFieldTypes } from "../../types/newEntry";
import { CreateProjectFields, CreateProjectFormValues } from "./types";
import { initialValuesCreateProject } from "./form";
import { createProjectSchema } from "./validation";
import { getBlockchainIcon, getFieldRenderValue } from "../../lib/createProject.utils";

interface UseFieldsProps {
  onSubmit: (values: CreateProjectFormValues) => void;
}

const useFields = ({ onSubmit }: UseFieldsProps) => {
  const theme = useTheme();
  const formik = useFormik({
    initialValues: initialValuesCreateProject,
    validationSchema: createProjectSchema,
    validateOnChange: true,
    enableReinitialize: true,
    onSubmit,
  });

  const { values, setFieldValue, setFieldTouched, errors, touched, handleChange, handleBlur, handleSubmit } = formik;

  const handleDropdownChange = (value: string[], name: string) => {
    if (name === CreateProjectFields.blockchain) setFieldValue(CreateProjectFields.network, [], true);
    setFieldValue(name, value, true);
  };

  const handleDropdownBlur = (name: string) => {
    setFieldTouched(name, true, true);
  };

  const checkError = (name: string) => !!errors[name as keyof typeof errors] && touched[name as keyof typeof touched];

  const getField = (field: NewEntryField) => {
    const fieldValue = values[field.name as keyof typeof values] || "";
    const isProjectNameTooLong = field.name === CreateProjectFields.name && fieldValue.length >= 32;

    const menuProps = {
      PaperProps: {
        style: {
          ...getMenuStyle(theme, checkError(field.name) as boolean),
        },
      },
    };

    const isDisabled =
      field.name === CreateProjectFields.network && values[CreateProjectFields.blockchain].length === 0;
    const startAdornment =
      field.name === CreateProjectFields.blockchain ? getBlockchainIcon(values[field.name][0]) : null;

    const fieldName = field.name as CreateProjectFields;

    return {
      [NewEntryFieldTypes.text]: (
        <StyledTextfield
          label={field.label}
          variant="outlined"
          name={fieldName}
          value={fieldValue}
          onChange={handleChange}
          onBlur={handleBlur}
          error={checkError(fieldName) as boolean}
          fieldLabel={field.fieldLabel}
          inputProps={{ maxLength: 32 }}
          isMaxReached={isProjectNameTooLong}
        />
      ),
      [NewEntryFieldTypes.select]: (
        <StyledSelect
          label={field.label}
          name={fieldName}
          fullWidth
          onBlur={() => handleDropdownBlur(fieldName)}
          value={values[fieldName] as any}
          renderValue={(value) => getFieldRenderValue(fieldName, value as string, values.blockchain[0])}
          fieldLabel={field.fieldLabel}
          onChange={(value) => handleDropdownChange(value, fieldName)}
          options={field.options || []}
          startAdornment={startAdornment}
          error={checkError(fieldName) as boolean}
          disabled={isDisabled}
          MenuProps={menuProps}
        />
      ),
    };
  };

  return { values, getField, isValid: formik.isValid, dirty: formik.dirty, handleSubmit, resetForm: formik.resetForm };
};

const StyledTextfield = styled(TextField)<{ isMaxReached: boolean }>(({ isMaxReached }) => ({
  "& fieldset": {
    borderRadius: "4px !important",
    borderWidth: "1px !important",
  },

  "& .MuiInputBase-root": {
    padding: "12.5px 16px",
  },

  "& .MuiFormLabel-root.Mui-error": {
    color: "#DC6675 !important",
  },

  ...(isMaxReached && {
    "& fieldset": {
      borderColor: "#DC6675 !important",
    },

    "& .Mui-focused fieldset": {
      borderColor: "#DC6675 !important",
    },
  }),
}));

const StyledSelect = styled(Dropdown)(({ theme }) => ({
  paddingLeft: "0",

  "& .MuiSelect-select": {
    backgroundColor: theme.palette.common.white,
    borderRadius: "4px !important",
    borderWidth: "1px !important",
  },

  "& .MuiInputBase-inputAdornedStart": {
    "& span": {
      paddingLeft: "14px",
    },
  },

  "& img": {
    position: "absolute",
    left: "7px",
  },
}));

const getMenuStyle = (theme: Theme, error?: boolean, width?: number) => ({
  width: width ? `${width}px` : "auto",
  background: theme.palette.common.white,
  borderRadius: theme.borderRadius.sm,
  boxShadow: "none",
  border: `2px solid ${error ? theme.palette.dropdown.border.error : theme.palette.dropdown.border.main}`,
  transform: "translateY(5px)",
});

export default useFields;
