export const ProjectsIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
    <path
      d="M2.25 8.75V4.75C2.25 3.645 3.145 2.75 4.25 2.75H6.201C6.808 2.75 7.381 3.025 7.761 3.498L8.364 4.25H13.75C14.855 4.25 15.75 5.145 15.75 6.25V9.094"
      stroke="#C53DD8"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.75 9.795V8.75C15.75 7.646 14.855 6.75 13.75 6.75H4.25C3.145 6.75 2.25 7.646 2.25 8.75V13.25C2.25 14.354 3.145 15.25 4.25 15.25H9.612"
      stroke="#C53DD8"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.1267 10.7673L17.0667 12.9373C17.3167 13.0283 17.3097 13.3853 17.0557 13.4663L14.3367 14.3363L13.4667 17.0553C13.3857 17.3093 13.0287 17.3163 12.9377 17.0663L10.7677 11.1263C10.6857 10.9033 10.9027 10.6863 11.1267 10.7673Z"
      stroke="#C53DD8"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
