import { Button } from "@maestro-org/ui-kit";
import { styled, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

import Divider from "../../../components/Divider/Divider";
import SettingsCard from "./SettingsCard";
import { MessageIcon } from "../../../components/Icons";

import { pages } from "../../../lib/routeUtils";

const AutoRenewal = () => {
  return (
    <SettingsCard>
      <Top>
        <Typography color="grey.A200" variant="h6">
          Disable auto-renewal
        </Typography>
        <Typography color="grey.500" variant="paragraphMedium">
          What will happen if you disable auto-renewal?
        </Typography>
      </Top>
      <Info>
        <InfoItem>
          <Typography variant="paragraphMedium" color="grey.A200">
            ✓
          </Typography>
          <Typography variant="paragraphMedium" color="grey.A200">
            Your subscription will no logner automatically renew and will expire on <Ref href="/">Apr 4, 2023</Ref>
          </Typography>
        </InfoItem>
        <InfoItem>
          <Typography variant="paragraphMedium" color="grey.A200">
            ✓
          </Typography>
          <Typography variant="paragraphMedium" color="grey.A200">
            If you want to continue using Maestro again, you’ll need to purchase a new subscription
          </Typography>
        </InfoItem>
      </Info>
      <Actions>
        <Link to={pages.subscriptionPlan()}>
          <CancelButton>
            <Typography color="textColor.dark">Cancel</Typography>
          </CancelButton>
        </Link>
        <Button>
          <Typography>Disable auto-renewal</Typography>
        </Button>
      </Actions>
      <Bottom>
        <Divider />
        <TipWrapper>
          <MessageIcon />
          <Typography color="grey.500" variant="article">
            If you run into any issue, we are here to help. Contact customer support{" "}
            <Ref underline href="mailto:info@gomaestro.org">
              here
            </Ref>
            .
          </Typography>
        </TipWrapper>
      </Bottom>
    </SettingsCard>
  );
};

const Top = styled("div")({
  display: "flex",
  flexDirection: "column",
  rowGap: "8px",
});

const Info = styled("div")({
  display: "flex",
  flexDirection: "column",
  rowGap: "16px",
  maxWidth: "650px",
});

const InfoItem = styled("div")({
  display: "flex",
  alignItems: "flex-start",
  columnGap: "8px",
});

const Actions = styled("div")({
  display: "flex",
  alignItems: "center",
  columnGap: "16px",
});

const CancelButton = styled(Button)(({ theme }) => ({
  background: theme.palette.grey["50"],

  "&:hover": {
    background: theme.palette.grey["50"],
  },
}));

const Bottom = styled("div")({
  display: "flex",
  flexDirection: "column",
  rowGap: "16px",
});

const TipWrapper = styled("div")({
  display: "flex",
  alignItems: "flex-start",
  columnGap: "16px",
  maxWidth: "300px",

  "& svg": {
    width: "44px",
  },
});

const Ref = styled("a")<{ underline?: boolean }>(({ theme, underline }) => ({
  textDecoration: underline ? "underline" : "none",
  color: theme.palette.primary.main,
}));

export default AutoRenewal;
