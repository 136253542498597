import { TextField } from "@maestro-org/ui-kit";
import { styled, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { getAllprojects } from "../../../redux/reducers/projectsReducer";

import Container from "../../../components/Container/Container";

import { Project as ProjectType } from "../../../types/project";
import { getBlockchainIcon, getNetworkLabel } from "../../../lib/createProject.utils";

const ProjectHeader = () => {
  const { id } = useParams();

  const allProjects = useSelector(getAllprojects);

  const [currentProject, setCurrentProject] = useState<ProjectType>({} as ProjectType);
  const [nameInputValue, setNameInputValue] = useState<string>("");
  const [editMode, setEditMode] = useState<boolean>(false);

  const toggleEditMode = () => {
    if (!editMode) {
      setNameInputValue(currentProject?.name || "");
    }
    setEditMode(!editMode);
  };

  const handleSubmitName = (event: React.KeyboardEvent<HTMLImageElement>) => {
    if (event.key === "Enter") {
      setCurrentProject({ ...currentProject, name: nameInputValue });
      toggleEditMode();
    }
  };

  const handleNameInputBlur = () => {
    setCurrentProject({ ...currentProject, name: nameInputValue });
    toggleEditMode();
  };

  const handleNameInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNameInputValue(event.target.value);
  };

  useEffect(() => {
    if (!id || !allProjects.length) return;
    const current = allProjects.find((item) => item.id === id);
    if (!current) return;
    setCurrentProject(current);
  }, [id, allProjects]);

  return (
    <Container>
      <Wrapper>
        <Top>
          <ProjectWrapper>
            <Row>
              {editMode ? (
                <InputWrapper>
                  <ProjectInput
                    inputRef={(input) => input && input.focus()}
                    variant="outlined"
                    value={nameInputValue}
                    onChange={handleNameInputChange}
                    onKeyDown={handleSubmitName}
                    onBlur={handleNameInputBlur}
                  />
                </InputWrapper>
              ) : (
                <ProjectName color={"grey.A200"}>{currentProject.name}</ProjectName>
              )}
            </Row>
            <Divider />
            <NetworkWrapper>
              {getBlockchainIcon(currentProject.chain)}
              <NetworkName color={"grey.A200"}>
                {getNetworkLabel(currentProject.chain, currentProject.network)}
              </NetworkName>
            </NetworkWrapper>
          </ProjectWrapper>
        </Top>
      </Wrapper>
    </Container>
  );
};

const Wrapper = styled("div")({
  display: "flex",
  flexDirection: "column",
  rowGap: "4px",
});

const Top = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
});

const ProjectWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  columnGap: "20px",

  [theme.breakpoints.down("md")]: {
    columnGap: "16px",
  },
}));

const Divider = styled("span")(({ theme }) => ({
  display: "flex",
  flexShrink: "0",
  width: "1px",
  height: "16px",
  backgroundColor: theme.palette.menu.border,
}));

const NetworkWrapper = styled("div")({
  display: "flex",
  gap: "6px",
  alignItems: "center",
});

const ProjectName = styled(Typography)({
  fontWeight: 600,
  fontSize: "24px",
  lineHeight: "28px",
});

const NetworkName = styled(Typography)({
  fontSize: "14px",
  fontWeight: 400,
  lineHeight: "20px",
});

const InputWrapper = styled("div")({
  "& > div": {
    rowGap: 0,
  },
});

const ProjectInput = styled(TextField)({
  "& .MuiInputBase-root": {
    padding: "10px 10px",
  },
});

const Row = styled("div")({
  display: "flex",
  alignItems: "center",
  columnGap: "10px",
});

export default ProjectHeader;
