import { FC } from "react";
import { styled } from "@mui/material";
import TeamCardUserItem from "./TeamCardUserItem";
import { UserRoles } from "../../../../types/roles";
import { TeamProps } from "../../../../redux/actions/teamsActions";
import { useSelector } from "react-redux";
import { getUser } from "../../../../redux/reducers/usersReducer";

interface Props {
  teamData?: TeamProps;
}

const TeamCardTable: FC<Props> = ({ teamData }) => {
  const user = useSelector(getUser);

  const findUserByEmail = (teamData?: TeamProps) => {
    const findUser = teamData?.users.find((u) => u.email === user.email);
    return findUser ? [UserRoles.OWNER, UserRoles.ADMIN].includes(findUser.role_name as UserRoles) : false;
  };

  return (
    <Wrapper>
      <Thead>
        <EmailTd>User</EmailTd>
        <RoleTd>Role</RoleTd>
        {(user.team_role_name === UserRoles.OWNER ||
          user.team_role_name === UserRoles.ADMIN ||
          findUserByEmail(teamData)) && (
          <>
            <MfaTd>MFA</MfaTd>
            <ManageTd>Manage</ManageTd>
          </>
        )}
      </Thead>
      <TBody>
        {teamData &&
          teamData.users &&
          teamData.users.map((elem, index) => <TeamCardUserItem key={index} elem={elem} />)}
      </TBody>
    </Wrapper>
  );
};

const TBody = styled("div")({
  display: "flex",
  flexDirection: "column",
  gap: "16px",
  marginTop: "24px",
});

const MfaTd = styled("div")({
  width: "14%",
});

const ManageTd = styled("div")({
  textAlign: "right",
  width: "9%",
});

const RoleTd = styled("div")({
  width: "27%",
});

const EmailTd = styled("div")({
  display: "flex",
  alignItems: "center",
  width: "50%",
});

const Thead = styled("div")({
  display: "flex",
  color: "#0F0311",

  fontSize: "14px",
  fontWeight: "400",
  lineHeight: "20px",
});

const Wrapper = styled("div")({
  display: "flex",
  flexDirection: "column",

  maxHeight: "300px",
  overflowY: "auto",
});

export default TeamCardTable;
