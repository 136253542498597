export const InputWarningIcon = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_2726_1570)">
      <path
        d="M0.932266 14.2132L7.61402 1.58171C7.74748 1.32945 7.9472 1.11836 8.19167 0.971127C8.43614 0.823894 8.71613 0.746094 9.00152 0.746094C9.2869 0.746094 9.56689 0.823894 9.81136 0.971127C10.0558 1.11836 10.2555 1.32945 10.389 1.58171L17.0708 14.2132C17.1962 14.4516 17.2577 14.7184 17.2494 14.9876C17.241 15.2568 17.1631 15.5193 17.0231 15.7494C16.8831 15.9796 16.686 16.1695 16.4508 16.3008C16.2156 16.4321 15.9504 16.5002 15.681 16.4985H2.31902C2.04992 16.4996 1.7851 16.4312 1.55032 16.2997C1.31553 16.1682 1.11877 15.9782 0.979165 15.7481C0.839564 15.5181 0.761876 15.2558 0.753657 14.9868C0.745439 14.7179 0.80697 14.4514 0.932266 14.2132V14.2132Z"
        stroke="#DC6675"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path d="M9 6V10.5" stroke="#DC6675" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="square" />
      <path
        d="M9 14.25C9.62132 14.25 10.125 13.7463 10.125 13.125C10.125 12.5037 9.62132 12 9 12C8.37868 12 7.875 12.5037 7.875 13.125C7.875 13.7463 8.37868 14.25 9 14.25Z"
        fill="#DC6675"
      />
    </g>
    <defs>
      <clipPath id="clip0_2726_1570">
        <rect width="18" height="18" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
