import { styled, Typography } from "@mui/material";
import { ReactElement } from "react";

import IconButton from "../../../components/Button/IconButton";
import { Chip } from "../../../components/Chip/Chip";
import { ArrowLeft, ArrowRight } from "../../../components/Icons";

interface Props {
  date: string;
  label: string;
  next: () => void;
  prev: () => void;
  chart: ReactElement;
}

const ChartHeader = ({ date, label, next, prev, chart }: Props) => (
  <>
    <DateWrapper>
      <Typography color="textColor.dark" variant="chartTitle">
        <b>{date}</b>
      </Typography>
      {/* <DaySwitch>
        <CustomIconButton noFill onClick={prev}>
          <ArrowLeft />
        </CustomIconButton>
        <CustomChip
          label={
            <Typography variant="dateSwitch" color="textColor.dark">
              {label}
            </Typography>
          }
        />
        <CustomIconButton noFill onClick={next}>
          <ArrowRight />
        </CustomIconButton>
      </DaySwitch> */}
    </DateWrapper>
    <ChartWrapper>
      {chart}
      <Fade />
    </ChartWrapper>
  </>
);

const ChartWrapper = styled("div")(({ theme }) => ({
  width: "100%",
  order: 3,
  overflow: "auto hidden",
  paddingBottom: "24px",
  position: "relative",

  "&::-webkit-scrollbar ": {
    height: "6px",
  },
  "&::-webkit-scrollbar-track ": {
    background: theme.palette.grey[50],
    borderRadius: "4px",
  },
  "&::-webkit-scrollbar-thumb ": {
    background: theme.palette.grey.A200,
    borderRadius: "13px",
  },
}));

const Fade = styled("div")(({ theme }) => ({
  position: "absolute",
  background: "linear-gradient(270deg, #FFFFFF 15.15%, rgba(245, 245, 245, 0) 77.52%)",
  right: 0,
  top: 0,
  height: "100%",
  width: "26px",

  [theme.breakpoints.up("md")]: {
    display: "none",
  },
}));

const CustomChip = styled(Chip)(({ theme }) => ({
  background: theme.palette.totalRequestVolume.dateSwitcher.background,
  padding: "6px 20px",
  borderRadius: theme.borderRadius.sm,
}));

const CustomIconButton = styled(IconButton)(({ theme }) => ({
  width: "32px",
  height: "32px",
  borderRadius: theme.borderRadius.sm,
  background: theme.palette.totalRequestVolume.dateSwitcher.background,
}));

const DateWrapper = styled("div")({
  display: "flex",
  alignItems: "center",
  columnGap: "24px",
  order: 1,

  "& > span > b": {
    marginRight: "10px",
  },
});

const DaySwitch = styled("div")({
  display: "flex",
  alignItems: "center",
  columnGap: "4px",
});

export default ChartHeader;
