import React from "react";
import { Link } from "react-router-dom";
import { styled, Typography } from "@mui/material";
import { Button } from "@maestro-org/ui-kit";
import { useSelector, useDispatch } from "react-redux";

import { updatePopup } from "../../redux/actions/popupsActions";
import { getPrefilled, getResetApiKeyDialog } from "../../redux/reducers/popupsReducer";

import Dialog from "./Dialog";

import { Popups } from "../../types/popups";
import { pages } from "../../lib/routeUtils";
import { rotateApiKeyServer } from "../../redux/actions/projectsActions";

const ResetKeyDialog = () => {
  const isOpen = useSelector(getResetApiKeyDialog);
  const { projectName, projectId } = useSelector(getPrefilled) || {};
  const dispatch = useDispatch();

  const handleClose = () => dispatch(updatePopup({ popup: Popups.resetKey, status: false }));

  const handleResetClick = () => {
    handleClose();
    dispatch(rotateApiKeyServer({ id: projectId }));
  };

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <Title color="grey.A200" variant="h5">
        Are you sure you want to reset you API key for project {projectName}?
      </Title>
      <Typography color="grey.A200" variant="paragraphMedium">
        Reset your key if it is lost or has been compromised. Beware that the old key will become invalid once you
        generate a new key.{" "}
        <Ref onClick={handleClose} to={pages.main()}>
          Learn more
        </Ref>
      </Typography>
      <Actions>
        <CancelButton onClick={handleClose}>
          <Typography color="grey.A200" variant="paragraphSmall">
            Cancel
          </Typography>
        </CancelButton>
        <Button onClick={handleResetClick}>
          <Typography variant="paragraphSmall">Reset</Typography>
        </Button>
      </Actions>
    </Dialog>
  );
};

const Title = styled(Typography)({
  maxWidth: "404px",
});

const Actions = styled("div")({
  display: "flex",
  alignItems: "center",
  columnGap: "12px",
});

const CancelButton = styled(Button)(({ theme }) => ({
  background: theme.palette.grey["50"],

  "&:hover": {
    background: theme.palette.grey["50"],
  },
}));

const Ref = styled(Link)(({ theme }) => ({
  color: theme.palette.primary.main,
  cursor: "pointer",
}));

export default ResetKeyDialog;
