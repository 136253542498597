export interface CreateTransactionFormValues {
  project: string[];
  url: string;
  webhookName: string;
}

export enum CreateTransactionFields {
  project = "project",
  url = "url",
  webhookName = "webhookName",
}
