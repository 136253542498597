import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getHydrated } from "../redux/reducers/appReducer";
import { getIsAuthorized } from "../redux/reducers/authReducer";

const withPrivateRoute = (Component: any) => {
  const Auth = (props: any) => {
    const navigate = useNavigate();

    const isHydrated = useSelector(getHydrated);
    const isAuthorized = useSelector(getIsAuthorized);

    useEffect(() => {
      if (isHydrated && !isAuthorized) {
        navigate("/login");
      }
    }, [isAuthorized, isHydrated]);

    return isHydrated && isAuthorized ? <Component {...props} /> : null;
  };

  return Auth;
};

export default withPrivateRoute;
