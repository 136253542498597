import { styled, MenuItem as MuiMenuItem, Typography } from "@mui/material";
import { ReactNode } from "react";
import { Link } from "react-router-dom";

import { SettingsPages as SettingsPagesType } from "../../../types/settings";

import Logo, { LogoStyles } from "../../../components/Logo/Logo";

import { pages } from "../../../lib/routeUtils";
import { DashboardIcon } from "../../../components/Icons/DashboardIcon";

interface Page {
  value: SettingsPagesType;
  title?: string;
  href: string;
}

interface SidebarItem {
  title: string;
  icon: ReactNode;
  pages: Page[] | Page;
}

interface Props {
  items: SidebarItem[];
  active: SettingsPagesType;
}

const SettingsSidebar = ({ items, active }: Props) => {
  const activeCategory = items.find((item) =>
    !Array.isArray(item.pages) ? item.pages.value === active : item.pages.find((page) => page.value === active),
  );

  return (
    <Wrapper>
      <LogoWrapper>
        <Logo style={LogoStyles.Dark} />
      </LogoWrapper>

      <Link to={pages.main()}>
        <BackToDashboardWrapper>
          <DashboardIcon />

          <Typography color="grey.A200" variant="paragraphMedium">
            Dashboard
          </Typography>
        </BackToDashboardWrapper>
      </Link>
      <SettingsPages>
        <SettingsLabelWrapper>
          <SettingsText color="grey.400" variant="paragraphMedium">
            SETTINGS
          </SettingsText>
        </SettingsLabelWrapper>
        {items.map((item) => {
          const isActive = item.title === activeCategory?.title;
          let activeIndex = 0;
          if (Array.isArray(activeCategory?.pages)) {
            activeIndex = activeCategory?.pages.findIndex((elem) => elem.value === active) || 0;
          }
          return (
            <StyledLink key={item.title} to={Array.isArray(item.pages) ? item.pages[0].href : item.pages.href}>
              <SettingsCategory active={isActive}>
                <>
                  <CategoryNameWrapper active={isActive}>
                    <IconWrapper>{item.icon}</IconWrapper>
                    <Typography
                      color={!Array.isArray(item.pages) && item.pages.value === active ? "primary.main" : "grey.A200"}
                      variant="paragraphMedium"
                    >
                      {item.title}
                    </Typography>
                  </CategoryNameWrapper>
                  {Array.isArray(item.pages) &&
                    isActive &&
                    item.pages.map((page, index) => (
                      <StyledLink key={item.title} to={page.href}>
                        <SettingsPageItem key={page.title} active={isActive && activeIndex >= index}>
                          <Typography
                            color={page.value === active ? "primary.main" : "grey.400"}
                            variant="paragraphMedium"
                          >
                            {page.title}
                          </Typography>
                        </SettingsPageItem>
                      </StyledLink>
                    ))}
                </>
              </SettingsCategory>
            </StyledLink>
          );
        })}
      </SettingsPages>
      {/*<Line />*/}
    </Wrapper>
  );
};

const IconWrapper = styled("div")({
  height: "20px",
});

const CategoryNameWrapper = styled("div")<{ active: boolean }>(({ theme, active }) => ({
  display: "flex",
  gap: "8px",
  alignItems: "center",
  position: "relative",
  width: "100%",

  padding: "10px 0",

  "& svg path": {
    stroke: active ? theme.palette.primary.main : "#212121",
  },

  "&:after": {
    transition: "0.3s",
    content: '""',
    position: "absolute",
    right: 0,
    top: 0,
    bottom: 0,
    background: theme.palette.primary.main,
    width: "3px",
    opacity: active ? 1 : 0,
  },
}));

const SettingsCategory = styled("div")<{ active: boolean }>(({ theme, active }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  position: "relative",

  background: active ? "#FAF9FB" : "transparent",

  "& svg path": {
    stroke: active ? theme.palette.primary.main : "#212121",
  },

  padding: "0 0 0 64px",

  [theme.breakpoints.down("lg")]: {
    padding: "0 0 0 34px",
  },
}));

const SettingsText = styled(Typography)({
  fontSize: "12px",
  fontWeight: "400",
  lineHeight: "16px",
  letterSpacing: "0.09m",
});

const Wrapper = styled("div")(({ theme }) => ({
  position: "relative",
  display: "flex",
  flexDirection: "column",
  padding: "20px 0 0 0",
  minWidth: "322px",
  background: "white",
  boxShadow: "3px 4px 16px -2px rgba(0, 0, 0, 0.16)",
  flexShrink: 0,

  [theme.breakpoints.down("lg")]: {
    minWidth: "250px",
    padding: "20px 0 0 0",
  },

  [theme.breakpoints.down(1101)]: {
    display: "none",
  },
}));

const LogoWrapper = styled("div")(({ theme }) => ({
  padding: "0 0 0 64px",

  [theme.breakpoints.down("lg")]: {
    padding: "0 0 0 34px",
  },
}));

const BackToDashboardWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  columnGap: "8px",
  margin: "63px 0 64px",
  cursor: "pointer",

  padding: "0 0 0 64px",

  [theme.breakpoints.down("lg")]: {
    padding: "0 0 0 34px",
  },
}));

const SettingsLabelWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  columnGap: "12px",
  paddingBottom: "10px",

  padding: "0 0 0 64px",

  [theme.breakpoints.down("lg")]: {
    padding: "0 0 0 34px",
  },
}));

const SettingsPages = styled("div")({
  display: "flex",
  flexDirection: "column",
});

const StyledLink = styled(Link)({
  textDecoration: "none",
  width: "100%",
});

const SettingsPageItem = styled(MuiMenuItem)<{ active: boolean }>(({ theme, active }) => ({
  padding: "10px 0 10px 40px",
  width: "100%",
  borderRadius: theme.borderRadius.xs,
  position: "relative",

  // "& svg path": {
  //   stroke: active ? theme.palette.primary.main : "#212121",
  // },

  "&:after": {
    transition: "0.3s",
    content: '""',
    position: "absolute",
    right: 0,
    top: "0",
    bottom: 0,
    background: theme.palette.primary.main,
    width: "3px",
    opacity: active ? 1 : 0,
  },
}));

const StyledLineWrapper = styled("div")(({ theme }) => ({
  pointerEvents: "none",
  position: "absolute",
  height: "100%",
  overflow: "hidden",
  top: "0px",
  right: "0px",
  width: "100%",
}));

const StyledLine = styled("svg")(({ theme }) => ({
  position: "absolute",
  zIndex: "200",
  left: "0px",
  top: "-150px",
  marginRight: "calc(-1 * (100vw - 100%))",

  [theme.breakpoints.down("lg")]: {
    left: "-50px",
  },
}));

export default SettingsSidebar;
