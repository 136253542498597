export const DashboardIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_4661_2662)">
      <path
        d="M0.832031 9.16406L9.9987 1.66406L19.1654 9.16406"
        stroke="#212121"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path d="M8.33203 19.1641V14.1641H11.6654V19.1641" stroke="#212121" strokeWidth="1.5" strokeMiterlimit="10" />
      <path
        d="M3.33203 10.832V17.4987C3.33203 18.4195 4.07786 19.1654 4.9987 19.1654H14.9987C15.9195 19.1654 16.6654 18.4195 16.6654 17.4987V10.832"
        stroke="#212121"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
    </g>
    <defs>
      <clipPath id="clip0_4661_2662">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
