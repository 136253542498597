import { addDays, differenceInDays, eachDayOfInterval, format, startOfDay } from "date-fns";

export const getEmptyCreditUsageData = (startDate?: Date): { date: string; value: number }[] => {
  if (!startDate) return [];

  const tomorrow = startOfDay(addDays(new Date(), 1));
  const dateArray: { date: string; value: number }[] = [];

  const days = eachDayOfInterval({ start: startDate, end: tomorrow });

  days.forEach((day) => {
    dateArray.push({ date: format(day, "MM/dd/yyyy h:mm:ss a"), value: 0 });
  });

  return dateArray;
};

export const getEmptyCreditLeftDays = (nextBillingDate?: Date): { date: string; value: number }[] => {
  if (!nextBillingDate) return [];

  const tomorrow = startOfDay(addDays(new Date(), 2));

  const dateArray: { date: string; value: number; emptyValue?: boolean }[] = [];

  if (nextBillingDate < tomorrow) return [];

  const days = eachDayOfInterval({ start: tomorrow, end: nextBillingDate });

  days.forEach((day) => {
    dateArray.push({ date: format(day, "MM/dd/yyyy h:mm:ss a"), value: 0, emptyValue: true });
  });

  return dateArray;
};

export const getDaysUntilNextBilling = (nextBillingDate?: Date): number => {
  if (!nextBillingDate) return 1;

  const today = startOfDay(new Date());

  const daysUntilNextMonth = differenceInDays(nextBillingDate, today);

  return daysUntilNextMonth;
};

export const getDaysBetweenBilling = (lastBillingDate?: Date, nextBillingDate?: Date): number => {
  if (!lastBillingDate || !nextBillingDate) return 1;
  const daysBetweenLastBillingAndNextMonth = differenceInDays(nextBillingDate, lastBillingDate) + 1;

  return daysBetweenLastBillingAndNextMonth;
};
