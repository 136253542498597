import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { CircularProgress, styled, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { initialValuesResetPassword, resetPasswordFields } from "../../forms/reset-password/form";
import { ResetPasswordFormValues } from "../../forms/reset-password/types";
import { resetPasswordSchema } from "../../forms/reset-password/validation";

import { updateIsLoadingAuth } from "../../redux/actions/authActions";
import { getAuthLoading } from "../../redux/reducers/authReducer";

import { auth } from "../../services/auth0.service";

import AuthLayout from "../../layouts/AuthLayout";

import { Button } from "@maestro-org/ui-kit";
import TextField from "./components/TextField";
import { Auth0Error } from "auth0-js";
import withAuthRedirect from "../../hooks/withAuthRedirect";

const ResetPasswordPage = () => {
  const isLoading = useSelector(getAuthLoading);
  const [isLinkSent, setIsLinkSet] = useState(false);
  const dispatch = useDispatch();

  const onSubmit = (values: ResetPasswordFormValues) => {
    // dispatch(resetPasswordServer(values));
    auth.changePassword(
      {
        connection: `${process.env.REACT_APP_AUTH0_REALM}`,
        email: values.email,
      },
      function (error: Auth0Error | null, result: any | null) {
        if (error) {
          return;
        }
        // toast.success("Password reset email sent!");
        setIsLinkSet(true);
        // navigate("/login");
      },
    );
  };

  const formik = useFormik({
    initialValues: initialValuesResetPassword,
    validationSchema: resetPasswordSchema,
    validateOnChange: true,
    enableReinitialize: true,
    onSubmit,
  });

  useEffect(() => {
    dispatch(updateIsLoadingAuth({ status: false }));
  }, []);

  return (
    <AuthLayout withoutLeftBlock={true} title="The Developer Platform for UTxO Blockchains">
      {isLinkSent ? (
        <Wrapper>
          <StyledTitle color="textColor.dark" variant="h2">
            Password sent!
          </StyledTitle>
          <Typography color="textColor.dark" variant="paragraphMedium">
            A password reset link has been sent to your email address.
          </Typography>
        </Wrapper>
      ) : (
        <StyledForm onSubmit={formik.handleSubmit}>
          <Wrapper>
            <Top>
              <StyledTitle color="textColor.dark" variant="h2">
                Reset password
              </StyledTitle>
              <Typography color="textColor.dark" variant="paragraphMedium">
                Haven&apos;t signed up? <Ref to="/signup">Sign Up </Ref>
              </Typography>
            </Top>
            <FieldsWrapper>
              {resetPasswordFields.map((field) => (
                <TextField key={field.name} field={field} {...formik} />
              ))}
            </FieldsWrapper>
            <Actions>
              <BackRef to="/login">
                <Typography color="textColor.dark">Back</Typography>
              </BackRef>
              <Button type="submit" onMouseDown={(e) => e.preventDefault()} disabled={isLoading}>
                {isLoading ? <CircularProgress /> : <Typography>Submit</Typography>}
              </Button>
            </Actions>
          </Wrapper>
        </StyledForm>
      )}
    </AuthLayout>
  );
};

const StyledTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 300,
  fontSize: "40px",
  lineHeight: "56px",
}));

const StyledForm = styled("form")({});

const Wrapper = styled("div")({
  display: "flex",
  flexDirection: "column",
  rowGap: "40px",
});

const Top = styled("div")({
  display: "flex",
  flexDirection: "column",
  rowGap: "8px",
});

const Ref = styled(Link)<any>(({ theme }) => ({
  color: theme.palette.contrastText.main,
  cursor: "pointer",
  textDecoration: "underline",
}));

const BackRef = styled(Link)({
  cursor: "pointer",
  textDecoration: "none",
});

const FieldsWrapper = styled("div")({
  display: "flex",
  flexDirection: "column",
  rowGap: "16px",
});

const Actions = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});

export default withAuthRedirect(ResetPasswordPage);
