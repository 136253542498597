export interface AccountDetailsFormValues {
  firstName: string;
  lastName: string;
  email: string;
  organization: string;
}

export enum AccountDetailsFields {
  firstName = "firstName",
  lastName = "lastName",
  email = "email",
  organization = "organization",
}
