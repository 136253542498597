import { Button, Loader } from "@maestro-org/ui-kit";
import { StripeAddressElementChangeEvent } from "@stripe/stripe-js";
import { styled, Typography } from "@mui/material";
import { AddressElement } from "@stripe/react-stripe-js";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import { IBillingInfo } from "../../../types/auth";

import SettingsCard from "./SettingsCard";
import Divider from "../../../components/Divider/Divider";

import { pages } from "../../../lib/routeUtils";
import { changeBillingDataUpdated, updateBillingServer } from "../../../redux/actions/userActions";
import { getActiveMethodId } from "../../../redux/reducers/stripeReducer";
import { getIsBillingLoading, getIsBillingUpdated } from "../../../redux/reducers/usersReducer";
import { StripeCustomer } from "../../../types/stripeCustomer";

interface Props {
  isLoading: boolean;
  data?: StripeCustomer;
}

const EditBillingInfoCard = ({ isLoading, data }: Props) => {
  const [billingInfo, setBillingInfo] = useState<StripeAddressElementChangeEvent>();

  const isUpdating = useSelector(getIsBillingLoading);
  const isUpdated = useSelector(getIsBillingUpdated);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleChangeBilling = (event: StripeAddressElementChangeEvent) => {
    setBillingInfo(event);
  };

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const values = billingInfo?.value;
    if (!values) return;

    dispatch(
      updateBillingServer({
        address: values.address,
        email: data?.email,
        name: values.name,
      }),
    );
  };

  useEffect(() => {
    if (!isUpdated) return;
    navigate(pages.subscriptionPlan());
    dispatch(changeBillingDataUpdated({ status: false }));
  }, [isUpdated]);

  const splittedName = data?.name.split(" ");

  const firstName = splittedName && splittedName?.length > 0 ? splittedName[0] : "";
  const lastName = splittedName && splittedName?.length > 1 ? splittedName[1] : "";

  return (
    <form id="payment-details" onSubmit={onSubmit}>
      <SettingsCard>
        <Typography color="grey.A200" variant="h6">
          Update billing information
        </Typography>
        {isLoading && <p>loading</p>}
        {data && !isLoading && (
          <AddressElement
            onChange={handleChangeBilling}
            options={{
              mode: "billing",
              defaultValues: {
                firstName,
                lastName,
                address: {
                  line1: data.address.line1,
                  line2: data.address.line2,
                  city: data.address.city,
                  state: data.address.state,
                  postal_code: data.address.postal_code,
                  country: data.address.country,
                },
              },
              display: {
                name: "split",
              },
            }}
          />
        )}
        <Actions>
          <Link to={pages.subscriptionPlan()}>
            <CancelButton onMouseDown={(event) => event.stopPropagation()}>Cancel</CancelButton>
          </Link>
          <SubmitButton type="submit" onMouseDown={(event) => event.stopPropagation()}>
            {isUpdating ? <StyledLoader size={20} /> : "Save"}
          </SubmitButton>
        </Actions>
        <BottomWrapper>
          <Divider />
          <Typography color="grey.400" variant="article">
            By updating your payment details, you agree to turn on automatic renewal for your current subscription. See
            offer details and cancellation terms{" "}
            <Ref href="https://storage.googleapis.com/ispo-marketplace/legal/Privacy_Policy.pdf" target="_blank">
              here
            </Ref>
            .
          </Typography>
        </BottomWrapper>
      </SettingsCard>
    </form>
  );
};

const Actions = styled("div")({
  display: "flex",
  columnGap: "16px",
  alignItems: "center",
});

const CancelButton = styled(Button)(({ theme }) => ({
  borderRadius: theme.borderRadius.sm,
  background: theme.palette.grey[50],
  color: theme.palette.grey["A200"],

  "&:hover": {
    background: theme.palette.grey[50],
    color: theme.palette.grey["A200"],
  },
}));

const SubmitButton = styled(Button)(({ theme }) => ({
  borderRadius: theme.borderRadius.sm,
}));

const BottomWrapper = styled("div")({
  display: "flex",
  flexDirection: "column",
  rowGap: "16px",
});

const Ref = styled("a")(({ theme }) => ({
  color: theme.palette.primary.main,
  textDecoration: "underline",
}));

const StyledLoader = styled(Loader)(({ theme }) => ({
  color: theme.palette.common.white,
}));

export default EditBillingInfoCard;
