import React from "react";
import { styled } from "@mui/material";

import { SettingsPages } from "../../types/settings";

import SettingsLayout from "../../layouts/SettingsLayout";

import AutoRenewal from "./components/AutoRenewal";

import withPrivateRoute from "../../hooks/withPrivateRoute";

const AutoRenewalPage = () => {
  return (
    <SettingsLayout
      path={[
        { title: "Settings", href: "/" },
        { title: "Subscription", href: "/" },
        { title: "Overview", href: "/" },
        { title: "Auto-renewal", href: "/" },
      ]}
      active={SettingsPages.overview}
    >
      <Wrapper>
        <AutoRenewal />
      </Wrapper>
    </SettingsLayout>
  );
};

const Wrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  rowGap: "56px",

  "@media (max-height: 1000px)": {
    rowGap: "39px",
  },

  [theme.breakpoints.down("xl")]: {
    rowGap: "39px",
  },
}));

export default withPrivateRoute(AutoRenewalPage);
