import { getHours, getTime } from "date-fns";
import React from "react";
import { Line, ResponsiveContainer, LineChart as LineChartFromLib, Tooltip, XAxis, YAxis } from "recharts";
import { getTheme } from "../../lib/theme/theme";
import CustomTooltip from "./CustomTooltip";

interface Props {
  data: {
    date: string;
    value: number;
  }[];
  units?: string;
  measure?: string;
  height?: number;
  strokeDasharray?: string;
}

const LineChart = ({ data, units, height = 45, strokeDasharray, measure }: Props) => {
  const theme = getTheme("light");

  // const sortArray = data.sort((a, b) => {
  //   return getTime(a.date) - getTime(b.date);
  // });

  return (
    <ResponsiveContainer width="100%" height={height}>
      <LineChartFromLib data={data}>
        <Line
          type="linear"
          dataKey="value"
          stroke={theme.palette.primary.main}
          strokeWidth="2px"
          dot={false}
          activeDot={<ActiveDot />}
          strokeDasharray={strokeDasharray}
        />
        <XAxis dataKey="date" domain={["auto", "auto"]} axisLine={false} tickLine={false} hide />
        <YAxis dataKey="value" axisLine={false} tick={false} tickLine={false} hide />
        <Tooltip
          content={<CustomTooltip units={units} measure={measure} />}
          cursor={false}
          wrapperStyle={{ border: "none", outline: "none", top: "-90px" }}
        />
      </LineChartFromLib>
    </ResponsiveContainer>
  );
};

const ActiveDot = ({ payload, cx, cy }: any) => {
  const theme = getTheme("light");

  if (payload.emptyValue) return null;

  return (
    <circle
      cx={cx}
      cy={cy}
      stroke={theme.palette.primary.main}
      strokeWidth={2}
      r={3}
      fill={theme.palette.grey[50]}
      className="recharts-dot"
    ></circle>
  );
};

export default LineChart;
