export const BackIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15.5 8.5H0.5" stroke="#0F0311" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M5.5 3.5L0.5 8.5L5.5 13.5"
      stroke="#0F0311"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
