export const GithubIcon = () => (
  <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_5152_176)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.5004 0.417969C8.12639 0.4192 5.83025 1.26002 4.02253 2.79007C2.21482 4.32013 1.0134 6.43964 0.633096 8.76963C0.252797 11.0996 0.718415 13.4881 1.9467 15.5081C3.17499 17.5281 5.08584 19.0478 7.33758 19.7954C7.83435 19.8876 8.02147 19.5798 8.02147 19.318C8.02147 19.0562 8.01153 18.2971 8.00822 17.4673C5.22631 18.0683 4.63846 16.2934 4.63846 16.2934C4.18475 15.1408 3.52901 14.8379 3.52901 14.8379C2.62157 14.2221 3.5969 14.2336 3.5969 14.2336C4.60204 14.3044 5.13027 15.2594 5.13027 15.2594C6.02115 16.7791 7.47005 16.3395 8.03968 16.0826C8.1291 15.4389 8.38908 15.0009 8.67556 14.7523C6.45333 14.502 4.11851 13.6491 4.11851 9.83915C4.10473 8.85106 4.47349 7.89551 5.14848 7.1702C5.04582 6.91994 4.70304 5.909 5.24618 4.53583C5.24618 4.53583 6.08573 4.2691 7.99664 5.555C9.6357 5.10932 11.365 5.10932 13.0041 5.555C14.9133 4.2691 15.7512 4.53583 15.7512 4.53583C16.296 5.9057 15.9533 6.91665 15.8506 7.1702C16.5277 7.89563 16.8972 8.85284 16.8822 9.84245C16.8822 13.6606 14.5424 14.502 12.3169 14.7473C12.6746 15.0569 12.9942 15.6611 12.9942 16.5897C12.9942 17.9201 12.9826 18.9903 12.9826 19.318C12.9826 19.583 13.163 19.8926 13.6698 19.7954C15.9218 19.0477 17.8328 17.5277 19.0611 15.5074C20.2893 13.4871 20.7547 11.0982 20.374 8.76801C19.9932 6.43781 18.7912 4.31826 16.9829 2.78847C15.1746 1.25868 12.8779 0.41842 10.5037 0.417969H10.5004Z"
      />
      <path d="M4.28632 14.6925C4.26479 14.7419 4.18531 14.7568 4.12073 14.7222C4.05615 14.6876 4.00813 14.6234 4.03131 14.5724C4.0545 14.5213 4.13232 14.5081 4.1969 14.5427C4.26148 14.5773 4.31116 14.6431 4.28632 14.6925Z" />
      <path d="M4.69334 15.1433C4.65905 15.1604 4.61982 15.1652 4.58236 15.1568C4.5449 15.1484 4.51155 15.1273 4.48801 15.0972C4.42343 15.028 4.41018 14.9325 4.45985 14.8897C4.50953 14.8469 4.59896 14.8667 4.66354 14.9358C4.72812 15.005 4.74302 15.1005 4.69334 15.1433Z" />
      <path d="M5.08673 15.7138C5.02546 15.7566 4.92113 15.7138 4.86318 15.6282C4.84715 15.6129 4.83441 15.5944 4.8257 15.5741C4.81699 15.5537 4.8125 15.5318 4.8125 15.5097C4.8125 15.4875 4.81699 15.4656 4.8257 15.4453C4.83441 15.4249 4.84715 15.4065 4.86318 15.3911C4.92444 15.35 5.02877 15.3911 5.08673 15.4751C5.14468 15.5591 5.14634 15.671 5.08673 15.7138Z" />
      <path d="M5.62168 16.2683C5.56704 16.3293 5.45609 16.3128 5.36502 16.2305C5.27394 16.1482 5.25241 16.0362 5.30706 15.9769C5.3617 15.9176 5.47265 15.9341 5.56703 16.0148C5.66142 16.0955 5.67964 16.2091 5.62168 16.2683Z" />
      <path d="M6.37246 16.5893C6.34762 16.6667 6.23502 16.7013 6.12242 16.6684C6.00982 16.6354 5.9353 16.5432 5.95683 16.4642C5.97836 16.3852 6.09262 16.349 6.20687 16.3852C6.32113 16.4214 6.39399 16.5087 6.37246 16.5893Z" />
      <path d="M7.19059 16.6449C7.19059 16.7256 7.09787 16.7948 6.97864 16.7964C6.85942 16.798 6.76172 16.7322 6.76172 16.6515C6.76172 16.5708 6.85444 16.5017 6.97367 16.5C7.09289 16.4984 7.19059 16.5626 7.19059 16.6449Z" />
      <path d="M7.95068 16.5199C7.96559 16.6006 7.88279 16.6846 7.76357 16.7043C7.64434 16.7241 7.54002 16.6763 7.52512 16.5973C7.51021 16.5183 7.59632 16.4327 7.71223 16.4112C7.82815 16.3898 7.93578 16.4392 7.95068 16.5199Z" />
    </g>
    <defs>
      <clipPath id="clip0_5152_176">
        <rect width="20" height="20" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </svg>
);
