import { INewInvoiceHistoryItem } from "../../types/invoiceHistory";
import { CreditsUsageItem, SubscriptionService, UserMetrics, UserSubscriptions } from "../../types/user";
import {
  CHANGE_IS_BILLING_UPDATED,
  CREATE_USER_SERVER,
  UPDATE_BILLING_LOADING,
  UPDATE_INVOICES,
  UPDATE_INVOICES_LOADING,
  UPDATE_IS_USER_DELETING,
  UPDATE_METRICS_OVERVIEW,
  UPDATE_METRICS_OVERVIEW_LOADING,
  UPDATE_SUBSCRIPTION_SERVICES,
  UPDATE_SUBSCRIPTION_SERVICES_LOADING,
  UPDATE_USER,
  UPDATE_USER_CREDIT_USAGE,
  UPDATE_USER_CREDIT_USAGE_LOADING,
  UPDATE_USER_LOADING,
  UPDATE_USER_SUBSCRIPTIONS,
  UPDATE_USER_SUBSCRIPTIONS_LOADING,
  UPDATE_TEAM_OWNER,
  UPDATE_TEAM_OWNER_LOADING,
  SET_USER_ORGANIZATION,
  UPDATE_ORGANIZATION,
  SET_IS_USER_ORGANIZATION_UPDATING,
  CLEAR_ORGANIZATION_DATA,
} from "../actions/userActions";

import { REHYDRATE } from "../actions/appActions";

import { StoreType } from "../types/store.types";

export interface IUser {
  email: string;
  auth0_user_id: string;
  subscription_id: string;
  available_credits?: string;
  credits_used?: string;
  customer_id: string;
  default_payment_method_id: string;
  last_billing_date: number;
  next_billing_date: number;
  tier_id: string;
  package_id: string;
  team_id: string;
  team_name: string;
  team_role_name: string;
  team_status: string;
  organization: string;
}

export interface usersStateType {
  isDeleting: boolean;
  user: IUser;
  teamOwner: IUser;
  teamOwnerLoading: boolean;
  userCreated: boolean;
  userLoading: boolean;
  userMetrics: UserMetrics | null;
  userMetricsLoading: boolean;
  userSubscriptions: UserSubscriptions[];
  userSubscriptionsLoading: boolean;
  subscriptionServices: SubscriptionService[];
  subscriptionServicesLoading: boolean;
  creditUsage: CreditsUsageItem[];
  creditUsageLoading: boolean;
  billingLoading: boolean;
  isBillingUpdated: boolean;
  invoices: INewInvoiceHistoryItem[];
  invoicesLoading: boolean;
  organization?: string;
  isOrganizationUpdating: boolean;
}

export const usersInitialState: usersStateType = {
  isDeleting: false,
  user: {} as IUser,
  teamOwner: {} as IUser,
  teamOwnerLoading: false,
  userCreated: false,
  userLoading: false,
  userMetrics: null,
  userMetricsLoading: false,
  userSubscriptions: [],
  userSubscriptionsLoading: false,
  subscriptionServices: [],
  subscriptionServicesLoading: false,
  creditUsage: [],
  creditUsageLoading: false,
  billingLoading: false,
  isBillingUpdated: false,
  invoices: [],
  invoicesLoading: false,
  organization: undefined,
  isOrganizationUpdating: false,
};

const usersReducer = (state = usersInitialState, action: any) => {
  switch (action.type) {
    case REHYDRATE: {
      const persistedData = action.payload?.users;
      if (persistedData) {
        return {
          ...state,
          user: {
            ...state.user,
            team_role_name: persistedData.user.team_role_name,
            team_id: persistedData.user.team_id,
          },
          teamOwner: { ...persistedData.teamOwner },
          organization: persistedData.organization,
        };
      }
      return state;
    }
    case UPDATE_IS_USER_DELETING: {
      return {
        ...state,
        isDeleting: action.payload.status,
      };
    }
    case UPDATE_USER: {
      return {
        ...state,
        user: action.payload.user,
      };
    }
    case UPDATE_TEAM_OWNER: {
      return {
        ...state,
        teamOwner: action.payload.user,
      };
    }
    case UPDATE_TEAM_OWNER_LOADING: {
      return {
        ...state,
        teamOwnerLoading: action.payload.status,
      };
    }
    case `${CREATE_USER_SERVER}_SUCCESS`: {
      return {
        ...state,
        userCreated: true,
      };
    }
    case `${CREATE_USER_SERVER}_FAIL`: {
      if (action.error.response.status === 409)
        return {
          ...state,
          userCreated: true,
        };
      return {
        ...state,
      };
    }
    case UPDATE_USER_LOADING: {
      return {
        ...state,
        userLoading: action.payload.status,
      };
    }
    case UPDATE_METRICS_OVERVIEW: {
      return {
        ...state,
        userMetrics: action.payload.data,
      };
    }
    case UPDATE_METRICS_OVERVIEW_LOADING: {
      return {
        ...state,
        userMetricsLoading: action.payload.status,
      };
    }
    case UPDATE_USER_SUBSCRIPTIONS: {
      return {
        ...state,
        userSubscriptions: action.payload.data,
      };
    }
    case UPDATE_USER_SUBSCRIPTIONS_LOADING: {
      return {
        ...state,
        userSubscriptionsLoading: action.payload.status,
      };
    }
    case UPDATE_SUBSCRIPTION_SERVICES: {
      return {
        ...state,
        subscriptionServices: action.payload.data,
      };
    }
    case UPDATE_SUBSCRIPTION_SERVICES_LOADING: {
      return {
        ...state,
        subscriptionServicesLoading: action.payload.status,
      };
    }
    case UPDATE_USER_CREDIT_USAGE: {
      return {
        ...state,
        creditUsage: action.payload.data,
      };
    }
    case UPDATE_USER_CREDIT_USAGE_LOADING: {
      return {
        ...state,
        creditUsageLoading: action.payload.status,
      };
    }
    case UPDATE_BILLING_LOADING: {
      return {
        ...state,
        billingLoading: action.payload.status,
      };
    }
    case CHANGE_IS_BILLING_UPDATED: {
      return {
        ...state,
        isBillingUpdated: action.payload.status,
      };
    }
    case UPDATE_INVOICES: {
      return {
        ...state,
        invoices: action.payload.invoices,
      };
    }
    case UPDATE_INVOICES_LOADING: {
      return {
        ...state,
        invoicesLoading: action.payload.status,
      };
    }
    case SET_USER_ORGANIZATION: {
      return {
        ...state,
        organization: action.payload.organization,
      };
    }
    case UPDATE_ORGANIZATION: {
      return {
        ...state,
        organization: action.payload.organization,
      };
    }
    case SET_IS_USER_ORGANIZATION_UPDATING: {
      return {
        ...state,
        isOrganizationUpdating: action.payload.status,
      };
    }
    case CLEAR_ORGANIZATION_DATA: {
      return {
        ...state,
        organization: undefined,
      };
    }
    default: {
      return { ...state };
    }
  }
};

export const getIsUserDeleting = (state: StoreType) => state.users.isDeleting;
export const getUser = (state: StoreType) => state.users.user;
export const getTeamOwner = (state: StoreType) => state.users.teamOwner;
export const getTeamOwnerLoading = (state: StoreType) => state.users.teamOwnerLoading;
export const getUserCreated = (state: StoreType) => state.users.userCreated;
export const getUserLoading = (state: StoreType) => state.users.userLoading;
export const getUserMetrics = (state: StoreType) => state.users.userMetrics;
export const getUserMetricsLoading = (state: StoreType) => state.users.userMetricsLoading;
export const getUserSubscriptions = (state: StoreType) => state.users.userSubscriptions;
export const getUserSubscriptionsLoading = (state: StoreType) => state.users.userSubscriptionsLoading;
export const getSubscriptionServices = (state: StoreType) => state.users.subscriptionServices;
export const getSubscriptionServicesLoading = (state: StoreType) => state.users.subscriptionServicesLoading;
export const getCreditUsage = (state: StoreType) => state.users.creditUsage;
export const getCreditUsageLoading = (state: StoreType) => state.users.creditUsageLoading;
export const getIsBillingLoading = (state: StoreType) => state.users.billingLoading;
export const getIsBillingUpdated = (state: StoreType) => state.users.isBillingUpdated;
export const getInvoicesHistory = (state: StoreType) => state.users.invoices;
export const getInvoicesHistoryLoading = (state: StoreType) => state.users.invoicesLoading;
export const getUserOrganization = (state: StoreType) => state.users.organization;
export const getIsOrganizationUpdating = (state: StoreType) => state.users.isOrganizationUpdating;

export default usersReducer;
