import { Button } from "@maestro-org/ui-kit";
import { styled, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

import { SuccessCopiedIcon } from "../../../components/Icons";

import { pages } from "../../../lib/routeUtils";

const PaymentSuccess = () => {
  return (
    <Wrapper>
      <TitleWrapper>
        <SuccessCopiedIcon />
        <Typography variant="h5" color="grey.A200">
          Payment received!
        </Typography>
      </TitleWrapper>
      <Typography color="grey.A200" variant="paragraphSmall">
        Your order is now on the way. Please check your email for the receipt.
      </Typography>
      <ButtonWrapper>
        <Link to={pages.main()}>
          <Button>Back to Dashboard</Button>
        </Link>
      </ButtonWrapper>
    </Wrapper>
  );
};

const Wrapper = styled("div")({
  display: "flex",
  flexDirection: "column",
  rowGap: "20px",
});

const TitleWrapper = styled("div")({
  display: "flex",
  alignItems: "center",
  columnGap: "12px",

  "& svg": {
    width: "24px",
    height: "24px",
  },
});

const ButtonWrapper = styled("div")({
  paddingTop: "40px",

  "& a": {
    display: "flex",
  },

  "& button": {
    width: "100%",
  },
});

export default PaymentSuccess;
