import React, { useEffect, useState } from "react";
import { Button } from "@maestro-org/ui-kit";
import { styled, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import { deleteProjectsServer } from "../../redux/actions/projectsActions";
import { updatePopup } from "../../redux/actions/popupsActions";
import { getDeleteProjectDialog, getPrefilled } from "../../redux/reducers/popupsReducer";
import { getAllprojects } from "../../redux/reducers/projectsReducer";
import { toast } from "react-toastify";

import Dialog from "./Dialog";

import { Popups } from "../../types/popups";

const DeleteProjectDialog = () => {
  const [projectName, setProjectName] = useState("");

  const allProjects = useSelector(getAllprojects);
  const isOpen = useSelector(getDeleteProjectDialog);
  const { projectId } = useSelector(getPrefilled) || {};

  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(updatePopup({ popup: Popups.deleteProject, status: false }));
    setProjectName("");
  };

  const handleDelete = () => {
    if (!projectId) {
      toast.error("Error occurred while deleting project");
      return;
    }
    dispatch(deleteProjectsServer({ id: projectId, prevProjects: allProjects }));
    toast.success("Successfully deleted project");
    handleClose();
  };

  useEffect(() => {
    if (!allProjects.length) return;
    const projectName = allProjects.find((item) => item.id === projectId)?.name;
    if (!projectName) return;
    setProjectName(projectName);
  }, [allProjects, projectId]);

  return (
    <Dialog open={isOpen}>
      <Wrapper>
        <Typography color="grey.A200" variant="h5">
          Delete {projectName}
        </Typography>
        <Typography color="grey.A200" variant="paragraphMedium">
          Are you sure you want to delete this project? The API key associated with this project will stop working once
          the project is deleted.
        </Typography>
        <Actions>
          <CancelButton onClick={handleClose}>Cancel</CancelButton>
          <Button onClick={handleDelete}>Delete</Button>
        </Actions>
      </Wrapper>
    </Dialog>
  );
};

const Wrapper = styled("div")({
  display: "flex",
  flexDirection: "column",
  rowGap: "40px",
});

const Actions = styled("div")({
  display: "flex",
  alignItems: "center",
  columnGap: "12px",
});

const CancelButton = styled(Button)(({ theme }) => ({
  background: theme.palette.grey["50"],
  color: theme.palette.grey.A200,

  "&:hover": {
    background: theme.palette.grey["50"],
    color: theme.palette.grey.A200,
  },
}));

export default DeleteProjectDialog;
