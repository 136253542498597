import { FC } from "react";
import { styled, Theme, Typography, useTheme } from "@mui/material";
import { Button, Dropdown, TextField } from "@maestro-org/ui-kit";

import Tooltip from "../../../../components/Tooltip/Tooltip";

import { useFormik } from "formik";

import { useDispatch } from "react-redux";

import { Popups } from "../../../../types/popups";
import { updatePopup } from "../../../../redux/actions/popupsActions";

import { getCreateAddTeamMemberFields, initialValuesAddTeamMember } from "../../../../forms/addTeamMember/form";
import { AddTeamMemberFormValues, AddTeamMemberFields } from "../../../../forms/addTeamMember/types";
import { addTeamMemberSchema } from "../../../../forms/addTeamMember/validation";
import { NewEntryField, NewEntryFieldTypes } from "../../../../types/newEntry";

interface Props {
  teamUsersNumber: number;
}

const TeamCardInvite: FC<Props> = ({ teamUsersNumber }) => {
  const dispatch = useDispatch();

  const theme = useTheme();

  const onSubmit = (values: AddTeamMemberFormValues) => {
    dispatch(
      updatePopup({
        popup: Popups.addTeamMember,
        status: true,
        prefilled: { email: values.emailAddress, role: values.role[0].toLowerCase() },
      }),
    );

    formik.resetForm();
  };

  const checkError = (name: string) => !!errors[name as keyof typeof errors] && touched[name as keyof typeof touched];

  const handleDropdownBlur = (name: string) => {
    setFieldTouched(name, true, true);
  };

  const handleDropdownChange = (value: unknown, name: string) => {
    setFieldValue(name, value, true);
  };

  const getField = (field: NewEntryField) => {
    const fieldValue = values[field.name as keyof typeof values] || "";
    const isEmailTooLong = field.name === AddTeamMemberFields.emailAddress && fieldValue.length >= 32;

    return {
      [NewEntryFieldTypes.text]: (
        <StyledTextfield
          label={field.label}
          variant="outlined"
          name={field.name}
          value={fieldValue}
          onChange={handleChange}
          placeholder={field.placeholder}
          error={checkError(field.name) as boolean}
          onBlur={handleBlur}
          fieldLabel={field.fieldLabel}
          inputProps={{ maxLength: 32 }}
          isMaxReached={isEmailTooLong}
        />
      ),
      [NewEntryFieldTypes.select]: (
        <StyledDropdown
          label={field.label}
          name={field.name}
          fullWidth
          onBlur={() => handleDropdownBlur(field.name)}
          value={values[field.name as keyof typeof values] as any}
          placeholder={field.placeholder}
          onChange={(value) => handleDropdownChange(value, field.name)}
          options={field.options || []}
          error={checkError(field.name) as boolean}
          fieldLabel={field.fieldLabel}
          MenuProps={{
            PaperProps: {
              style: {
                ...getMenuStyle(theme, checkError(field.name) as boolean),
              },
            },
          }}
        />
      ),
    };
  };

  const formik = useFormik({
    initialValues: initialValuesAddTeamMember,
    validationSchema: addTeamMemberSchema,
    validateOnChange: true,
    enableReinitialize: true,
    onSubmit,
  });

  const { values, setFieldValue, setFieldTouched, errors, touched, handleChange, handleBlur, handleSubmit } = formik;

  return (
    <Form onSubmit={handleSubmit}>
      {getCreateAddTeamMemberFields().map((field: NewEntryField) => getField(field)[field.type])}
      <StyledTooltip
        title={
          <Typography variant="paragraphSmall" color="grey.A200">
            You have reached the limit of 5 users per team.
          </Typography>
        }
        disableHoverListener={!(teamUsersNumber >= 5)}
      >
        <ButtonWrapper>
          <StyledButton
            type="submit"
            onMouseDown={(e) => e.preventDefault()}
            disabled={!formik.isValid || !formik.dirty}
          >
            Invite
          </StyledButton>
        </ButtonWrapper>
      </StyledTooltip>
    </Form>
  );
};

const Form = styled("form")({
  display: "flex",
  alignItems: "end",
  gap: "20px",
  width: "100%",

  "& > div:first-child": {
    width: "100%",
    height: "100%",
  },

  "& > div:nth-child(2)": {
    width: "fit-content",
    height: "100%",
  },

  "& .MuiBox-root": {
    width: "140px",
    flex: 1,

    "& .MuiFormControl-root": {
      height: "100%",

      "& .MuiInputBase-root": {
        height: "100%",
      },
    },
  },
});

const StyledTextfield = styled(TextField)<{ isMaxReached: boolean }>(({ isMaxReached }) => ({
  ...(isMaxReached && {
    "& fieldset": {
      borderColor: "#DC6675 !important",
    },

    "& .Mui-focused fieldset": {
      borderColor: "#DC6675 !important",
    },
  }),
}));

const StyledButton = styled(Button)({
  "&.MuiButton-root": {
    padding: "14px 34px",
  },

  "&.Mui-disabled": {
    background: "#CCCCCC",
    color: "#999999",
  },
});

const StyledDropdown = styled(Dropdown)(({ theme }) => ({
  maxWidth: "180px",

  "& .MuiSelect-select": {
    backgroundColor: theme.palette.common.white,
    borderRadius: "4px !important",
    borderWidth: "1px !important",
  },

  "& label": {
    left: "0px !important",
  },
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-notchedOutline": {
    border: `1px solid ${theme.palette.textfield.border.main} !important`,
    borderRadius: "4px",
    width: "100%",
  },

  "& .MuiOutlinedInput-root": {
    padding: "12px 16px !important",
  },

  "& .MuiFormLabel-root.Mui-error": {
    color: "#DC6675 !important",
  },

  "& .MuiInputLabel-shrink.Mui-focused": {
    color: `${theme.palette.primary.main} !important`,
  },
}));

const StyledTooltip = styled(Tooltip)(({ theme }) => ({
  zIndex: "100",
  "& .MuiTooltip-tooltip": {
    maxWidth: "216px",
    padding: "16px",
    boxShadow: "0px 2px 16px -2px rgba(15, 3, 17, 0.16)",
    border: `1px solid ${theme.palette.grey[100]}`,
    borderRadius: "6px",
  },
}));

const ButtonWrapper = styled("div")({
  // display: "flex",
});

const getMenuStyle = (theme: Theme, error?: boolean, width?: number) => ({
  width: width ? `${width}px` : "auto",
  background: theme.palette.common.white,
  boxShadow: "none",
  border: `1px solid ${error ? theme.palette.dropdown.border.error : theme.palette.dropdown.border.main}`,
  transform: "translateY(5px)",
});

export default TeamCardInvite;
