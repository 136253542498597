import React from "react";
import { styled, Typography } from "@mui/material";

import Lower from "../modules/Auth/components/Lower";

import Container from "../components/Container/Container";
import Logo, { LogoStyles } from "../components/Logo/Logo";
import { TwitterIcon, TelegramIcon, DiscordIcon } from "../components/Icons";

interface Props {
  children?: React.ReactNode;
  title?: string;
  backgroundVideo?: string;
  withoutLeftBlock?: boolean;
  isAuth?: boolean;
}

const socials = [
  {
    src: "https://twitter.com/GoMaestroOrg",
    element: <TwitterIcon />,
  },
  {
    src: "https://t.me/+WsD8BXKBggw5ZmU5",
    element: <TelegramIcon />,
  },
  {
    src: "https://discord.gg/ES2rDhBJt3",
    element: <DiscordIcon />,
  },
];

const Line = () => (
  <StyledLineWrapper>
    <StyledLine width="1920" height="2315" viewBox="0 0 1920 2315" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1358.76 348.179C1272.05 399.559 1183.52 452.009 1091.6 503.429C1075.51 512.429 1071.27 533.619 1082.61 548.159C1108.22 580.959 1134.64 612.539 1161.88 642.869C1476.5 993.139 1830.54 1101.25 2072.14 1130.26L2071.43 1136.22C1919.86 1118.02 1772.14 1074.76 1632.4 1007.64C1456.26 923.039 1296.46 801.659 1157.43 646.869C1130.11 616.449 1103.48 584.589 1077.61 551.399C1065.02 535.239 1042.56 530.609 1024.55 540.369C988.74 559.789 952.39 578.989 915.39 597.839C737.84 688.299 569.58 758.129 400.98 811.319C196.91 875.699 -9.47998 916.909 -229.99 937.299C-350.14 948.409 -475.77 953.179 -603.4 951.469C-737.88 949.669 -879.05 940.619 -1023 924.569L-1022.33 918.609C-855.71 937.189 -700.15 945.689 -553.97 945.689C138.49 945.689 616.53 754.409 1020.68 535.559C1037.37 526.519 1042.4 504.879 1031.34 489.449C998.95 444.229 967.94 396.879 938.51 347.639C867.37 228.639 803.62 95.6287 749.02 -47.7013C690.46 -201.431 640.9 -371.041 601.71 -551.831C559.96 -744.491 528.87 -954.981 509.33 -1177.47L515.31 -1178C577.77 -467.071 753.08 91.8988 1037.15 487.419C1048.68 503.479 1070.48 508.289 1087.74 498.649C1180.05 447.079 1268.79 394.499 1355.71 342.999C1527.35 241.299 1689.47 145.229 1860.63 66.6088C1952.42 24.4387 2038.77 -9.00122 2124.59 -35.6312C2220.37 -65.3512 2313.56 -86.0613 2409.5 -98.9413L2410.3 -92.9912C2013.37 -39.7112 1695.41 148.689 1358.77 348.159L1358.76 348.179Z"
        fill="#C53DD8"
      />
    </StyledLine>
  </StyledLineWrapper>
);

const AuthLayout = ({ children, title, backgroundVideo, withoutLeftBlock, isAuth }: Props) => (
  <OuterWrapper>
    <Container>
      <InnerWrapper withoutLeftBlock={withoutLeftBlock}>
        <Left withoutLeftBlock={withoutLeftBlock}>
          <LogoWrapper>
            <Logo style={LogoStyles.Dark} />
          </LogoWrapper>
          {!withoutLeftBlock && (
            <DesktopOnly>
              <MainTitleWrapper>
                {isAuth ? (
                  <AuthTitle color="textColor.dark">{title}</AuthTitle>
                ) : (
                  <StyledTitle color="textColor.dark" variant="authTitle">
                    {title}
                  </StyledTitle>
                )}
              </MainTitleWrapper>
            </DesktopOnly>
          )}
          {!withoutLeftBlock && (
            <DesktopLowerWrapper>
              <DesktopOnly>
                <Lower icons={socials} />
              </DesktopOnly>
            </DesktopLowerWrapper>
          )}
        </Left>
        <Right withoutLeftBlock={withoutLeftBlock}>
          <MobileFormWrapper>
            {!withoutLeftBlock && (
              <MobileOnly>
                <MainTitleWrapper>
                  {isAuth ? (
                    <AuthTitle color="textColor.dark">{title}</AuthTitle>
                  ) : (
                    <StyledTitle color="textColor.dark" variant="authTitle">
                      {title}
                    </StyledTitle>
                  )}
                </MainTitleWrapper>
              </MobileOnly>
            )}
            <ContentWrapper>{children && <Content>{children}</Content>}</ContentWrapper>
          </MobileFormWrapper>
        </Right>
        <MobileOnly withoutLeftBlock={withoutLeftBlock}>
          <MobileLowerWrapper>
            <Lower icons={socials} />
          </MobileLowerWrapper>
        </MobileOnly>
      </InnerWrapper>
    </Container>
    {backgroundVideo ? (
      <Video autoPlay muted loop>
        <source src={backgroundVideo} type="video/mp4" />
      </Video>
    ) : (
      <Line />
    )}
  </OuterWrapper>
);

const StyledTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 300,
  fontSize: "50px",
  lineHeight: "80px",

  [theme.breakpoints.down("md")]: {
    fontWeight: 300,
    fontSize: "50px",
    lineHeight: "60px",
  },

  [theme.breakpoints.down("xs")]: {
    fontWeight: 300,
    fontSize: "40px",
    lineHeight: "56px",
  },
}));

const AuthTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 300,
  fontSize: "40px",
  lineHeight: "56px",

  [theme.breakpoints.down("sm")]: {
    marginTop: "-160px",
  },

  [theme.breakpoints.down("xs")]: {
    fontWeight: 400,
    fontSize: "32px",
    lineHeight: "40px",
  },
}));

const StyledLineWrapper = styled("div")(({ theme }) => ({
  position: "absolute",
  height: "100%",
  overflow: "hidden",
  top: "0px",
  right: "0px",
  width: "100%",

  [theme.breakpoints.down("sm")]: {
    display: "none",
  },
}));

const StyledLine = styled("svg")(({ theme }) => ({
  position: "fixed",
  zIndex: "200",
  left: "0px",
  top: "-65px",
  marginRight: "calc(-1 * (100vw - 100%))",

  [theme.breakpoints.down("xl")]: {
    left: "-300px",
  },

  [theme.breakpoints.down("md")]: {
    left: "-900px",
  },
}));

const OuterWrapper = styled("div")(({ theme }) => ({
  background: theme.palette.grey["50"],
  position: "relative",
}));

const InnerWrapper = styled("div", {
  shouldForwardProp: (prop) => prop !== "withoutLeftBlock",
})<{ withoutLeftBlock?: boolean }>(({ withoutLeftBlock, theme }) => ({
  display: "flex",
  minHeight: "100vh",
  background: theme.palette.grey["50"],

  ...(withoutLeftBlock && {
    flexDirection: "column",
    justifyContent: "space-between",
  }),

  [theme.breakpoints.down("lg")]: {
    columnGap: "20px",
  },

  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    justifyContent: "space-between",
  },
}));

const Left = styled("div", {
  shouldForwardProp: (prop) => prop !== "withoutLeftBlock",
})<{ withoutLeftBlock?: boolean }>(({ withoutLeftBlock, theme }) => ({
  flex: 1,
  position: "relative",
  zIndex: 300,
  display: "flex",
  flexDirection: "column",
  padding: "80px 0 64px",

  ...(withoutLeftBlock && {
    flex: 0,
    padding: "30px 0",
  }),

  [theme.breakpoints.down("md")]: {
    flex: 0,
    padding: "30px 0",
  },

  [theme.breakpoints.down("sm")]: {
    padding: "22px 0",
  },
}));

const LogoWrapper = styled("div")({
  height: "40px",
});

const MainTitleWrapper = styled("div")(({ theme }) => ({
  paddingTop: "180px",
  maxWidth: "460px",

  [theme.breakpoints.down("md")]: {
    paddingTop: "30px",
  },
}));

const DesktopLowerWrapper = styled("div")({
  flex: 1,
  display: "flex",
  alignItems: "flex-end",
});

const MobileLowerWrapper = styled("div")(({ theme }) => ({
  margin: "44px 0 40px",
  flex: 1,
  display: "flex",
  alignItems: "flex-end",
  justifyContent: "center",

  [theme.breakpoints.up("md")]: {
    justifyContent: "left",
  },
}));

const MobileOnly = styled("div", {
  shouldForwardProp: (prop) => prop !== "withoutLeftBlock",
})<{ withoutLeftBlock?: boolean }>(({ withoutLeftBlock, theme }) => ({
  zIndex: "100",

  [theme.breakpoints.up("md")]: {
    display: "none",
  },

  ...(withoutLeftBlock && {
    [theme.breakpoints.up("md")]: {
      display: "block",
    },
  }),
}));

const DesktopOnly = styled("div")(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    display: "none",
  },
}));

const Right = styled("div", {
  shouldForwardProp: (prop) => prop !== "withoutLeftBlock",
})<{ withoutLeftBlock?: boolean }>(({ theme, withoutLeftBlock }) => ({
  zIndex: 300,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",

  ...(withoutLeftBlock && {
    alignItems: "center",
  }),

  [theme.breakpoints.down("md")]: {
    // width: "100%",
    alignItems: "center",
  },
}));

const MobileFormWrapper = styled("div")(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    display: "flex",
    flexDirection: "column",
    rowGap: "50px",
    justifyContent: "center",
  },
  [theme.breakpoints.down("sm")]: {
    rowGap: "30px",
  },
}));

const ContentWrapper = styled("div")(({ theme }) => ({
  flex: 1,
  display: "flex",
  alignItems: "center",

  [theme.breakpoints.down("md")]: {
    width: "100%",
    // justifyContent: "center",
  },
}));

const Content = styled("div")(({ theme }) => ({
  width: "459px",
  maxWidth: "100%",
  borderRadius: theme.borderRadius.ms,
  border: `2px solid ${theme.palette.grey["A200"]}`,
  background: theme.palette.common.white,
  padding: "45px 40px",

  [theme.breakpoints.down("md")]: {
    width: "550px",
    // padding: "35px 30px",
  },

  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));

const Video = styled("video")(({ theme }) => ({
  position: "fixed",
  right: 0,
  bottom: 0,
  minWidth: "100%",
  minHeight: "100%",

  [theme.breakpoints.down("md")]: {
    right: -1000,
  },

  [theme.breakpoints.down("sm")]: {
    right: -1100,
    bottom: -140,
  },
}));

export default AuthLayout;
