import React, { useState } from "react";
import { styled, Typography } from "@mui/material";
import { Button, IconButton, TextField } from "@maestro-org/ui-kit";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

import { updatePopup } from "../../redux/actions/popupsActions";
import { getApiKeyCreatedDialog, getPrefilled } from "../../redux/reducers/popupsReducer";

import Dialog from "./Dialog";
import { EyeIcon } from "../Icons";

import { Popups } from "../../types/popups";
import { pages } from "../../lib/routeUtils";
import { copyToClipboard } from "../../lib/copyToClipboard";
import { addToResetKeys } from "../../redux/actions/projectsActions";

const APIKeyCreatedDialog = () => {
  const [isPasswordType, setIsPasswordType] = useState(true);
  const isOpen = useSelector(getApiKeyCreatedDialog);
  const { apiKey, projectId } = useSelector(getPrefilled) || {};
  const dispatch = useDispatch();

  const togglePasswordType = () => setIsPasswordType(!isPasswordType);

  const handleClose = () => {
    setIsPasswordType(true);
    dispatch(updatePopup({ popup: Popups.apiKeyCreated, status: false }));
  };

  const handleCopy = () => {
    copyToClipboard(apiKey);
    toast.success("Copied to clipboard");
    dispatch(addToResetKeys({ id: projectId }));
    handleClose();
  };

  return (
    <Dialog open={isOpen}>
      <Title color="grey.A200" variant="h5">
        API key successfuly created
      </Title>
      <APIKeyWrapper>
        <TextField
          variant="outlined"
          label="API key"
          type={isPasswordType ? "password" : "text"}
          value={apiKey}
          endIcon={
            <StyledIconButton onClick={togglePasswordType}>
              <EyeIcon />
            </StyledIconButton>
          }
        />
        <Typography variant="paragraphSmall" color="grey.500">
          This is your unique key and it is non-recoverable. If you loose this API key, you will have to reset it.{" "}
          <Ref onClick={handleClose} to={pages.main()}>
            Learn more
          </Ref>
        </Typography>
      </APIKeyWrapper>
      <Actions>
        <CancelButton onClick={handleClose}>
          <Typography color="grey.A200" variant="paragraphSmall">
            Cancel
          </Typography>
        </CancelButton>
        <Button onClick={handleCopy}>
          <Typography variant="paragraphSmall">Copy</Typography>
        </Button>
      </Actions>
    </Dialog>
  );
};

const Title = styled(Typography)({
  maxWidth: "404px",
});

const APIKeyWrapper = styled("div")({
  display: "flex",
  flexDirection: "column",
  rowGap: "16px",
});

const Actions = styled("div")({
  display: "flex",
  alignItems: "center",
  columnGap: "12px",
});

const CancelButton = styled(Button)(({ theme }) => ({
  background: theme.palette.grey["50"],

  "&:hover": {
    background: theme.palette.grey["50"],
  },
}));

const StyledIconButton = styled(IconButton)({
  background: "transparent",
  width: "25px",
  height: "25px",

  "&:hover": {
    background: "transparent",
  },
});

const Ref = styled(Link)(({ theme }) => ({
  color: theme.palette.primary.main,
  cursor: "pointer",
}));

export default APIKeyCreatedDialog;
