// prettier-ignore
export interface AddTeamMemberFormValues {
    [AddTeamMemberFields.emailAddress]: string;
    [AddTeamMemberFields.role]: string[];
}

// prettier-ignore
export enum AddTeamMemberFields {
    emailAddress = "emailAddress",
    role = "role",
}
