import React, { useEffect } from "react";
import { styled } from "@mui/material";

import SettingsLayout from "../../layouts/SettingsLayout";

import { SettingsPages } from "../../types/settings";
import withPrivateRoute from "../../hooks/withPrivateRoute";
import BillingInvoiceHistory from "./components/BillingInvoiceHistory";

const BillingPage = () => {
  return (
    <SettingsLayout
      path={[
        { title: "Settings", href: "/" },
        { title: "Subscription", href: "/" },
        { title: "Invoices", href: "/" },
      ]}
      active={SettingsPages.billing}
    >
      <Wrapper>
        <BillingInvoiceHistory />
      </Wrapper>
    </SettingsLayout>
  );
};

const Wrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  rowGap: "32px",

  "@media (max-height: 1000px)": {
    rowGap: "28px",
  },

  [theme.breakpoints.down("xl")]: {
    rowGap: "25px",
  },
}));

export default withPrivateRoute(BillingPage);
