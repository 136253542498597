import { toast } from "react-toastify";
import { takeEvery, put } from "redux-saga/effects";
import {
  GET_PROJECTS_REQUEST_VOLUME_SERVER,
  updateProjectsRequestVolume,
  updateRequestVolumeLoading,
} from "../actions/requestVolumeActions";

const requestVolumeSagas = [
  takeEvery(GET_PROJECTS_REQUEST_VOLUME_SERVER, handleGetRequestVolume),
  takeEvery(`${GET_PROJECTS_REQUEST_VOLUME_SERVER}_SUCCESS`, handleGetRequestVolumeSuccess),
  takeEvery(`${GET_PROJECTS_REQUEST_VOLUME_SERVER}_FAIL`, handleGetRequestVolumeFail),
];

function* handleGetRequestVolume() {
  yield put(updateRequestVolumeLoading({ status: true }));
}

function* handleGetRequestVolumeFail() {
  yield put(updateRequestVolumeLoading({ status: false }));
  toast.error("Could not get request volume data");
}

function* handleGetRequestVolumeSuccess(action: any) {
  yield put(updateRequestVolumeLoading({ status: false }));
  yield put(updateProjectsRequestVolume({ data: action.payload.data }));
}

export default requestVolumeSagas;
