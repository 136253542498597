import React, { useEffect, useState } from "react";
import { Button } from "@maestro-org/ui-kit";
import { styled, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import { addTeamMemberServer } from "../../redux/actions/teamsActions";
import { updatePopup } from "../../redux/actions/popupsActions";
import { getAddTeamMemberDialog, getPrefilled } from "../../redux/reducers/popupsReducer";
import { toast } from "react-toastify";

import Dialog from "./Dialog";

import { Popups } from "../../types/popups";

const AddTeamMemberDialog = () => {
  const isOpen = useSelector(getAddTeamMemberDialog);
  const { email, role } = useSelector(getPrefilled) || {};

  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(updatePopup({ popup: Popups.addTeamMember, status: false }));
  };

  const handleAdd = () => {
    if (!email || !role) {
      toast.error("Error occurred while adding new team user");
      return;
    }
    dispatch(addTeamMemberServer(email, role));
    handleClose();
  };

  return (
    <Dialog open={isOpen}>
      <Wrapper>
        <Typography color="grey.A200" variant="h5">
          Add {email}
        </Typography>
        <Typography color="grey.A200" variant="paragraphMedium">
          Are you sure you want to add this user?
        </Typography>
        <Actions>
          <CancelButton onClick={handleClose}>Cancel</CancelButton>
          <Button onClick={handleAdd}>Add</Button>
        </Actions>
      </Wrapper>
    </Dialog>
  );
};

const Wrapper = styled("div")({
  display: "flex",
  flexDirection: "column",
  rowGap: "40px",
});

const Actions = styled("div")({
  display: "flex",
  alignItems: "center",
  columnGap: "12px",
});

const CancelButton = styled(Button)(({ theme }) => ({
  background: theme.palette.grey["50"],
  color: theme.palette.grey.A200,

  "&:hover": {
    background: theme.palette.grey["50"],
    color: theme.palette.grey.A200,
  },
}));

export default AddTeamMemberDialog;
