import { Popups } from "../../types/popups";
import { UPDATE_POPUP } from "../actions/popupsActions";
import { StoreType } from "../types/store.types";

export interface popupsStateType {
  [Popups.apiKeyCreated]: boolean;
  [Popups.resetKey]: boolean;
  [Popups.deleteProject]: boolean;
  [Popups.deleteWebhook]: boolean;
  [Popups.addNewProject]: boolean;
  [Popups.createWebhook]: boolean;
  [Popups.deleteAccount]: boolean;
  [Popups.renameTeam]: boolean;
  [Popups.createTeam]: boolean;
  [Popups.addTeamMember]: boolean;
  [Popups.updateTeamMemberRole]: boolean;
  [Popups.deleteTeamMember]: boolean;
  prefilled: any;
}

export const popupsInitialState: popupsStateType = {
  [Popups.apiKeyCreated]: false,
  [Popups.resetKey]: false,
  [Popups.deleteProject]: false,
  [Popups.deleteWebhook]: false,
  [Popups.addNewProject]: false,
  [Popups.createWebhook]: false,
  [Popups.deleteAccount]: false,
  [Popups.renameTeam]: false,
  [Popups.createTeam]: false,
  [Popups.addTeamMember]: false,
  [Popups.updateTeamMemberRole]: false,
  [Popups.deleteTeamMember]: false,
  prefilled: {},
};

const popupsReducer = (state = popupsInitialState, action: any) => {
  switch (action.type) {
    case UPDATE_POPUP: {
      const { popup, status, prefilled } = action.payload;
      return { ...state, [popup]: status, prefilled };
    }
    default:
      return { ...state };
  }
};

export const getApiKeyCreatedDialog = (state: StoreType) => state.popups[Popups.apiKeyCreated];
export const getResetApiKeyDialog = (state: StoreType) => state.popups[Popups.resetKey];
export const getDeleteProjectDialog = (state: StoreType) => state.popups[Popups.deleteProject];
export const getDeleteWebhookDialog = (state: StoreType) => state.popups[Popups.deleteWebhook];
export const getAddNewProjectDialog = (state: StoreType) => state.popups[Popups.addNewProject];
export const getCreateWebhookDialog = (state: StoreType) => state.popups[Popups.createWebhook];
export const getDeleteAccountDialog = (state: StoreType) => state.popups[Popups.deleteAccount];
export const getRenameTeamtDialog = (state: StoreType) => state.popups[Popups.renameTeam];
export const getCreateTeamDialog = (state: StoreType) => state.popups[Popups.createTeam];
export const getAddTeamMemberDialog = (state: StoreType) => state.popups[Popups.addTeamMember];
export const getUpdateTeamMemberRoleDialog = (state: StoreType) => state.popups[Popups.updateTeamMemberRole];
export const getDeleteTeamMemberDialog = (state: StoreType) => state.popups[Popups.deleteTeamMember];
export const getPrefilled = (state: StoreType) => state.popups.prefilled;

export default popupsReducer;
