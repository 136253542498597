import { styled, Typography } from "@mui/material";
import { Loader } from "@maestro-org/ui-kit";

import PlanPackage from "./PlanPackage";
import { Package, TiersProps } from "../../../redux/reducers/stripeReducer";
import { CompareIcon } from "../../../components/Icons/CompareIcon";

import { formActiveSubscriptionName } from "../../../lib/subscriptionPlanUtils";
import { SubscriptionPlan } from "../../../types/subscription";

interface Props {
  selectedPlan?: Package;
  subscriptionTiers?: TiersProps;
  subscriptionPlans: Package[];
  activeSubscriptionId: string;
  activeSubscriptionName: SubscriptionPlan;
  isLoading: boolean;
  isVirtuosoAnnual: boolean;
  handleToogleSwitch: () => void;
  handlePlanSelection: (planId: string) => void;
}

const APIPackages = ({
  selectedPlan,
  subscriptionPlans,
  isLoading,
  isVirtuosoAnnual,
  subscriptionTiers,
  activeSubscriptionId,
  activeSubscriptionName,
  handleToogleSwitch,
  handlePlanSelection,
}: Props) => (
  <Wrapper>
    <TitleOutterWrapper>
      <TitleWrapper>
        <Typography color="grey.A200" variant="h5">
          API Packages
        </Typography>
        <TextWrapper>
          <Typography color="grey.A200" variant="paragraphSmall">
            Currently subscribed to{" "}
            <PlanName>
              {formActiveSubscriptionName(activeSubscriptionId, activeSubscriptionName, subscriptionPlans)}
            </PlanName>
          </Typography>
        </TextWrapper>
      </TitleWrapper>
      <CompareWrapper href="https://www.gomaestro.org/pricing#plan-comparison" target="_blank">
        <CompareText color="textColor.contrastText">Compare Plans</CompareText>
        <CompareIcon />
      </CompareWrapper>
    </TitleOutterWrapper>
    {isLoading ? (
      <Loader />
    ) : (
      <Content>
        <PackagesWrapper>
          {(subscriptionPlans || []).map((item) => (
            <PlanPackage
              {...item}
              key={item.id}
              isVirtuosoAnnual={isVirtuosoAnnual}
              subscriptionTiers={subscriptionTiers}
              handleToogleSwitch={handleToogleSwitch}
              handleChangeActive={handlePlanSelection}
              isPlanSelected={item.id === selectedPlan?.id}
            />
          ))}
        </PackagesWrapper>
        <Fade />
      </Content>
    )}
  </Wrapper>
);

const Wrapper = styled("div")({
  display: "flex",
  flexDirection: "column",
  rowGap: "24px",
});

const TitleWrapper = styled("div")({
  display: "flex",
  flexDirection: "column",
  rowGap: "12px",
  position: "relative",
});

const PlanName = styled("strong")({
  textTransform: "capitalize",
});

const TextWrapper = styled("div")({
  display: "flex",
  alignItems: "center",
  gap: "4px",
});

const TitleOutterWrapper = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
  position: "relative",
});

const CompareText = styled(Typography)({
  fontSize: "16px",
});

const CompareWrapper = styled("a")(({ theme }) => ({
  position: "absolute",
  right: "0",
  top: "6px",

  display: "flex",
  gap: "12px",
  alignItems: "center",
  justifySelf: "end",

  [theme.breakpoints.down("md")]: {
    position: "relative",
    display: "flex",
  },
}));

const Content = styled("div")({
  position: "relative",
});

const PackagesWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  columnGap: "36px",
  overflow: "auto hidden",
  padding: "16px 80px 40px 0",
  position: "relative",

  "&::-webkit-scrollbar": {
    height: "2px",
  },

  "&::-webkit-scrollbar-track": {
    backgroundColor: "#E6E6E6",
    height: "2px",
  },

  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#0F0311",
    borderRadius: "2px",
    height: "2px",
  },

  "&::-webkit-scrollbar-button": {
    display: "none",
  },

  [theme.breakpoints.down("xl")]: {
    columnGap: "26px",
  },
}));

const Fade = styled("div")(({ theme }) => ({
  position: "absolute",
  right: 0,
  top: 0,
  bottom: 5,
  width: "136px",
  background: "linear-gradient(270deg, #F5F5F5 15.15%, rgba(245, 245, 245, 0) 77.52%)",
  pointerEvents: "none",

  [theme.breakpoints.down(1101)]: {
    width: "100px",
  },
}));

export default APIPackages;
