import { UserReuestVolume } from "../../types/user";
import {
  UPDATE_PROJECTS_REQUEST_VOLUME,
  UPDATE_PROJECTS_REQUEST_VOLUME_LOADING,
} from "../actions/requestVolumeActions";
import { StoreType } from "../types/store.types";

export interface requestVolumeStateType {
  data: UserReuestVolume[];
  isLoading: boolean;
}

export const requestVolumeInitialState: requestVolumeStateType = {
  data: [],
  isLoading: true,
};

const requestVolumeReducer = (state = requestVolumeInitialState, action: any) => {
  switch (action.type) {
    case UPDATE_PROJECTS_REQUEST_VOLUME: {
      return {
        ...state,
        data: [...action.payload.data],
      };
    }
    case UPDATE_PROJECTS_REQUEST_VOLUME_LOADING: {
      return {
        ...state,
        isLoadingRequestVolume: action.payload.status,
      };
    }
    default: {
      return { ...state };
    }
  }
};

export const getProjectsRequestVolume = (state: StoreType) => state.requestVolume.data;
export const getProjectsRequestVolumeLoading = (state: StoreType) => state.requestVolume.isLoading;

export default requestVolumeReducer;
