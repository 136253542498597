import { styled } from "@mui/material";
import React from "react";

import { SettingsPages } from "../../types/settings";

import SettingsLayout from "../../layouts/SettingsLayout";

import AccountDetails from "./components/AccountDetails";
import EmailNotification from "./components/EmailNotification";
import UserCredentials from "./components/UserCredentials";
import DeleteAccount from "./components/DeleteAccount";

import withPrivateRoute from "../../hooks/withPrivateRoute";
import MfaSettings from "./MfaSettings";

import { getUser } from "../../redux/reducers/usersReducer";

import { useSelector } from "react-redux";
import { UserRoles } from "../../types/roles";

const AccountPage = () => {
  const user = useSelector(getUser);
  return (
    <SettingsLayout
      path={[
        { title: "Settings", href: "/" },
        { title: "Account", href: "/" },
        { title: "Account details", href: "/" },
      ]}
      active={SettingsPages.accountDetails}
    >
      <OuterWrapper>
        <AccountDetails />
        <MfaSettings />
        {/*<EmailNotification />*/}
        <UserCredentials />
        {user.team_role_name === UserRoles.OWNER && (
          <>
            <DeleteAccount />
          </>
        )}
      </OuterWrapper>
    </SettingsLayout>
  );
};

const OuterWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  rowGap: "32px",
}));

export default withPrivateRoute(AccountPage);
