import { styled, Typography } from "@mui/material";
import React from "react";

interface CustomPieChartLabelProps {
  cx: number;
  cy: number;
  image: string;
  value: number;
  activeIndex: number;
  isAnimationActive: boolean;
  color: any;
}

const CustomPieChartLabel = ({
  cx,
  cy,
  image,
  value,
  activeIndex,
  isAnimationActive,
  color,
}: CustomPieChartLabelProps) => (
  <ImageOuterWrapper hover={isAnimationActive} x={cx - 43} y={cy - 37.5}>
    <ImageInnerWrapper className="card-inner-wrapper">
      <FrontFace>
        <div>
          <ImageWrapper>
            <svg width="75" height="75" viewBox="0 0 75 75" fill="none">
              <image href={image} height="100%" width="100%" />
            </svg>
          </ImageWrapper>
        </div>
      </FrontFace>
      <BackFace>
        <BackSideCircle activeIndex={activeIndex} color={color}>
          <Typography color="textColor.light" variant="paragraphMedium" lineHeight="19px">
            {value}
          </Typography>
        </BackSideCircle>
      </BackFace>
    </ImageInnerWrapper>
  </ImageOuterWrapper>
);

export const Text = styled("text")(({ theme }) => ({
  display: "none",

  [theme.breakpoints.down(700)]: {
    display: "flex",
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.paragraphMedium.fontWeight,
    fontSize: theme.typography.paragraphMedium.fontSize,
    lineHeight: "19px",
    color: theme.palette.grey[50],
  },
}));

const ImageInnerWrapper = styled("div")({
  position: "relative",
  transformStyle: "preserve-3d",
  transition: "transform 1s cubic-bezier(0.2, 0.885, 0.4, 1.275)",
  width: "100%",
  height: "100%",
});

const ImageOuterWrapper = styled("foreignObject")<{ hover: boolean }>(({ hover }) => ({
  overflow: "hidden",
  width: "86px",
  height: "75px",
  perspective: "800px",

  ...(hover && {
    "& > .card-inner-wrapper": {
      transform: "rotateY(180deg)",
    },
  }),
}));

const BackSideCircle = styled("div")<{ activeIndex: number; color: any }>(({ activeIndex, color }) => ({
  width: "75px",
  height: "75px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: color[activeIndex % color.length],
  borderRadius: "50%",
}));

const FrontFace = styled("div")({
  position: "relative",
  WebkitBackfaceVisibility: "hidden",
  backfaceVisibility: "hidden",
  zIndex: 0,

  "& > div": {
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
});

const BackFace = styled("div")({
  position: "absolute",
  width: "100%",
  height: "100%",
  left: "0",
  top: "0",
  WebkitBackfaceVisibility: "hidden",
  backfaceVisibility: "hidden",
  transform: "rotateY(180deg)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",

  "& > div": {
    height: "100%",
  },
});

const ImageWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: theme.palette.grey.A100,
  borderRadius: "50%",
  width: "75px",
  height: "75px",
  border: `5px solid ${theme.palette.grey.A100}`,
}));

export default CustomPieChartLabel;
