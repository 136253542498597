import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { getHydrated } from "../redux/reducers/appReducer";
import { getIsAuthorized } from "../redux/reducers/authReducer";

const withAuthRedirect = (Component: any) => {
  const Auth = (props: any) => {
    const navigate = useNavigate();

    const isHydrated = useSelector(getHydrated);
    const isAuthorized = useSelector(getIsAuthorized);

    useEffect(() => {
      if (isHydrated && isAuthorized) {
        navigate("/");
      }
    }, [isAuthorized, isHydrated]);

    return <Component {...props} />;
  };

  return Auth;
};

export default withAuthRedirect;
