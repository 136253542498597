import React, { useEffect, useState } from "react";
import { Button } from "@maestro-org/ui-kit";
import { styled, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import { getAllWebhooks } from "../../redux/reducers/webhooksReducer";
import { updatePopup } from "../../redux/actions/popupsActions";
import { getDeleteWebhookDialog, getPrefilled } from "../../redux/reducers/popupsReducer";

import Dialog from "./Dialog";
import { toast } from "react-toastify";

import { Popups } from "../../types/popups";
import { deleteWebhookServer } from "../../redux/actions/webhooksActions";

const DeleteWebhookDialog = () => {
  const [webhookName, setWebhookName] = useState("");

  const allWebhooks = useSelector(getAllWebhooks);
  const isOpen = useSelector(getDeleteWebhookDialog);
  const { webhookId, projectId } = useSelector(getPrefilled) || {};

  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(updatePopup({ popup: Popups.deleteWebhook, status: false }));
    setWebhookName("");
  };

  const handleDelete = () => {
    if (!webhookId) {
      toast.error("Error occurred while deleting webhook");
      return;
    }
    dispatch(deleteWebhookServer({ webhook_id: webhookId, project_id: projectId, prevWebhooks: allWebhooks }));
    toast.success("Successfully deleted webhook");
    handleClose();
  };

  useEffect(() => {
    if (!allWebhooks.length) return;
    const webhookName = allWebhooks.find((item) => item.webhook_id === webhookId)?.name;
    if (!webhookName) return;
    setWebhookName(webhookName);
  }, [allWebhooks, webhookId]);

  return (
    <Dialog open={isOpen}>
      <Wrapper>
        <Typography color="grey.A200" variant="h5">
          Delete {webhookName}
        </Typography>
        <Typography color="grey.A200" variant="paragraphMedium">
          Are you sure you want to delete this webhook? The action cannot be undone.
        </Typography>
        <Actions>
          <CancelButton onClick={handleClose}>Cancel</CancelButton>
          <Button onClick={handleDelete}>Delete</Button>
        </Actions>
      </Wrapper>
    </Dialog>
  );
};

const Wrapper = styled("div")({
  display: "flex",
  flexDirection: "column",
  rowGap: "40px",
});

const Actions = styled("div")({
  display: "flex",
  alignItems: "center",
  columnGap: "12px",
});

const CancelButton = styled(Button)(({ theme }) => ({
  background: theme.palette.grey["50"],
  color: theme.palette.grey.A200,

  "&:hover": {
    background: theme.palette.grey["50"],
    color: theme.palette.grey.A200,
  },
}));

export default DeleteWebhookDialog;
