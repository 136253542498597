import { toast } from "react-toastify";
import { takeEvery, put, select } from "redux-saga/effects";
import {
  LOGIN_SERVER,
  REGISTER_ACCOUNT_SERVER,
  RESET_PASSWORD_SERVER,
  setUpdatedUserPicture,
  updateUserData,
  updateIsLoadingAuth,
  updateIsUploadingImage,
  UPLOAD_IMAGE_SERVER,
  SEND_EMAIL_VERIFICATION_SERVER,
  UPDATE_USER_METADATA_SERVER,
  updateIsAuthorized,
  clearAuthData,
  GET_USER_AUTHENTICATORS_SERVER,
  deleteUserAuthenticatorsServer,
  DELETE_USER_AUTHENTICATORS_SERVER,
  updateUserMetadataServer,
  setIsUpdatingUserInfo,
  GET_USER_DATA,
  getUserDataServer,
} from "../actions/authActions";
import { getAuthData } from "../reducers/authReducer";
import { UserData } from "../../types/auth";

const authSagas = [
  takeEvery(REGISTER_ACCOUNT_SERVER, handleRegister),
  takeEvery(`${REGISTER_ACCOUNT_SERVER}_SUCCESS`, handleRegisterSuccess),
  takeEvery(`${REGISTER_ACCOUNT_SERVER}_FAIL`, handleRegisterFail),

  takeEvery(LOGIN_SERVER, handleLogin),
  takeEvery(`${LOGIN_SERVER}_SUCCESS`, handleLoginSuccess),
  takeEvery(`${LOGIN_SERVER}_FAIL`, handleLoginFail),

  takeEvery(RESET_PASSWORD_SERVER, handleResetPassword),
  takeEvery(`${RESET_PASSWORD_SERVER}_SUCCESS`, handleResetPasswordSuccess),
  takeEvery(`${RESET_PASSWORD_SERVER}_FAIL`, handleResetPasswordFail),

  takeEvery(UPLOAD_IMAGE_SERVER, handleUploadImageServer),
  takeEvery(`${UPLOAD_IMAGE_SERVER}_SUCCESS`, handleUploadImageServerSuccess),
  takeEvery(`${UPLOAD_IMAGE_SERVER}_FAIL`, handleUploadImageServerFail),

  takeEvery(`${SEND_EMAIL_VERIFICATION_SERVER}_SUCCESS`, handleSendEmailServerSuccess),
  takeEvery(`${SEND_EMAIL_VERIFICATION_SERVER}_FAIL`, handleSendEmailServerFail),

  takeEvery(`${UPDATE_USER_METADATA_SERVER}_SUCCESS`, handleUpdateMetadataServerSuccess),
  takeEvery(`${UPDATE_USER_METADATA_SERVER}_FAIL`, handleUpdateMetadataServerFail),

  takeEvery(`${GET_USER_AUTHENTICATORS_SERVER}_SUCCESS`, handleGetAuthenticatorsServerSuccess),
  takeEvery(`${GET_USER_AUTHENTICATORS_SERVER}_FAIL`, handleGetAuthenticatorsFail),

  takeEvery(`${DELETE_USER_AUTHENTICATORS_SERVER}_SUCCESS`, handleDeleteAuthenticatorsServerSuccess),
  takeEvery(`${DELETE_USER_AUTHENTICATORS_SERVER}_FAIL`, handleDeleteAuthenticatorsFail),

  takeEvery(`${GET_USER_DATA}_SUCCESS`, handleGetUserDatServerSuccess),
];

function* handleRegister() {
  yield put(updateIsLoadingAuth({ status: true }));
}

function* handleRegisterFail() {
  yield put(updateIsLoadingAuth({ status: false }));
  toast.error("Could not register. Try again later!");
}

function* handleRegisterSuccess() {
  yield put(updateIsLoadingAuth({ status: false }));
}

function* handleLogin() {
  yield put(updateIsLoadingAuth({ status: true }));
}

function* handleLoginFail() {
  yield put(updateIsLoadingAuth({ status: false }));
  toast.error("Could not log in. Try again later!");
}

function* handleLoginSuccess() {
  yield put(updateIsLoadingAuth({ status: false }));
}

function* handleResetPassword() {
  yield put(updateIsLoadingAuth({ status: true }));
}

function* handleResetPasswordFail() {
  yield put(updateIsLoadingAuth({ status: false }));
  toast.error("Could not reset your password. Try again later!");
}

function* handleResetPasswordSuccess() {
  yield put(updateIsLoadingAuth({ status: false }));
}

function* handleUploadImageServer() {
  yield put(updateIsUploadingImage({ status: true }));
}

function* handleUploadImageServerFail() {
  yield put(updateIsUploadingImage({ status: false }));
  toast.error("Could not change image");
}

function* handleUploadImageServerSuccess(action: any) {
  yield put(updateIsUploadingImage({ status: false }));
  yield put(setUpdatedUserPicture({ status: true }));
  // yield put(updateUserData({ picture: action.payload.data.data.url }));
  toast.success("New picture was successfully uploaded!");
}

function* handleSendEmailServerFail() {
  yield toast.error("Error while sending verification email");
}

function* handleSendEmailServerSuccess(action: any) {
  yield toast.success("Verification email sent successfully");
}

function* handleUpdateMetadataServerFail() {
  // yield toast.error("Error while updating metadata");
  yield put(setIsUpdatingUserInfo({ status: false }));
}

function* handleUpdateMetadataServerSuccess(action: any): any {
  const authData: UserData = yield select(getAuthData);
  const data = action.meta.previousAction.payload.request.data;
  yield put(setIsUpdatingUserInfo({ status: false }));
  const authUseMfa = authData?.user_metadata?.use_mfa;
  yield put(getUserDataServer());

  if ("use_mfa" in data && !authUseMfa) {
    yield put(updateIsAuthorized({ status: false }));
    yield put(clearAuthData());
    toast.info("Logging out to complete 2FA setup. Please login to resume");
  }
}

function* handleGetAuthenticatorsFail() {
  yield toast.error("Error while getting user authenticators");
}

function* handleGetAuthenticatorsServerSuccess(action: any): any {
  const data = action.payload.data;

  for (let i = 0; i < data.length; i++) {
    const elem = data[i];
    const isLastElem = i === data.length - 1;
    yield put(deleteUserAuthenticatorsServer({ authentication_method_id: elem.id, isLastElem }));
  }
}

function* handleDeleteAuthenticatorsFail() {
  yield toast.error("Error while deleting user authenticators");
}

function* handleDeleteAuthenticatorsServerSuccess(action: any): any {
  const isLastElem = action.meta.previousAction.payload.data.isLastElem;

  if (isLastElem) {
    yield put(updateUserMetadataServer({ use_mfa: false }));
  }
}

function* handleGetUserDatServerSuccess(action: any): any {
  yield put(updateUserData(action.payload.data));
}

export default authSagas;
