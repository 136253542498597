import { styled } from "@mui/material";
import React from "react";

interface Props {
  children: React.ReactNode;
}

const SettingsCard = ({ children }: Props) => <Wrapper>{children}</Wrapper>;

const Wrapper = styled("div")(({ theme }) => ({
  background: theme.palette.common.white,
  padding: "40px 32px",
  borderRadius: theme.borderRadius.sm,
  boxShadow: "0px 2px 12px rgba(153, 153, 153, 0.12)",
  display: "flex",
  flexDirection: "column",
  rowGap: "40px",
  // maxWidth: "708px",
  maxWidth: "654px",
  border: `1px solid ${theme.palette.grey[100]}`,

  [theme.breakpoints.down(1101)]: {
    maxWidth: "100%",
  },

  [theme.breakpoints.down("sm")]: {
    padding: "24px",
  },
}));

export default SettingsCard;
