import { Radio } from "@maestro-org/ui-kit";
import { styled, Typography } from "@mui/material";

import { SubscriptionPlan } from "../../../types/subscription";

import Divider from "../../../components/Divider/Divider";
import Tooltip from "../../../components/Tooltip/Tooltip";

import PlanPackagePros from "./PlanPackagePros";
import PlanPackageData from "./PlanPackageData";
import Switch from "../../../components/Switch/Switch";

import { InfoTooltipIcon } from "../../../components/Icons";

import { Package, TiersProps } from "../../../redux/reducers/stripeReducer";

import {
  getPlanIcon,
  getTooltipText,
  getSubscriptionPrice,
  gitDiscountPercentage,
} from "../../../lib/subscriptionPlanUtils";

const MILLION = 1000000 as const;

interface AdditionalProps {
  price_per_month?: number;
  isPlanSelected?: boolean;
  isVirtuosoAnnual: boolean;
  subscriptionTiers?: TiersProps;
  handleToogleSwitch: () => void;
  handleChangeActive: (id: string) => void;
}

const PlanPackage = ({
  id,
  name,
  credits_per_month,
  features,
  isPlanSelected,
  webhook_limit,
  project_limit,
  throughput_limit,
  isVirtuosoAnnual,
  subscriptionTiers,
  credits_per_day,
  handleToogleSwitch,
  handleChangeActive,
}: Package & AdditionalProps) => {
  const isVirtuoso = name === SubscriptionPlan.virtuoso;
  const subscriptionPrice = getSubscriptionPrice(name, isVirtuosoAnnual, subscriptionTiers);
  const { price, discount } = subscriptionPrice;

  const handleButtonClick = () => {
    handleChangeActive(id);
  };

  return (
    <OutterWrapper onClick={handleButtonClick} isActive={isPlanSelected}>
      {isVirtuoso && (
        <ToogleWrapper isChecked={isVirtuosoAnnual}>
          <ToogleLeft>
            <Switch checked={isVirtuosoAnnual} handleChange={handleToogleSwitch} />
            <SwitchText isChecked={isVirtuosoAnnual}>
              {isVirtuosoAnnual ? "Billed yearly" : "Billed monthly"}
            </SwitchText>
          </ToogleLeft>
          {isVirtuosoAnnual && <SwitchText isChecked>Save {gitDiscountPercentage({ price, discount })}%</SwitchText>}
        </ToogleWrapper>
      )}
      <PackageWrapper isVirtuoso={isVirtuoso}>
        <Top>
          <TitleWrapper>
            <WithIcon>
              {getPlanIcon[name]}
              <Typography color="textColor.dark" variant="subtitle1">
                {name}
              </Typography>
            </WithIcon>
            <ActionWrapper>
              <StyledRadio checked={isPlanSelected} value="" label="" handleChange={console.log} />
            </ActionWrapper>
          </TitleWrapper>
          <PriceWrapper>
            {discount && <Discount variant="chartTitle">{discount}</Discount>}
            <Typography color="textColor.dark" variant="chartTitle">
              {price}
            </Typography>
          </PriceWrapper>
          <CreditsWrapper>
            <CreditsPerMonth color="textColor.lightGray">
              {credits_per_month / MILLION}M credits a month
            </CreditsPerMonth>
            <StyledTooltip
              enterTouchDelay={0}
              title={<TooltipText>{getTooltipText(name, credits_per_day, credits_per_month)}</TooltipText>}
            >
              <InfoIconWrapper>
                <InfoTooltipIcon />
              </InfoIconWrapper>
            </StyledTooltip>
          </CreditsWrapper>
        </Top>
        <PlanInfoWrapper>
          <PackageDividerWrapper>
            <Divider color="light" />
          </PackageDividerWrapper>
          <PlanPackageData
            throughputLimit={throughput_limit}
            webhookLimit={webhook_limit}
            projectLimit={project_limit}
          />
          <PlanPackagePros pros={features} planName={name} />
        </PlanInfoWrapper>
      </PackageWrapper>
    </OutterWrapper>
  );
};

const CreditsPerMonth = styled(Typography)({
  fontSize: "16px",
  fontWeight: "300",
  lineHeight: "24px",
});

const PriceWrapper = styled("div")({
  display: "flex",
  alignItems: "center",
  gap: "12px",
  minHeight: "34px",
});

const TooltipText = styled(Typography)(() => ({
  color: "#333333",
  fontSize: "12px",
  fontWeight: "400",
  lineHeight: "16px",
}));

const PackageWrapper = styled("div")<{ isVirtuoso: boolean }>(({ isVirtuoso, theme }) => ({
  position: "relative",
  display: "flex",
  flexDirection: "column",
  padding: "32px",
  minHeight: "665px",
  minWidth: "377px",
  flex: 1,

  borderRadius: isVirtuoso ? "0px 0px 12px 12px" : theme.borderRadius.sm,
  background: theme.palette.common.white,
}));

const StyledRadio = styled(Radio)({
  padding: 0,
  cursor: "default",
});

const Top = styled("div")({
  display: "flex",
  flexDirection: "column",
  rowGap: "8px",
});

const PlanInfoWrapper = styled("div")({
  display: "flex",
  gap: "32px",
  flexDirection: "column",
});

const CreditsWrapper = styled("div")({
  display: "flex",
  alignItems: "center",
  columnGap: "8px",
});

const InfoIconWrapper = styled("div")({
  height: "16px",
});

const StyledTooltip = styled(Tooltip)(({ theme }) => ({
  "& .MuiTooltip-tooltip": {
    maxWidth: "236px",
    padding: "8px 16px",
    boxShadow: "0px 2px 16px -2px rgba(15, 3, 17, 0.16)",
    border: `1px solid ${theme.palette.grey[100]}`,
    display: "flex",
    alignItems: "center",
    columnGap: "9px",
    background: "#FFFFFF",
  },
}));

const TitleWrapper = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  paddingBottom: "16px",

  "& .MuiFormControlLabel-root": {
    margin: 0,
  },
});

const WithIcon = styled("div")({
  display: "flex",
  alignItems: "center",
  columnGap: "8px",

  "& svg": {
    width: "32px",
    height: "32px",
  },
});

const PackageDividerWrapper = styled("div")({
  padding: "20px 0 0",
});

const ActionWrapper = styled("div")({
  display: "flex",
  alignItems: "center",
  gap: "14px",
});

const Discount = styled(Typography)({
  color: "#CCC",
  textDecoration: "line-through",
});

const OutterWrapper = styled("div")<{ isActive?: boolean }>(({ isActive, theme }) => ({
  display: "flex",
  flexDirection: "column",
  marginTop: "auto",
  borderRadius: theme.borderRadius.sm,
  boxShadow: "0px 2px 12px rgba(153, 153, 153, 0.12)",
  border: isActive ? `1px solid ${theme.palette.primary.main}` : `1px solid ${theme.palette.grey[100]}`,
  transition: "border-color 0.3s",

  "&:hover": {
    borderColor: theme.palette.contrastText.main,
  },
}));

const ToogleWrapper = styled("div")<{ isChecked: boolean }>(({ isChecked }) => ({
  display: "flex",
  padding: "12px",
  alignItems: "center",
  justifyContent: "space-between",
  borderRadius: "12px 12px 0px 0px",
  backgroundColor: isChecked ? "#F7E6FA" : "#FAF9FB",
  transition: "background-color 0.3s ease-in-out",
}));

const ToogleLeft = styled("div")({
  display: "flex",
  alignItems: "center",
  gap: "10px",
});

const SwitchText = styled(Typography)<{ isChecked: boolean }>(({ theme, isChecked }) => ({
  fontSize: "16px",
  fontWeight: "400",
  lineHeight: "24px",
  color: isChecked ? theme.palette.primary.main : theme.palette.social.dark,
  transition: "color 0.3s ease-in-out",
}));

export default PlanPackage;
