export const WebhooksIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18" fill="none">
    <path
      d="M3.17188 8.68959C3.41788 7.25259 4.15387 5.98259 5.20287 5.05859"
      stroke="#C53DD8"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.4919 8.68959C15.2459 7.25259 14.5099 5.98259 13.4609 5.05859"
      stroke="#C53DD8"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.332 15.6719C10.704 15.8839 10.031 15.9989 9.33203 15.9989C8.63303 15.9989 7.96003 15.8839 7.33203 15.6719"
      stroke="#C53DD8"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.33203 5.75C10.4366 5.75 11.332 4.85457 11.332 3.75C11.332 2.64543 10.4366 1.75 9.33203 1.75C8.22746 1.75 7.33203 2.64543 7.33203 3.75C7.33203 4.85457 8.22746 5.75 9.33203 5.75Z"
      stroke="#C53DD8"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.13672 14.75C5.24129 14.75 6.13672 13.8546 6.13672 12.75C6.13672 11.6454 5.24129 10.75 4.13672 10.75C3.03215 10.75 2.13672 11.6454 2.13672 12.75C2.13672 13.8546 3.03215 14.75 4.13672 14.75Z"
      stroke="#C53DD8"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.5273 14.75C15.6319 14.75 16.5273 13.8546 16.5273 12.75C16.5273 11.6454 15.6319 10.75 14.5273 10.75C13.4228 10.75 12.5273 11.6454 12.5273 12.75C12.5273 13.8546 13.4228 14.75 14.5273 14.75Z"
      stroke="#C53DD8"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
