export const SuccessCopiedIcon = () => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.5 6.99831C13.5 10.4708 10.6029 13.3091 7 13.3091C3.39715 13.3091 0.5 10.4708 0.5 6.99831C0.5 3.52577 3.39715 0.6875 7 0.6875C10.6029 0.6875 13.5 3.52577 13.5 6.99831Z"
      stroke="#53954A"
    />
    <path
      d="M6.49553 9.64939L4.30231 7.46659C4.10144 7.23315 4.11495 6.88518 4.33331 6.66786C4.55167 6.45054 4.9013 6.4371 5.13585 6.63701L6.48962 7.98434L9.61689 4.87194C9.85144 4.67204 10.2011 4.68548 10.4194 4.9028C10.6378 5.12012 10.6513 5.46809 10.4504 5.70153L6.49553 9.64939Z"
      fill="#53954A"
    />
  </svg>
);
