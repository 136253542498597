import * as React from "react";
import {
  Drawer as MuiDrawer,
  DrawerProps as MuiDrawerProps,
  List as MuiList,
  ListItem,
  styled,
  ListItemButton as MuiListItemButton,
  Typography,
  useTheme,
} from "@mui/material";

import IconButton from "../Button/IconButton";
import Logo, { LogoStyles } from "../Logo/Logo";
import Button from "../Button/Button";
import { CloseDrawerIcon } from "../Icons";

interface DrawerCustomProps {
  openTrigger: React.ReactNode;
  mobileSpacing: number;
  children: React.ReactNode;
  toggleDrawer: (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => void;
}

type DrawerProps = DrawerCustomProps & MuiDrawerProps;

const Drawer = ({ children, openTrigger, toggleDrawer, mobileSpacing, ...props }: DrawerProps) => {
  const theme = useTheme();

  return (
    <>
      {openTrigger}
      <StyledDrawer anchor="right" onClose={toggleDrawer(false)} {...props}>
        <Top mobileSpacing={mobileSpacing}>
          <LogoWrapper>
            <Logo style={theme.palette.mode === "light" ? LogoStyles.Dark : LogoStyles.WithText} />
          </LogoWrapper>
          <IconButton onClick={toggleDrawer(false)}>
            <CloseDrawerIcon />
          </IconButton>
        </Top>
        {children}
      </StyledDrawer>
    </>
  );
};

const StyledDrawer = styled(MuiDrawer)(({ theme }) => ({
  "& .MuiDrawer-paper": {
    width: "100%",
    overflow: "hidden",
    background: theme.palette.grey[50],
  },
}));

const Top = styled<any>("div")(({ mobileSpacing, theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  padding: "26px 24px",
  alignItems: "center",
  marginTop: mobileSpacing + "px",
  borderBottom: `1px solid ${theme.palette.grey[100]}`,
}));

const LogoWrapper = styled("div")({
  position: "relative",
  width: "166px",
  height: "36px",
});

const UpList = styled(MuiList)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  rowGap: "30px",
  flex: 1,

  "& .MuiListItem-root": {
    padding: 0,
  },
});

const DownList = styled(MuiList)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "left",
  padding: "0 20px 32px",
  rowGap: "12px",
});

const ListItemButton = styled(MuiListItemButton)({
  justifyContent: "center",
  padding: 0,
});

export default Drawer;
