import { styled, Typography } from "@mui/material";
import { format } from "date-fns";

const CustomTooltip = ({ active, payload, units = "", measure = "Requests" }: any) => {
  if (!active || !payload) return null;

  const isEmptyValue = payload[0].payload.emptyValue;
  if (isEmptyValue) return null;

  if (active && payload && payload.length) {
    return (
      <Wrapper padding="16px" minWidth="189px">
        <Typography variant="paragraphSmall" color="textColor.tooltipText">
          {/* {format(payload[0].payload.date, "dd/MM/yyyy hh:mm:ss a")} */}
          {payload[0].payload.date}
        </Typography>
        <TextBlock>
          <Row>
            <Circle />
            <Typography variant="tooltipText" color="textColor.tooltipText">
              {measure}
            </Typography>
          </Row>
          <Typography variant="tooltipText" color="primary.main">
            {`${payload[0].payload.value} ${units}`}
          </Typography>
        </TextBlock>
      </Wrapper>
    );
  }

  return (
    <Wrapper padding="16px" minWidth="189px">
      <Typography variant="paragraphSmall" color="textColor.tooltipText">
        {/* {format(payload[0].payload.date, "dd/MM/yyyy hh:mm:ss a")} */}
        {payload[0].payload.date}
      </Typography>
      <TextBlock>
        <Row>
          <Circle />
          <Typography variant="tooltipText" color="textColor.tooltipText">
            {units}
          </Typography>
        </Row>
        <Typography variant="tooltipText" color="primary.main">
          {payload[0].payload.value}
        </Typography>
      </TextBlock>
    </Wrapper>
  );
};

export const Wrapper = styled("div")<{ padding: string; minWidth: string }>(({ theme, padding, minWidth }) => ({
  backgroundColor: theme.palette.tooltip.background,
  boxShadow: "0px 2px 16px -2px rgba(15, 3, 17, 0.16)",
  borderRadius: "6px",
  border: "1px solid #e6e6e6",
  outline: "none",
  padding,
  minWidth,
  maxWidth: "100%",
  width: "100%",
  display: "flex",
  flexDirection: "column",
  gap: "12px",
}));

const Circle = styled("div")(({ theme }) => ({
  width: "6px",
  height: "6px",
  backgroundColor: theme.palette.primary.main,
  borderRadius: "50%",
  flexShrink: 0,
}));

const TextBlock = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
});

const Row = styled("div")({
  display: "flex",
  alignItems: "center",
  gap: "8px",
});

export default CustomTooltip;
