import { FC } from "react";
import { styled, Typography } from "@mui/material";

import { SubscriptionPlan } from "../../../types/subscription";

import { getPlanDescription } from "../../../lib/subscriptionPlanUtils";

interface Props {
  pros: string[];
  planName: SubscriptionPlan;
}

const PlanPackagePros: FC<Props> = ({ pros, planName }) => {
  return (
    <Wrapper>
      <DescriptionWrapper>
        <Description color="textColor.dark">{getPlanDescription(planName)}</Description>
      </DescriptionWrapper>
      <PackageDetailsWrapper>
        {pros.map((elem) => (
          <ItemWrapper key={elem}>
            <Typography color="contrastText.main">✓</Typography>
            <Feature color="textColor.dark">{elem}</Feature>
          </ItemWrapper>
        ))}
      </PackageDetailsWrapper>
    </Wrapper>
  );
};

const Wrapper = styled("div")({
  display: "flex",
  flexDirection: "column",
  gap: "20px",
});

const DescriptionWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  width: "100%",
  borderRadius: theme.borderRadius.xxs,
  padding: "8px 16px",
  backgroundColor: "#FAF9FB",
}));

const PackageDetailsWrapper = styled("div")({
  display: "flex",
  flexDirection: "column",
  rowGap: "12px",
});

const ItemWrapper = styled("div")({
  display: "flex",
  alignItems: "center",
  gap: "5px",
});

const Feature = styled(Typography)({
  fontSize: "16px",
  fontWeight: "300",
  lineHeight: "24px",
});

const Description = styled(Typography)({
  fontSize: "14px",
  fontWeight: "400",
  lineHeight: "20px",
});

export default PlanPackagePros;
