import { styled } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";

import { ITeamUser } from "../../../../redux/actions/teamsActions";

interface Props {
  user: ITeamUser;
}

const MemberImage = ({ user }: Props) => {
  const picture = user.avatar;
  const email = user.email;

  return picture ? <Image src={picture} /> : <NoImageWrapper>{email ? `${email[0] + email[1]}` : ""}</NoImageWrapper>;
};

const Image = styled("img")({
  borderRadius: "50%",
  width: "28px",
  height: "28px",
  marginRight: "8px",
  objectFit: "cover",
});

const NoImageWrapper = styled("div")(({ theme }) => ({
  borderRadius: "50%",
  width: "28px",
  height: "28px",
  marginRight: "8px",
  border: "1px solid #999999",
  borderStyle: "dashed",

  background: theme.palette.grey[100],
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  textTransform: "uppercase",
  fontWeight: 200,
  fontSize: "11px",
  color: theme.palette.grey.A200,
}));

export default MemberImage;
