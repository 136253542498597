import { styled, Typography } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "@maestro-org/ui-kit";

import { updatePopup } from "../../redux/actions/popupsActions";
import { getDeleteAccountDialog } from "../../redux/reducers/popupsReducer";
import { deleteUserServer } from "../../redux/actions/userActions";

import Dialog from "./Dialog";

import { Popups } from "../../types/popups";

const DeleteAccountDialog = () => {
  const isOpen = useSelector(getDeleteAccountDialog);

  const dispatch = useDispatch();

  const handleClose = () => dispatch(updatePopup({ popup: Popups.deleteAccount, status: false }));

  const handleDelete = () => {
    dispatch(deleteUserServer());
    handleClose();
  };

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <Wrapper>
        <Typography color="grey.A200" variant="h5">
          Delete Account
        </Typography>
        <Typography color="grey.A200" variant="paragraphMedium">
          Deleting your account will permanently delete all of your projects and webhooks. Once you confirm deletion,
          this process cannot be undone.
          <br />
          <br />
          Are you sure you want to delete your account?
        </Typography>
        <Actions>
          <CancelButton onClick={handleClose}>Cancel</CancelButton>
          <Button onClick={handleDelete}>Delete</Button>
        </Actions>
      </Wrapper>
    </Dialog>
  );
};

const Wrapper = styled("div")({
  display: "flex",
  flexDirection: "column",
  rowGap: "40px",
});

const Actions = styled("div")({
  display: "flex",
  alignItems: "center",
  columnGap: "12px",
});

const CancelButton = styled(Button)(({ theme }) => ({
  borderRadius: theme.borderRadius.sm,
  background: theme.palette.grey[50],
  color: theme.palette.grey["A200"],

  "&:hover": {
    background: theme.palette.grey[50],
    color: theme.palette.grey["A200"],
  },
}));

export default DeleteAccountDialog;
