import { Blockchain, Network } from "../../types/project";

export interface CreateProjectFormValues {
  name: string;
  network: Network[];
  blockchain: Blockchain[];
}

export enum CreateProjectFields {
  name = "name",
  network = "network",
  blockchain = "blockchain",
}
