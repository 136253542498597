import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { styled, Typography } from "@mui/material";

import MainNavigation, { NavItems } from "../../components/Navigation/MainNavigation";
import Footer from "./components/Footer";
import Projects from "./components/Projects";
import TotalRequestVolume from "./components/TotalRequestVolume";
import Tips from "./components/Tips";
import Widgets from "./components/Widgets";
import { BuildTogetherTipIcon, DocumentationIcon, IntroduceTipIcon, TransactionsTipIcon } from "../../components/Icons";

import withPrivateRoute from "../../hooks/withPrivateRoute";
import { getUserDataServer } from "../../redux/actions/authActions";

const Mainpage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (window.location.search.includes("loginSuccess")) {
      window.history.replaceState({}, "", window.location.origin);
    }
  }, [location]);

  useEffect(() => {
    dispatch(getUserDataServer());
  }, []);

  return (
    <Wrapper>
      <MainNavigation actions={<MainPageNavActions />} activeItem={NavItems.dashboard} />
      <Widgets />
      <Projects />
      <TotalRequestVolumeWrapper>
        <TotalRequestVolume />
      </TotalRequestVolumeWrapper>
      <TipsWrapper>
        <Tips tips={tips} />
      </TipsWrapper>
      <Footer />
    </Wrapper>
  );
};

const Wrapper = styled("div")({
  display: "flex",
  flexDirection: "column",
});

const TotalRequestVolumeWrapper = styled("div")(({ theme }) => ({
  padding: "104px 0",

  [theme.breakpoints.down("lg")]: {
    padding: "80px 0 81px",
  },

  [theme.breakpoints.down("sm")]: {
    padding: "72px 0",
  },
}));

const TipsWrapper = styled("div")(({ theme }) => ({
  paddingBottom: "120px",

  [theme.breakpoints.down("lg")]: {
    paddingBottom: "80px",
  },

  [theme.breakpoints.down("sm")]: {
    paddingBottom: "120px",
  },
}));

const MainPageNavActions = () => (
  <>
    <a href="https://docs.gomaestro.org/" target="_blank" rel="noreferrer">
      <DocumentationWrapper>
        <DocumentationIcon />
        <Typography variant="paragraphMedium" color="textColor.dark">
          Documentation
        </Typography>
      </DocumentationWrapper>
    </a>
  </>
);

const DocumentationWrapper = styled("div")({
  display: "flex",
  alignItems: "center",
  columnGap: "8px",
});

const tips = [
  {
    title: "Let’s build together",
    subtitle:
      "Checkout Maestro's quickstart guide and tutorials. Learn how to harness Maestro's UTxO-optimized APIs to power your application.",
    linkText: "Start building with Maestro",
    href: "https://docs.gomaestro.org/",
    icon: <BuildTogetherTipIcon />,
  },
  {
    title: "We value your feedback",
    subtitle:
      "Maestro is committed to product excellence and deliver a premium experience to our users. We want to understand your needs and product usage. We also accept feature requests.",
    linkText: "Take a survey",
    href: "https://0lsvix7t90t.typeform.com/to/EZJ4sM8m",
    icon: <IntroduceTipIcon />,
  },
  {
    title: "Transactions",
    subtitle:
      "Monitor your dapp transaction states in real-time with Maestro's transaction management system. Never lose sight of a transaction again.",
    linkText: "Manage your transactions",
    link: "/transactions#navigation",
    icon: <TransactionsTipIcon />,
  },
];

export default withPrivateRoute(Mainpage);
