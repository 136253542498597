import * as Yup from "yup";

import { CreateTransactionFields } from "./types";

export const createTransactionSchema = Yup.object().shape({
  [CreateTransactionFields.project]: Yup.array().min(1, "Project is required"),
  [CreateTransactionFields.url]: Yup.string()
    .matches(
      /\b((?:https?:\/\/|ftp:\/\/|www\d{0,3}[.]|[a-z0-9.\-]+[.][a-z]{2,4}\/)(?:[^\s()<>]+|\(([^\s()<>]+|(\([^\s()<>]+\)))*\))+(?:\(([^\s()<>]+|(\([^\s()<>]+\)))*\)|[^\s`!()\[\]{};:'".,<>?«»“”‘’]))/i,
      "Invalid webhook URL",
    )
    .max(100, "URL must be 32 characters or less")
    .required("URL is required"),
  [CreateTransactionFields.webhookName]: Yup.string()
    .max(32, "Webhook name must be 32 characters or less")
    .required("Webhook name is required"),
});
