import { UserCredentialsFields } from "./types";

export const initialValuesUserCredentials = {
  [UserCredentialsFields.email]: "",
};

export const getUserCredentialsFields = [
  {
    type: "email",
    name: UserCredentialsFields.email,
    label: "Email address",
    placeholder: "Enter email",
    fieldLabel: "Email Address",
  },
];
