export const LockIcon = () => (
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_6052_358)">
      <path
        d="M19.2526 9.16314H16.5026V5.50564C16.5148 4.05823 15.9522 2.66513 14.9381 1.63221C13.9241 0.599298 12.5417 0.0109935 11.0943 -0.00352669H11.0118C10.2944 -0.0119969 9.58246 0.121375 8.91681 0.388925C8.25117 0.656475 7.64495 1.05293 7.13301 1.5555C6.62107 2.05808 6.21348 2.65686 5.93368 3.31746C5.65388 3.97805 5.50739 4.68742 5.5026 5.40481V9.16314H2.7526C2.50949 9.16314 2.27633 9.25972 2.10442 9.43163C1.93251 9.60353 1.83594 9.83669 1.83594 10.0798V21.0798C1.83594 21.3229 1.93251 21.5561 2.10442 21.728C2.27633 21.8999 2.50949 21.9965 2.7526 21.9965H19.2526C19.4957 21.9965 19.7289 21.8999 19.9008 21.728C20.0727 21.5561 20.1693 21.3229 20.1693 21.0798V10.0798C20.1693 9.83669 20.0727 9.60353 19.9008 9.43163C19.7289 9.25972 19.4957 9.16314 19.2526 9.16314ZM11.0026 17.4131C10.64 17.4131 10.2855 17.3056 9.98406 17.1042C9.68257 16.9027 9.44759 16.6164 9.30883 16.2814C9.17006 15.9464 9.13376 15.5778 9.2045 15.2221C9.27524 14.8665 9.44985 14.5398 9.70624 14.2834C9.96264 14.027 10.2893 13.8524 10.6449 13.7817C11.0006 13.711 11.3692 13.7473 11.7042 13.886C12.0392 14.0248 12.3255 14.2598 12.527 14.5613C12.7284 14.8628 12.8359 15.2172 12.8359 15.5798C12.8359 16.066 12.6428 16.5324 12.299 16.8762C11.9551 17.22 11.4888 17.4131 11.0026 17.4131ZM14.6693 9.16314H7.33594V5.41397C7.34728 4.45913 7.73469 3.54728 8.4141 2.87628C9.09351 2.20528 10.0101 1.82926 10.965 1.82981H11.0851C12.0465 1.84117 12.964 2.23376 13.6361 2.9213C14.3082 3.60884 14.6798 4.53508 14.6693 5.49647V9.16314Z"
        fill="#B3B3B3"
      />
    </g>
    <defs>
      <clipPath id="clip0_6052_358">
        <rect width="22" height="22" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
