import { ITeamUser } from "../redux/actions/teamsActions";

export enum UserRoles {
  OWNER = "owner",
  ADMIN = "admin",
  DEVELOPER = "developer",
  MEMBER = "member",
}

export enum UserStatus {
  ACTIVE = "active",
  PENDING = "pending",
  INACTIVE = "inactive",
}

export const getRoleLevel = (roleName: UserRoles): number => {
  switch (roleName) {
    case UserRoles.OWNER:
      return 1;
    case UserRoles.ADMIN:
      return 2;
    case UserRoles.DEVELOPER:
      return 3;
    case UserRoles.MEMBER:
      return 4;
    default:
      throw new Error(`Unknown role: ${roleName}`);
  }
};

export const removeTeamMemberByEmail = (users: ITeamUser[], email: string): ITeamUser[] => {
  return users.filter((user) => user.email !== email);
};

export const createNewTeamMember = (email: string, role: UserRoles): ITeamUser => {
  const roleLevel = getRoleLevel(role);

  return {
    email,
    role_level: roleLevel,
    role_name: role,
    status: "active",
    use_mfa: false,
  };
};
