export const VirtuosoIcon = () => (
  <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.85 10.74V9L21.7 0H15.35L10.85 5.16L6.35 0H0L7.85 9V10.74C5.77 11.82 4.35 13.99 4.35 16.5C4.35 20.09 7.26 23 10.85 23C14.44 23 17.35 20.09 17.35 16.5C17.35 13.99 15.93 11.82 13.85 10.74ZM15.8 1H19.5L13.36 8.04L11.51 5.92L15.8 1ZM2.2 1H5.9L12.73 8.83H9.03L2.2 1ZM10.85 22C7.82 22 5.35 19.53 5.35 16.5C5.35 13.47 7.82 11 10.85 11C13.88 11 16.35 13.47 16.35 16.5C16.35 19.53 13.88 22 10.85 22Z"
      fill="#C53DD8"
    />
    <path
      d="M10.8503 12.3281L9.90031 15.2581H6.82031L9.31031 17.0681L8.36031 19.9981L10.8503 18.1881L13.3403 19.9981L12.3903 17.0681L14.8803 15.2581H11.8003L10.8503 12.3281Z"
      fill="#C53DD8"
    />
  </svg>
);
