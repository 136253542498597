import { EntryType } from "./table";

export interface Transaction {
  timestamp: string;
  state: TransactionMonitoringState;
  transaction_hash: string;
  turbo_tx: boolean;
  project_name: string;
  block: string;
  confirmations: string;
  webhook: string;
  info: string;
}

export interface IRow {
  id: number;
  heading: EntryType;
  data: EntryType;
  details?: string;
  isLoading?: boolean;
  turboTX: boolean;
}

export enum TransactionMonitoringState {
  onchain = "Onchain",
  pending = "Pending",
  rolledback = "Rolledback",
  rejected = "Rejected",
  failed = "Failed",
}

export enum WebhookStatus {
  active = "active",
  paused = "paused",
  // deleted = "deleted",
}

export enum WebhookActions {
  pause = "pause",
  unpause = "unpause",
}

export interface RowData {
  submitTime: string;
  state: TransactionMonitoringState;
  txHash: string;
  txHashFull: string;
  project: string;
  block: string;
  confirmations: string;
  webhook: string;
}

export interface HeadingData {
  submitTime: string;
  state: string;
  txHash: string;
  project: string;
  block: string;
  confirmations: string;
  webhook: string;
}

type Row = Omit<IRow, "data" | "heading"> & { data: RowData; heading: HeadingData };

export type RowType = IRow & Row;

export enum RangeType {
  twentyFourHours = "24 hours",
  twelveHours = "12 hours",
  sixHours = "6 hours",
  oneHour = "1 hour",
  thirtyMins = "30 mins",
  fiveMins = "5 mins",
}

export const formattedRangeTypes = {
  [RangeType.twentyFourHours]: "24h",
  [RangeType.twelveHours]: "12h",
  [RangeType.sixHours]: "6h",
  [RangeType.oneHour]: "1h",
  [RangeType.thirtyMins]: "30m",
  [RangeType.fiveMins]: "5m",
};
