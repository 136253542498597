import React from "react";
import { styled, Typography } from "@mui/material";
import { Link } from "react-router-dom";

import { PathElement } from "../../types/breadcrumpsPathTypes";

interface Props {
  path: PathElement[];
}

const Breadcrumps = ({ path }: Props) => (
  <Wrapper>
    {path.map(({ title, href }, index) => (
      <>
        <StyledLink to={href}>
          <Typography color="grey.500" variant="paragraphMedium">
            {title}
          </Typography>
        </StyledLink>
        {index < path.length - 1 && (
          <Typography color="grey.500" variant="paragraphMedium">
            /
          </Typography>
        )}
      </>
    ))}
  </Wrapper>
);

const Wrapper = styled("div")(({ theme }) => ({
  display: "flex",
  columnGap: "8px",
}));

const StyledLink = styled(Link)(({ theme }) => ({
  textDecoration: "none",

  "&:last-child .MuiTypography-root": {
    color: theme.palette.grey.A200,
  },
}));

export default Breadcrumps;
