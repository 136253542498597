export const EditIcon = () => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8 3.32812L10.6667 5.99479" stroke="#0F0311" strokeWidth="1.71429" strokeMiterlimit="10" />
    <path
      d="M4.66536 11.9948L1.33203 12.6615L1.9987 9.32812L9.9987 1.32812L12.6654 3.99479L4.66536 11.9948Z"
      stroke="#0F0311"
      strokeWidth="1.71429"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
  </svg>
);
