import { styled, Typography } from "@mui/material";
import React from "react";

import Divider from "../../../components/Divider/Divider";
import Tooltip from "../../../components/Tooltip/Tooltip";
import { LockIcon } from "../../../components/Icons";

interface MultiplierCardProps {
  multiplierValue?: number;
  handleMultiplierChangeUp?: (event: React.MouseEvent<HTMLElement>) => void;
  handleMultiplierChangeDown?: (event: React.MouseEvent<HTMLElement>) => void;
  handleMultiplierInputChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

interface CardProps extends MultiplierCardProps {
  title: string;
  subtitle?: string;
  description: string[];
  disabled?: boolean;
  availableIn?: string[];
  selected?: boolean;
  handleSelectedChange?: (title?: string) => void;
  icon?: React.ReactNode;
  blockchains: string[];
}

const PlanSettingsCard = ({
  title,
  subtitle,
  description,
  disabled,
  availableIn,
  selected,
  icon,
  blockchains,
}: CardProps) => {
  const availableInText = availableIn?.reduce(
    (acc, item, index) =>
      (acc += `${item}${index !== availableIn.length - 1 ? (index === availableIn.length - 2 ? " and " : ", ") : ""}`),
    "",
  );

  return (
    <StyledTooltip
      disabled={disabled}
      title={
        <>
          <LockIcon />
          <Typography variant="article" color="grey.800">
            Available in {availableInText}
          </Typography>
        </>
      }
      followCursor
      placement="top"
    >
      <Wrapper isActive={selected || !disabled}>
        <CardHeader disabled={disabled}>
          <Top>
            <TitleWrapper disabled={disabled}>
              <LeftTitleWrapper>
                {icon}
                <Title color={disabled ? "grey.300" : "grey.A200"}>{title}</Title>
              </LeftTitleWrapper>
              <ImagesWrapper disabled={disabled}>
                {blockchains.map((chain) => (
                  <BlockchainWrapper key={chain} src={`/images/blockchains/${chain}.png`} />
                ))}
              </ImagesWrapper>
            </TitleWrapper>
            {subtitle && (
              <Typography color={disabled ? "grey.300" : "grey.A200"} variant="h6">
                {typeof subtitle === "string" ? subtitle : `+ $${subtitle}/mo`}
              </Typography>
            )}
          </Top>
        </CardHeader>
        <DividerWrapper>
          <Divider />
        </DividerWrapper>
        <DescriptionWrapper>
          {description.map((item) => (
            <StyledListItem key={item} disabled={disabled}>
              <Typography color={disabled ? "grey.300" : "grey.A200"} variant="paragraphMedium">
                {item}
              </Typography>
            </StyledListItem>
          ))}
        </DescriptionWrapper>
      </Wrapper>
    </StyledTooltip>
  );
};

const Wrapper = styled("div")<{ isActive?: boolean }>(({ theme, isActive }) => ({
  borderRadius: theme.borderRadius.sm,
  boxShadow: "0px 2px 12px rgba(153, 153, 153, 0.12)",
  background: theme.palette.common.white,
  display: "flex",
  flexDirection: "column",
  border: `2px solid ${theme.palette.grey[100]}`,
  transition: "all 0.2s ease",

  ...(isActive && {
    "&:hover": {
      border: `2px solid ${theme.palette.primary.main}`,
    },
  }),
}));

const Title = styled(Typography)(({ theme }) => ({
  fontSize: "24px",
  lineHeight: "28px",
  fontWeight: "400",

  [theme.breakpoints.down("sm")]: {
    fontSize: "20px",
    lineHeight: "24px",
  },
}));

const CardHeader = styled("div")<{ disabled?: boolean }>(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: "32px 32px 24px",

  "& .MuiFormControlLabel-root": {
    margin: 0,
  },

  [theme.breakpoints.down("sm")]: {
    padding: "24px",
  },
}));

const ImagesWrapper = styled("div")<{ disabled?: boolean }>(({ disabled }) => ({
  display: "flex",
  filter: disabled ? "grayscale(100%)" : "none",
  paddingLeft: "10px",
}));

const BlockchainWrapper = styled("img")({
  width: "40px",
  height: "40px",
  marginLeft: "-10px",
});

const StyledTooltip = styled(Tooltip)<{ disabled?: boolean }>(({ theme, disabled }) => ({
  ...(!disabled && { display: "none" }),
  "& .MuiTooltip-tooltip": {
    maxWidth: "236px",
    padding: "8px 16px",
    boxShadow: "0px 2px 16px -2px rgba(15, 3, 17, 0.16)",
    border: `1px solid ${theme.palette.grey[100]}`,
    display: "flex",
    alignItems: "center",
    columnGap: "9px",
  },

  "& svg": {
    width: "13px",
    height: "16px",
  },
}));

const Top = styled("div")({
  display: "flex",
  flexDirection: "column",
  rowGap: "8px",
  width: "100%",
});

const TitleWrapper = styled("div")<{ disabled?: boolean }>(({ theme, disabled }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  width: "100%",

  "& svg": {
    flexShrink: "0",

    "& path": {
      fill: disabled ? theme.palette.grey[300] : theme.palette.primary.main,
    },
  },
}));

const LeftTitleWrapper = styled("div")({
  display: "flex",
  alignItems: "center",
  columnGap: "20px",

  "& svg": {
    width: "32px",
    height: "32px",
  },
});

const DividerWrapper = styled("div")({
  paddingBottom: "24px",
});

const DescriptionWrapper = styled("ul")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  rowGap: "8px",
  padding: "0 32px 32px",

  [theme.breakpoints.down("sm")]: {
    padding: "0 24px 24px",
  },
}));

const StyledListItem = styled("li")<{ disabled?: boolean }>(({ disabled, theme }) => ({
  marginLeft: "12px",
  paddingLeft: "5px",

  "&::marker": {
    content: '"✓"',
    color: disabled ? theme.palette.grey[300] : theme.palette.grey.A200,
  },
}));

export default PlanSettingsCard;
