import { Button } from "@maestro-org/ui-kit";
import { useEffect } from "react";
import { styled, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import { updatePopup } from "../../redux/actions/popupsActions";
import { getAddNewProjectDialog } from "../../redux/reducers/popupsReducer";
import { createProjectsServer, getProjectsNetworks } from "../../redux/actions/projectsActions";
import { getChainsNetworks } from "../../redux/reducers/projectsReducer";
import { getIsAuthorized } from "../../redux/reducers/authReducer";

import { getCreateProjectFields } from "../../forms/createProject/form";
import { CreateProjectFormValues } from "../../forms/createProject/types";

import Dialog from "./Dialog";

import { Popups } from "../../types/popups";
import { NewEntryField } from "../../types/newEntry";
import { Blockchain, Network } from "../../types/project";
import { Option } from "../../types/newEntry";

import useFields from "../../forms/createProject/useFields";
import {
  blockhainsSortHelper,
  getBlockchainLabel,
  getNetworkLabel,
  networksSortHelper,
} from "../../lib/createProject.utils";

const AddNewProjectDialog = () => {
  const dispatch = useDispatch();

  const chainsNetworks = useSelector(getChainsNetworks);
  const isAuthorized = useSelector(getIsAuthorized);
  const isOpen = useSelector(getAddNewProjectDialog);

  const onSubmit = (values: CreateProjectFormValues) => {
    const network = values.network[0];
    const blockchain = values.blockchain[0];

    if (!network || !blockchain) return;
    addProject(values.name, network, blockchain);
  };

  const { values, isValid, dirty, getField, handleSubmit, resetForm } = useFields({ onSubmit });

  const blockchainOptions: Option[] = chainsNetworks
    .map((item) => ({
      value: item.chain,
      label: getBlockchainLabel(item.chain as Blockchain),
    }))
    .sort(blockhainsSortHelper);

  const networkOptions: Option[] =
    chainsNetworks
      .find((item) => item.chain === values.blockchain[0])
      ?.network.map((value) => ({
        value,
        label: getNetworkLabel(values.blockchain[0], value as Network),
      }))
      .sort(networksSortHelper) || [];

  const addProject = (name: string, network: Network, blockchain: Blockchain) => {
    dispatch(createProjectsServer({ name, network, chain: blockchain }));

    handleClose();
    resetForm();
  };

  const handleClose = () => {
    dispatch(updatePopup({ popup: Popups.addNewProject, status: false }));
  };

  useEffect(() => {
    if (!isAuthorized) return;
    dispatch(getProjectsNetworks());
  }, [isAuthorized]);

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <Form onSubmit={handleSubmit}>
        <Typography color="grey.A200" variant="h5">
          Add new project
        </Typography>
        <FormFields>
          {getCreateProjectFields({ blockchainOptions, networkOptions }).map(
            (field: NewEntryField) => getField(field)[field.type],
          )}
        </FormFields>
        <Actions>
          <CancelButton onClick={handleClose}>Cancel</CancelButton>
          <Button
            type="submit"
            onMouseDown={(e) => e.preventDefault()}
            disabled={!isValid || !dirty || !values.network[0]}
          >
            Save project
          </Button>
        </Actions>
      </Form>
    </Dialog>
  );
};

const Form = styled("form")({
  display: "flex",
  flexDirection: "column",
  rowGap: "40px",
});

const FormFields = styled("div")({
  display: "flex",
  flexDirection: "column",
  rowGap: "20px",
});

const Actions = styled("div")({
  display: "flex",
  alignItems: "center",
  columnGap: "12px",

  "& .MuiButtonBase-root": {
    height: "44px",
  },
});

const CancelButton = styled(Button)(({ theme }) => ({
  borderRadius: theme.borderRadius.sm,
  background: theme.palette.grey[50],
  color: theme.palette.grey["A200"],

  "&:hover": {
    background: theme.palette.grey[50],
    color: theme.palette.grey["A200"],
  },
}));

export default AddNewProjectDialog;
