import {
  Collapse,
  Paper,
  Table as MuiTable,
  TableBody,
  TableCell as MuiTableCell,
  TableContainer,
  TableRow,
  styled,
  Typography,
} from "@mui/material";
import React, { useState } from "react";

import { RowData, RowType, TransactionMonitoringState } from "../../../types/transaction";

import TableChip, { ChipColors } from "../../../components/Table/TableChip";
import { ArrowDown, TurboTXIcon } from "../../../components/Icons";
import { Network } from "../../../types/project";
import { useSelector } from "react-redux";
import { getAllprojects } from "../../../redux/reducers/projectsReducer";

interface ParsedData {
  [key: string]: React.ReactNode;
}

const columnWidth = [220, 190, 230, 255, 150, 150, 150];

const getChipColor = {
  [TransactionMonitoringState.onchain]: ChipColors.green,
  [TransactionMonitoringState.pending]: ChipColors.yellow,
  [TransactionMonitoringState.rejected]: ChipColors.red,
  [TransactionMonitoringState.rolledback]: ChipColors.purple,
  [TransactionMonitoringState.failed]: ChipColors.red,
};

const getTxHashLink = (txHash: string, network: Network) =>
  ({
    [Network.Mainnet]: `https://cardanoscan.io/transaction/${txHash}`,
    [Network.Preprod]: `https://preprod.cardanoscan.io/transaction/${txHash}`,
    [Network.Preview]: `https://preview.cardanoscan.io/transaction/${txHash}`,
    [Network.Testnet]: `https://preview.cardanoscan.io/transaction/${txHash}`,
  }[network]);

const TransactionMonitoringRow = ({ heading, data, details, turboTX }: RowType) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const projects = useSelector(getAllprojects);

  const toggleIsOpen = () => setIsOpen((prev) => !prev);

  const getProjectNetworkByName = (projectName: string) =>
    projects.find((project) => project.name === projectName)?.network;

  const parseRow = (item: RowData): ParsedData => ({
    submitTime: (
      <Typography color="grey.A200" variant="paragraphSmall">
        {item.submitTime}
      </Typography>
    ),
    state: <TableChip label={item.state} color={getChipColor[item.state]} />,
    txHash: getTxHashLink(item.txHashFull, getProjectNetworkByName(item.project) as Network) ? (
      <a
        href={getTxHashLink(item.txHashFull, getProjectNetworkByName(item.project) as Network)}
        target="_blank"
        rel="noreferrer"
      >
        <WithAdornment>
          {`${turboTX}` === "true" && <TurboTXIcon />}

          <Typography color="grey.A200" variant="paragraphSmall">
            {item.txHash}
          </Typography>
        </WithAdornment>
      </a>
    ) : (
      <WithAdornment>
        {`${turboTX}` === "true" && <TurboTXIcon />}

        <Typography color="grey.A200" variant="paragraphSmall">
          {item.txHash}
        </Typography>
      </WithAdornment>
    ),
    project: (
      <Typography color="grey.A200" variant="paragraphSmall">
        {item.project}
      </Typography>
    ),
    block: (
      <Typography color="grey.A200" variant="paragraphSmall">
        {item.block}
      </Typography>
    ),
    confirmations: (
      <Typography color="grey.A200" variant="paragraphSmall">
        {item.confirmations}
      </Typography>
    ),
    // webhook: (
    //   <Typography color="grey.A200" variant="paragraphSmall">
    //     {item.webhook}
    //   </Typography>
    // ),
  });

  const newData = parseRow(data);

  return (
    <TableContainer component={StyledPaper}>
      <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
        <TableBody>
          <TableRow>
            {Object.keys(heading).map((headingKey, ix) => (
              <TableCell style={{ width: columnWidth[ix] }} key={headingKey}>
                <Typography color="grey.300" variant="paragraphSmall">
                  {heading[headingKey] as string}
                </Typography>
              </TableCell>
            ))}
            {/* <DetailsCell rowSpan={2} onClick={toggleIsOpen}>
              <WithAdornment>
                <Typography color="grey.A200" variant="paragraphSmall">
                  Details
                </Typography>
                <ArrowWrapper isOpen={isOpen}>
                  <ArrowDown />
                </ArrowWrapper>
              </WithAdornment>
            </DetailsCell> */}
          </TableRow>
          <TableRow>
            {Object.keys(newData).map((dataKey) => (
              <TableCell key={dataKey}>{newData[dataKey as keyof ParsedData]}</TableCell>
            ))}
          </TableRow>
        </TableBody>
        {/* <Caption>
          <Collapse in={isOpen} timeout="auto" unmountOnExit>
            <Details>
              <Typography color="grey.A200" variant="paragraphSmall">
                {details}
              </Typography>
            </Details>
          </Collapse>
        </Caption> */}
      </Table>
    </TableContainer>
  );
};

const StyledPaper = styled(Paper)({
  margin: "8px 0",
  boxShadow: "none",
  borderRadius: "1px",
  padding: "20px 26px 20px 24px",
  borderBottom: "1px solid transparent",
  transition: "0.3s",
  overflow: "visible !important",
  minWidth: "900px",

  "&:hover": {
    borderColor: "#C53DD8",
    boxShadow: "0px 8px 30px -2px rgba(0, 0, 0, 0.08)",
    borderRadius: "1px 1px 16px 16px",
  },
});

const Table = styled(MuiTable)(({ theme }) => ({
  borderCollapse: "separate",
  borderSpacing: "0",
  background: theme.palette.common.white,
}));

const TableCell = styled(MuiTableCell)({
  border: "none",
  padding: "4px 16px",
});

const DetailsCell = styled(TableCell)({
  cursor: "pointer",
  userSelect: "none",
});

const Details = styled("div")(({ theme }) => ({
  padding: "20px 0 0",
  borderTop: `1px solid ${theme.palette.grey[100]}`,
  marginTop: "20px",
}));

const Caption = styled("caption")({
  padding: "0 !important",
});

const WithAdornment = styled("div")({
  display: "flex",
  columnGap: "6px",
  alignItems: "center",
});

const ArrowWrapper = styled("div")<{ isOpen: boolean }>(({ isOpen }) => ({
  "& svg": {
    transition: "0.3s",
    transform: `rotate(${isOpen ? -180 : 0}deg)`,
  },
}));

export default TransactionMonitoringRow;
