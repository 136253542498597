import { Button, Dropdown, TextField } from "@maestro-org/ui-kit";
import { Theme, Typography, styled, useTheme } from "@mui/material";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";

import { updatePopup } from "../../redux/actions/popupsActions";
import { getCreateTeamDialog } from "../../redux/reducers/popupsReducer";

import { getCreateTeamFields, initialValuesCreateTeam } from "../../forms/createTeam/form";
import { CreateTeamFormValues, CreateTeamFields } from "../../forms/createTeam/types";
import { createTeamSchema } from "../../forms/createTeam/validation";

import Dialog from "./Dialog";

import { NewEntryField, NewEntryFieldTypes } from "../../types/newEntry";
import { Popups } from "../../types/popups";

import IconButton from "../Button/IconButton";

import { getUser } from "../../redux/reducers/usersReducer";

import { createTeamServer } from "../../redux/actions/teamsActions";

const CreateTeamDialog = () => {
  const theme = useTheme();

  const isOpen = useSelector(getCreateTeamDialog);

  const dispatch = useDispatch();

  const user = useSelector(getUser);

  const onSubmit = (values: CreateTeamFormValues) => {
    const team_name = values.teamName;

    dispatch(createTeamServer(team_name));

    dispatch(updatePopup({ popup: Popups.createTeam, status: false }));
    formik.resetForm();
  };

  const formik = useFormik({
    initialValues: initialValuesCreateTeam,
    validationSchema: createTeamSchema,
    validateOnChange: true,
    enableReinitialize: true,
    onSubmit,
  });

  const { values, setFieldValue, setFieldTouched, errors, touched, handleChange, handleBlur, handleSubmit } = formik;

  const handleClose = () => {
    dispatch(updatePopup({ popup: Popups.createTeam, status: false }));
    formik.resetForm();
  };

  const handleDropdownChange = (value: unknown, name: string) => {
    setFieldValue(name, value, true);
  };

  const handleDropdownBlur = (name: string) => {
    setFieldTouched(name, true, true);
  };

  const checkError = (name: string) => !!errors[name as keyof typeof errors] && touched[name as keyof typeof touched];

  const getField = (field: NewEntryField) => {
    const fieldValue = values[field.name as keyof typeof values] || "";
    const isTeamNameTooLong = field.name === CreateTeamFields.teamName && fieldValue.length >= 32;

    return {
      [NewEntryFieldTypes.text]: (
        <StyledTextField
          label={field.label}
          variant="outlined"
          name={field.name}
          value={fieldValue}
          onChange={handleChange}
          placeholder={field.placeholder}
          error={checkError(field.name) as boolean}
          onBlur={handleBlur}
          fieldLabel={field.fieldLabel}
          inputProps={{ maxLength: 32 }}
          isMaxReached={isTeamNameTooLong}
        />
      ),
      [NewEntryFieldTypes.select]: (
        <StyledSelect
          label={field.label}
          name={field.name}
          fullWidth
          onBlur={() => handleDropdownBlur(field.name)}
          value={values[field.name as keyof typeof values] as any}
          placeholder={field.placeholder}
          onChange={(value) => handleDropdownChange(value, field.name)}
          options={field.options || []}
          error={checkError(field.name) as boolean}
          fieldLabel={field.fieldLabel}
          MenuProps={{
            PaperProps: {
              style: {
                ...getMenuStyle(theme, checkError(field.name) as boolean),
              },
            },
          }}
        />
      ),
    };
  };

  return (
    <Dialog open={isOpen}>
      <Form onSubmit={handleSubmit}>
        <Typography color="grey.A200" variant="h5">
          Name your team
        </Typography>
        <FormFields>
          <Row>
            {getCreateTeamFields()
              .slice(0, 1)
              .map((field: NewEntryField) => getField(field)[field.type])}
          </Row>
        </FormFields>

        <Actions>
          <ButtonsWrapper>
            <CancelButton onClick={handleClose}>Cancel</CancelButton>
            <Button type="submit" onMouseDown={(e) => e.preventDefault()} disabled={!formik.isValid || !formik.dirty}>
              Create
            </Button>
          </ButtonsWrapper>
        </Actions>
      </Form>
    </Dialog>
  );
};

const Form = styled("form")({
  display: "flex",
  flexDirection: "column",
  rowGap: "32px",
});

const StyledTextField = styled(TextField)<{ isMaxReached: boolean }>(({ isMaxReached }) => ({
  "& .MuiFormLabel-root.Mui-error": {
    color: "#DC6675 !important",
  },

  ...(isMaxReached && {
    "& fieldset": {
      borderColor: "#DC6675 !important",
    },

    "& .Mui-focused fieldset": {
      borderColor: "#DC6675 !important",
    },
  }),
}));

const StyledText = styled(Typography)({
  marginBottom: "12px",
});

const FormFields = styled("div")({
  display: "flex",
  flexDirection: "column",
  rowGap: "20px",
});

const StyledSelect = styled(Dropdown)(({ theme }) => ({
  "& .MuiSelect-select": {
    backgroundColor: theme.palette.common.white,
    borderRadius: "4px !important",
    borderWidth: "1px !important",
  },
}));

const Actions = styled("div")({
  display: "flex",
  alignItems: "center",
  columnGap: "12px",
  marginTop: "8px",
});

const CancelButton = styled(Button)(({ theme }) => ({
  borderRadius: theme.borderRadius.sm,
  background: theme.palette.grey[50],
  color: theme.palette.grey["A200"],

  "&:hover": {
    background: theme.palette.grey[50],
    color: theme.palette.grey["A200"],
  },
}));

const AddTeamMemberWrapper = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: "20px",

  "&:before": {
    content: `''`,
    height: "1px",
    flex: 1,
    background: "#D9D9D9",
  },

  "&:after": {
    content: `''`,
    height: "1px",
    flex: 1,
    background: "#D9D9D9",
  },
});

const AddTeamMemberButton = styled(Button)({
  padding: "0",
  background: "transparent",
  gap: "16px",

  "&:hover": {
    background: "transparent",
  },

  p: {
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "24px",
    color: "#C53DD8",
  },
});

const ButtonsWrapper = styled("div")({
  display: "flex",
  alignItems: "center",
  columnGap: "12px",
});

const Row = styled("div")({
  display: "flex",
  gap: "16px",
  alignItems: "center",

  "& > div": {
    width: "100%",
  },

  "& > div:nth-child(2)": {
    maxWidth: "188px",
  },
});

const TeamRowWrapper = styled("div")({
  display: "flex",
  alignItems: "center",
  position: "relative",
  gap: "20px",
  "& > div:first-of-type": {
    width: "66%",
  },
  "& > div": {
    width: "33%",
  },
});

const Divider = styled("div")({
  display: "flex",
  width: "100%",
  height: "1px",
  backgroundColor: "#D9D9D9",
  margin: "12px 0px",
});

const DeleteRowButton = styled(IconButton)({
  position: "absolute",
  right: "-23px",
  padding: "0",
  height: "17px",
  top: "50%",
  transform: "translateY(-50%)",

  a: {
    height: "17px",
  },
});

const getMenuStyle = (theme: Theme, error?: boolean, width?: number) => ({
  width: width ? `${width}px` : "auto",
  background: theme.palette.common.white,
  boxShadow: "none",
  border: `1px solid ${error ? theme.palette.dropdown.border.error : theme.palette.dropdown.border.main}`,
  transform: "translateY(5px)",
});

export default CreateTeamDialog;
