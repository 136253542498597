export const RequestsIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
    <path
      d="M9 11C9.55228 11 10 10.5523 10 10C10 9.44772 9.55228 9 9 9C8.44772 9 8 9.44772 8 10C8 10.5523 8.44772 11 9 11Z"
      fill="#C53DD8"
      stroke="#C53DD8"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M9 2.75V4.75" stroke="#C53DD8" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M14.1259 4.87109L12.7109 6.28609"
      stroke="#C53DD8"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M16.25 10H14.25" stroke="#C53DD8" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M3.87109 4.87109L8.29109 9.29109"
      stroke="#C53DD8"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M1.75 10H3.75" stroke="#C53DD8" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M6 3.398C6.914 2.982 7.93 2.75 9 2.75C13.004 2.75 16.25 5.996 16.25 10C16.25 12.002 15.439 13.815 14.127 15.127L12.713 13.713"
      stroke="#C53DD8"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.288 13.713L3.874 15.127C2.562 13.815 1.75 12.003 1.75 10C1.75 8.93 1.982 7.914 2.398 7"
      stroke="#C53DD8"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
