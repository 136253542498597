export const TransactionSearchIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path
      d="M15.1295 15.2506L10.6094 10.7305"
      stroke="#999999"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.57891 12.4C9.72693 12.4 12.2789 9.84802 12.2789 6.7C12.2789 3.55198 9.72693 1 6.57891 1C3.43088 1 0.878906 3.55198 0.878906 6.7C0.878906 9.84802 3.43088 12.4 6.57891 12.4Z"
      stroke="#999999"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
