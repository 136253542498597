import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Theme, Typography, styled, useTheme } from "@mui/material";
import { Button, Dropdown, TextField } from "@maestro-org/ui-kit";
import { useFormik } from "formik";

import { getCreateWebhookDialog } from "../../redux/reducers/popupsReducer";
import { getAllprojects } from "../../redux/reducers/projectsReducer";
import { createWebhookServer } from "../../redux/actions/webhooksActions";
import { updatePopup } from "../../redux/actions/popupsActions";

import { getCreateTransactionFields, initialValuesCreateTransaction } from "../../forms/createTransaction/form";
import { createTransactionSchema } from "../../forms/createTransaction/validation";
import { CreateTransactionFormValues, CreateTransactionFields } from "../../forms/createTransaction/types";

import MenuItem from "@mui/material/MenuItem";

import Dialog from "./Dialog";

import { Popups } from "../../types/popups";
import { NewEntryField, NewEntryFieldTypes } from "../../types/newEntry";
import { Blockchain } from "../../types/project";

const CreateWebhookDialog = () => {
  const theme = useTheme();

  const isOpen = useSelector(getCreateWebhookDialog);
  const allProjects = useSelector(getAllprojects);
  const dispatch = useDispatch();

  const cardanoProjects = allProjects
    .filter((project) => project.chain === Blockchain.Cardano)
    .map((project) => ({ value: project.name, disabled: false }));
  const otherProjects = allProjects
    .filter((project) => project.chain !== Blockchain.Cardano)
    .map((project) => ({ value: project.name, disabled: true }));

  const sortedProjects = [...cardanoProjects, ...otherProjects];

  const onSubmit = (values: CreateTransactionFormValues) => {
    addTransactions(values.project[0], values.url, values.webhookName);
  };

  const addTransactions = (projectName: string, url: string, webhookName: string) => {
    const projectId = allProjects.find(({ name }) => name === projectName)?.id;
    if (!projectId) return;
    handleClose();
    formik.resetForm();
    dispatch(createWebhookServer({ project_id: projectId, webhook_name: webhookName, webhook_url: url }));
  };

  const formik = useFormik({
    initialValues: initialValuesCreateTransaction,
    validationSchema: createTransactionSchema,
    validateOnChange: true,
    enableReinitialize: true,
    onSubmit,
  });

  const { values, setFieldValue, setFieldTouched, errors, touched, handleChange, handleBlur, handleSubmit } = formik;

  const handleClose = () => dispatch(updatePopup({ popup: Popups.createWebhook, status: false }));

  const handleDropdownChange = (value: unknown, name: string) => {
    setFieldValue(name, value, true);
  };

  const handleDropdownBlur = (name: string) => {
    setFieldTouched(name, true, true);
  };

  const checkError = (name: string) => !!errors[name as keyof typeof errors] && touched[name as keyof typeof touched];

  const getField = (field: NewEntryField) => {
    const fieldValue = values[field.name as keyof typeof values] || "";
    const isWebhookNameTooLong = field.name === CreateTransactionFields.webhookName && fieldValue.length >= 32;
    const isUrlTooLong = field.name === CreateTransactionFields.url && fieldValue.length >= 100;

    return {
      [NewEntryFieldTypes.text]: (
        <StyledTextField
          variant="outlined"
          name={field.name}
          value={fieldValue}
          onChange={handleChange}
          onBlur={handleBlur}
          error={checkError(field.name)}
          fieldLabel={field.fieldLabel}
          inputProps={field.name === CreateTransactionFields.webhookName ? { maxLength: 32 } : { maxLength: 100 }}
          isMaxReached={isUrlTooLong || isWebhookNameTooLong}
        />
      ),
      [NewEntryFieldTypes.select]: (
        <StyledSelect
          name={field.name}
          fullWidth
          onBlur={() => handleDropdownBlur(field.name)}
          value={values[field.name as keyof typeof values] as any}
          placeholder={""}
          onChange={(value) => handleDropdownChange(value, field.name)}
          options={field.options || []}
          fieldLabel={field.fieldLabel}
          error={checkError(field.name) as boolean}
          MenuProps={{
            PaperProps: {
              style: {
                ...getMenuStyle(theme, checkError(field.name) as boolean),
              },
            },
          }}
        />
      ),
    };
  };

  return (
    <Dialog open={isOpen}>
      <Form onSubmit={handleSubmit}>
        <Typography color="grey.A200" variant="h5">
          Create webhook
        </Typography>
        <FormFields>
          {getCreateTransactionFields(sortedProjects).map((field: NewEntryField) => getField(field)[field.type])}
        </FormFields>
        <Actions>
          <CancelButton onClick={handleClose}>Cancel</CancelButton>
          <Button type="submit" onMouseDown={(e) => e.preventDefault()} disabled={!formik.isValid || !formik.dirty}>
            Save webhook
          </Button>
        </Actions>
      </Form>
    </Dialog>
  );
};

const Form = styled("form")({
  display: "flex",
  flexDirection: "column",
  rowGap: "40px",
});

const StyledTextField = styled(TextField)<{ isMaxReached: boolean }>(({ isMaxReached }) => ({
  "& .MuiFormLabel-root.Mui-error": {
    color: "#DC6675 !important",
  },
  ...(isMaxReached && {
    "& fieldset": {
      borderColor: "#DC6675 !important",
    },

    "& .Mui-focused fieldset": {
      borderColor: "#DC6675 !important",
    },
  }),
}));

const FormFields = styled("div")({
  display: "flex",
  flexDirection: "column",
  rowGap: "20px",
});

const StyledSelect = styled(Dropdown)(({ theme }) => ({
  "& .MuiSelect-select": {
    backgroundColor: theme.palette.common.white,
    borderRadius: "4px !important",
    borderWidth: "1px !important",
  },
}));

const Actions = styled("div")({
  display: "flex",
  alignItems: "center",
  columnGap: "12px",
});

const CancelButton = styled(Button)(({ theme }) => ({
  borderRadius: theme.borderRadius.sm,
  background: theme.palette.grey[50],
  color: theme.palette.grey["A200"],

  "&:hover": {
    background: theme.palette.grey[50],
    color: theme.palette.grey["A200"],
  },
}));

const getMenuStyle = (theme: Theme, error?: boolean, width?: number) => ({
  width: width ? `${width}px` : "auto",
  background: theme.palette.common.white,
  borderRadius: theme.borderRadius.sm,
  boxShadow: "none",
  border: `2px solid ${error ? theme.palette.dropdown.border.error : theme.palette.dropdown.border.main}`,
  transform: "translateY(5px)",
});

export default CreateWebhookDialog;
