// prettier-ignore
export interface CreateTeamFormValues {
    [CreateTeamFields.teamName]: string;
    [CreateTeamFields.emailAddress]: string[];
    [CreateTeamFields.role]: string[];
}

// prettier-ignore
export enum CreateTeamFields {
    teamName = "teamName",
    emailAddress = "emailAddress",
    role = "role",
}
