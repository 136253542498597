export const UrlIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_5216_1004)">
      <path
        d="M12 9.00018L13.975 6.97518C14.3 6.65016 14.5578 6.2643 14.7337 5.83964C14.9096 5.41498 15.0002 4.95983 15.0002 4.50018C15.0002 4.04053 14.9096 3.58538 14.7337 3.16072C14.5578 2.73606 14.3 2.3502 13.975 2.02518C13.65 1.70016 13.2641 1.44234 12.8395 1.26644C12.4148 1.09053 11.9597 1 11.5 1C11.0403 1 10.5852 1.09053 10.1605 1.26644C9.73588 1.44234 9.35002 1.70016 9.025 2.02518L7 4.00018"
        stroke="#A926BB"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.00018 12L6.97518 13.975C6.65016 14.3 6.2643 14.5578 5.83964 14.7337C5.41498 14.9096 4.95983 15.0002 4.50018 15.0002C4.04053 15.0002 3.58538 14.9096 3.16072 14.7337C2.73606 14.5578 2.3502 14.3 2.02518 13.975C1.70016 13.65 1.44234 13.2641 1.26644 12.8395C1.09053 12.4148 1 11.9597 1 11.5C1 11.0403 1.09053 10.5852 1.26644 10.1605C1.44234 9.73588 1.70016 9.35002 2.02518 9.025L4.00018 7"
        stroke="#A926BB"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M5 11L11 5" stroke="#A926BB" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    </g>
    <defs>
      <clipPath id="clip0_5216_1004">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
