import { useSelector } from "react-redux";
import { Button } from "@maestro-org/ui-kit";
import { styled, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { IBillingInfo } from "../../types/auth";
import { SettingsPages } from "../../types/settings";

import {
  getActiveMethodId,
  getAllPaymentMethods,
  getBillingDataLoading,
  getStripeCustomer,
  getStripeCustomerLoading,
} from "../../redux/reducers/stripeReducer";

import SettingsLayout from "../../layouts/SettingsLayout";

import EditBillingInfoCard from "./components/EditBillingInfoCard";
import { BackIcon } from "../../components/Icons";

import { pages } from "../../lib/routeUtils";
import withPrivateRoute from "../../hooks/withPrivateRoute";

const BillingInformationPage = () => {
  const billingInfo = useSelector(getStripeCustomer);
  const isLoading = useSelector(getStripeCustomerLoading);

  return (
    <SettingsLayout
      path={[
        { title: "Settings", href: "/" },
        { title: "Subscription", href: "/" },
        { title: "Overview", href: "/" },
        { title: "Billing information", href: "/" },
      ]}
      active={SettingsPages.overview}
    >
      <Wrapper>
        <Link to={pages.subscriptionPlan()}>
          <StyledButton startIcon={<BackIcon />}>
            <Typography color="grey.A200" variant="paragraphMedium">
              Back
            </Typography>
          </StyledButton>
        </Link>
        <EditBillingInfoCard isLoading={isLoading} data={billingInfo} />
      </Wrapper>
    </SettingsLayout>
  );
};

const Wrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  rowGap: "32px",

  "@media (max-height: 1000px)": {
    rowGap: "28px",
  },

  [theme.breakpoints.down("xl")]: {
    rowGap: "25px",
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  padding: "5px 10px",
  background: "transparent",
  color: theme.palette.grey.A200,

  "&:hover": {
    background: "transparent",
    color: theme.palette.grey.A200,
  },
}));

export default withPrivateRoute(BillingInformationPage);
