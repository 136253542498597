import React from "react";
import { FormikProps } from "formik";

import { AuthField } from "../../../types/auth";

import { RegisterFormValues } from "../../../forms/register/types";
import { LoginFormValues } from "../../../forms/login/types";
import { ResetPasswordFormValues } from "../../../forms/reset-password/types";

import { TextField as KitTextfield } from "@maestro-org/ui-kit";
import { InputWarningIcon } from "../../../components/Icons";

type FieldType = AuthField;

type FormProps = FormikProps<RegisterFormValues> | FormikProps<LoginFormValues> | FormikProps<ResetPasswordFormValues>;

interface CustomProps {
  field: FieldType;
}

const TextField = ({ field, errors, touched, handleChange, handleBlur, values }: CustomProps & FormProps) => {
  const getHelperText = (field: FieldType) =>
    errors[field.name as keyof typeof errors] && touched[field.name as keyof typeof touched]
      ? errors[field.name as keyof typeof errors]
      : null;
  const checkError = (field: FieldType) =>
    !!errors[field.name as keyof typeof errors] && touched[field.name as keyof typeof touched];

  return (
    <KitTextfield
      autoComplete={field.autoComplete}
      fieldLabel={field.fieldLabel}
      variant="outlined"
      label={field.label}
      type={field.type}
      endIcon={checkError(field) && <InputWarningIcon />}
      placeholder={field.placeholder}
      onChange={handleChange}
      onBlur={handleBlur}
      name={field.name}
      value={values[field.name as keyof typeof values] as string}
      error={checkError(field)}
      helperText={getHelperText(field)}
    />
  );
};

export default TextField;
