export const UpgradeIcon = () => (
  <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5 4.00089H8.5C8.59902 4.00107 8.69586 3.97184 8.77825 3.91692C8.86064 3.86199 8.92487 3.78383 8.96278 3.69236C9.0007 3.60088 9.01061 3.50021 8.99124 3.4031C8.97187 3.306 8.92411 3.21682 8.854 3.14689L5.854 0.146895C5.78407 0.0767879 5.6949 0.0290234 5.59779 0.00965666C5.50068 -0.00971012 5.40001 0.000192959 5.30854 0.0381105C5.21706 0.076028 5.13891 0.140252 5.08398 0.222641C5.02905 0.305031 4.99982 0.401874 5 0.500895V2.00089C3.67392 2.00089 2.40215 2.52768 1.46447 3.46536C0.526784 4.40304 0 5.67481 0 7.00089C0 8.32698 0.526784 9.59875 1.46447 10.5364C2.40215 11.4741 3.67392 12.0009 5 12.0009C5.26522 12.0009 5.51957 11.8955 5.70711 11.708C5.89464 11.5205 6 11.2661 6 11.0009C6 10.7357 5.89464 10.4813 5.70711 10.2938C5.51957 10.1063 5.26522 10.0009 5 10.0009C4.20435 10.0009 3.44129 9.68482 2.87868 9.12222C2.31607 8.55961 2 7.79654 2 7.00089C2 6.20525 2.31607 5.44218 2.87868 4.87957C3.44129 4.31697 4.20435 4.00089 5 4.00089Z"
      fill="#C53DD8"
    />
    <path
      d="M10.9999 2C10.7347 2 10.4803 2.10536 10.2928 2.29289C10.1053 2.48043 9.99992 2.73478 9.99992 3C9.99992 3.26522 10.1053 3.51957 10.2928 3.70711C10.4803 3.89464 10.7347 4 10.9999 4C11.7956 4 12.5586 4.31607 13.1212 4.87868C13.6838 5.44129 13.9999 6.20435 13.9999 7C13.9999 7.79565 13.6838 8.55871 13.1212 9.12132C12.5586 9.68393 11.7956 10 10.9999 10H7.49992C7.4009 9.99982 7.30405 10.0291 7.22167 10.084C7.13928 10.1389 7.07505 10.2171 7.03713 10.3085C6.99922 10.4 6.98931 10.5007 7.00868 10.5978C7.02805 10.6949 7.07581 10.7841 7.14592 10.854L10.1459 13.854C10.1924 13.9004 10.2476 13.9372 10.3084 13.9622C10.3691 13.9873 10.4342 14.0001 10.4999 14C10.5655 14.0002 10.6304 13.9872 10.6909 13.962C10.7824 13.9242 10.8605 13.8601 10.9155 13.7779C10.9705 13.6957 10.9999 13.5989 10.9999 13.5V12C12.326 12 13.5978 11.4732 14.5355 10.5355C15.4731 9.59785 15.9999 8.32608 15.9999 7C15.9999 5.67392 15.4731 4.40215 14.5355 3.46447C13.5978 2.52678 12.326 2 10.9999 2Z"
      fill="#C53DD8"
    />
  </svg>
);
