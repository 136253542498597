import * as Yup from "yup";

import { CreateProjectFields } from "./types";

export const createProjectSchema = Yup.object().shape({
  [CreateProjectFields.name]: Yup.string()
    .max(32, "Project name must be 32 characters or less")
    .required("Project name is required"),
  [CreateProjectFields.network]: Yup.array().min(1, "Network is required"),
});
