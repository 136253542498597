import * as Yup from "yup";

import { RegisterFields } from "./types";

export const registerSchema = Yup.object().shape({
  [RegisterFields.firstName]: Yup.string().required("First name is required"),
  [RegisterFields.lastName]: Yup.string().required("Last name is required"),
  [RegisterFields.email]: Yup.string().email("Invalid email").required("Email is required"),
  [RegisterFields.password]: Yup.string()
    .required("Password is required")
    .min(8, "Password must contain at least 8 symbols")
    .matches(/^(?=.*[a-z])/, "Password must include lowercase letters")
    .matches(/^(?=.*[A-Z])/, "Password must include uppercase letters")
    .matches(/^(?=.*[0-9])/, "Password must include a digit")
    .matches(/^(?=.*[!"#$%&'()*+,\-./:;<=>?@[\]^_`{|}~])/, "Password must include a special symbol"),
});
